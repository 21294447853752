import {Component, ContentChildren, Directive, TemplateRef} from '@angular/core';

@Directive({
    selector: '[mobile-slider-marker]'
})
export class MobileSliderMarker {}

@Directive({
    selector: '[mobile-slider-last-marker]'
})
export class MobileSliderLastMarker {}

@Component({
    selector: 'app-mobile-slider',
    templateUrl: './mobile-slider.component.html',
    styleUrls: ['./mobile-slider.component.css']
})
export class MobileSliderComponent {
    @ContentChildren(MobileSliderMarker, {read: TemplateRef}) mobileSliderTemplates: TemplateRef<any>[];
    @ContentChildren(MobileSliderLastMarker, {read: TemplateRef}) mobileSliderLastTemplates: TemplateRef<any>[];

    isSliderOpen: boolean = false;

    constructor() {
    }
}
