import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BaseListOrderComponent} from "../../pages/baseListOrder.component";

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortComponent {

    @Input('component') baseComponent: BaseListOrderComponent;
    @Input('property') property: string;

  constructor() { }

    getOrderBy() {
        let orderByProperty = this.baseComponent.findOrderProperty(this.property);
        if (orderByProperty === undefined) {
            return '';
        } else if (orderByProperty.asc) {
            return 'up';
        }
        return 'down';
    }
}
