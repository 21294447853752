<div class="base-popup" [ngClass]="additionalClass" [attr.id]="id">
	<div class="base-popup__container">
		<div class="base-popup__title">
			<ng-content select="[head]" ></ng-content>
			<div *ngIf="!title && state === 'ADD'" head>{{customLabel ? customLabel : 'Создание'}}</div>
			<div *ngIf="!title && state === 'EDIT'" head>{{customLabel ? customLabel : 'Редактирование'}}</div>
			<div *ngIf="!title && state === 'SHOW'" head>{{customLabel ? customLabel : 'Просмотр'}}</div>
			<div *ngIf="!title && state === 'DELETE'" head>{{customLabel ? customLabel : 'Удаление'}}</div>
			<div *ngIf="title !== 'hidden'">{{title}}</div>
		</div>
		<div class="base-popup__body">
			<ng-content select="[body]"></ng-content>
			<ng-content select="[validation]"></ng-content>
		</div>
		<div class="base-popup__footer">
			<ng-content select="[footer]"></ng-content>
		</div>
	</div>
</div>
