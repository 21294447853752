import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ListSearchData} from "../model/common/list/listSearchData";
import {Page} from "../model/common/list/page";
import {Fax} from "../model/fax";

@Injectable({
    providedIn: 'root'
})
export class FaxesService {

    constructor(private http: HttpClient) {
    }

    getById(id: number) {
        return this.http.get<Fax>(`api/faxes/${id}`)
    }

    create(pbxid: number, fax: Fax) {
        return this.http.post(`api/pbx/${pbxid}/faxes`, fax);
    }

    update(fax: Fax) {
        return this.http.put(`api/faxes/${fax.id}`, fax);
    }

    delete(id: number) {
        return this.http.delete(`api/faxes/${id}`)
    }

    getListByPbx(pbxId: number, searchData: ListSearchData<Fax>) {
        return this.http.post<Page<Fax>>(`api/pbx/${pbxId}/faxes/list`, searchData);
    }
}
