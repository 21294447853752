import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn} from "@angular/forms";

@Directive({
    selector: '[fileType]', providers: [{provide: NG_VALIDATORS, useExisting: FileTypeDirective, multi: true}]
})
export class FileTypeDirective implements Validator{
    @Input('fileType') fileType: string;

    constructor() {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.fileType && control ? forbiddenTypeValidator(this.fileType)(control) : null;
    }


}

export function forbiddenTypeValidator(fileType: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        const exception = control.value && control.value.substring(control.value.lastIndexOf('.') + 1).toLowerCase() != fileType;
        return exception ? {'type': {value: control.value}} : null;
    };
}
