<div class="title">
	Управление внешними номерами
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn right-margin popup-btn__open" (click)="createAddNumberModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id" name="input_id" [(ngModel)]="listSearchData.searchPattern.numberId" placeholder="ID"></text-input>
			</div>
			<div class="input-block id">
				<text-input id="input_pbx_id" name="input_pbx_id" [(ngModel)]="listSearchData.searchPattern.pbxId" placeholder="ID АТС"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_pbx_name" name="input_pbx_name" [(ngModel)]="listSearchData.searchPattern.pbxName" placeholder="Имя АТС"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_number" name="input_number" [(ngModel)]="listSearchData.searchPattern.number" placeholder="Номер"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Тип" value="{{ listSearchData.searchPattern.type | numberType }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.type = undefined">Все</a>
							<a data-value="REGULAR" (click)="listSearchData.searchPattern.type = 'REGULAR'">{{ 'REGULAR' | numberType }}</a>
							<a data-value="BRONZE" (click)="listSearchData.searchPattern.type = 'BRONZE'">{{ 'BRONZE' | numberType }}</a>
							<a data-value="SILVER" (click)="listSearchData.searchPattern.type = 'SILVER'">{{ 'SILVER' | numberType }}</a>
							<a data-value="GOLD" (click)="listSearchData.searchPattern.type = 'GOLD'">{{ 'GOLD' | numberType }}</a>
							<a data-value="DYNAMIC_CALL_TRACKING" (click)="listSearchData.searchPattern.type = 'DYNAMIC_CALL_TRACKING'">{{ 'DYNAMIC_CALL_TRACKING' | numberType }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.status | numberStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.status = undefined">Все</a>
							<a data-value="FREE" (click)="listSearchData.searchPattern.status = 'FREE'">{{ 'FREE' | numberStatus }}</a>
							<a data-value="BUSY" (click)="listSearchData.searchPattern.status = 'BUSY'">{{ 'BUSY' | numberStatus }}</a>
							<a data-value="LOCKED" (click)="listSearchData.searchPattern.status = 'LOCKED'">{{ 'LOCKED' | numberStatus }}</a>
							<a data-value="SELECTED" (click)="listSearchData.searchPattern.status = 'SELECTED'">{{ 'LOCKED' | numberStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<label style="margin-right: 5px">Только конфликты</label>
			<div class="base-toggle" style="margin-bottom: 0; margin-right: 10px">
				<input type="checkbox" id="input_is_conflict" name="input_is_conflict" [(ngModel)]="listSearchData.searchPattern.conflict">
				<label for="input_is_conflict">
				<span class="toggle-btn">
					<span class="o"></span>
				</span>
				</label>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td>
					<span>ID Billing</span>
					<app-sort [component]="this" [property]="'billingId'"></app-sort>
				</td>
				<td>
					<span>ID Public</span>
					<app-sort [component]="this" [property]="'publicId'"></app-sort>
				</td>
				<td>
					<span>Имя АТС (ID)</span>
				</td>
				<td>
					<span>Номер</span>
					<app-sort [component]="this" [property]="'number'"></app-sort>
				</td>
				<td>
					<span>Тип</span>
					<app-sort [component]="this" [property]="'billingType'"></app-sort>
				</td>
				<td>
					<span>Статус</span>
					<app-sort [component]="this" [property]="'status'"></app-sort>
				</td>
				<td></td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let number of page.items" [ngClass]="{'mark-warning': number.outer, 'mark-error': number.isConflict}">
				<td style="width: 100px">{{number.billingId}}</td>
				<td style="width: 100px">{{number.publicId}}</td>
				<td>
					<a *ngIf="number.billingPbxId"
					   routerLink="{{'/ats/' + number.billingPbxId + '/edit'}}">{{number.billingPbxName}} {{number.billingPbxId ? '(' + number.billingPbxId + ')' : ''}}</a><br/>
					<a *ngIf="number.publicPbxId !== number.billingPbxId"
					   routerLink="{{'/ats/' + number.publicPbxId + '/edit'}}">{{number.publicPbxName}} {{number.publicPbxId ? '(' + number.publicPbxId + ')' : ''}}</a>
				</td>
				<td class="phone">{{number.number}}</td>
				<td class="status">
					<div class="number-type" [ngClass]="number.billingType ? number.billingType : number.publicType">
						<span class="circle"></span><span>{{(number.billingType ? number.billingType : number.publicType) | numberType}}</span></div>
				</td>
				<td class="status">
					<div *ngIf="number.status" class="number-status" [ngClass]="number.status">
						<span class="circle"></span><span>{{number.status | numberStatus}}</span></div>
				</td>
				<td class="actions">
					<app-actions [isActivateUsed]="!number.outer && !number.isConflict && number.status === 'FREE'"
					             [isDeactivateUsed]="!number.outer && !number.isConflict && number.status !== 'FREE'"
					             [isAcceptUsed]="!number.outer && !number.isConflict && (number.status === 'SELECTED' || number.status === 'FREE')"
					             [isUnselectUsed]="!number.outer && !number.isConflict && number.status === 'SELECTED'"
					             [isSetupUsed]="!number.outer && number.isConflict"
					             (activate)="createActivateNumberModal(number)"
					             (deactivate)="createDeactivateNumberModal(number)"
					             (accept)="acceptNumberModal(number)"
					             (delete)="deleteNumberModal(number)"
					             (setup)="setup(number)"
					             (unselect)="unselectNumberModal(number)"></app-actions>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn popup-btn__open" (click)="createAddNumberModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id_mob" name="input_id" [(ngModel)]="listSearchData.searchPattern.numberId" placeholder="ID"></text-input>
			</div>
			<div class="input-block id">
				<text-input id="input_pbx_id_mob" name="input_pbx_id" [(ngModel)]="listSearchData.searchPattern.pbxId" placeholder="ID АТС"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_pbx_name_mob" name="input_pbx_name" [(ngModel)]="listSearchData.searchPattern.pbxName" placeholder="Имя АТС"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_number_mob" name="input_number" [(ngModel)]="listSearchData.searchPattern.number" placeholder="Номер"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Тип" value="{{ listSearchData.searchPattern.type | numberType }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.type = undefined">Все</a>
							<a data-value="REGULAR" (click)="listSearchData.searchPattern.type = 'REGULAR'">{{ 'REGULAR' | numberType }}</a>
							<a data-value="BRONZE" (click)="listSearchData.searchPattern.type = 'BRONZE'">{{ 'BRONZE' | numberType }}</a>
							<a data-value="SILVER" (click)="listSearchData.searchPattern.type = 'SILVER'">{{ 'SILVER' | numberType }}</a>
							<a data-value="GOLD" (click)="listSearchData.searchPattern.type = 'GOLD'">{{ 'GOLD' | numberType }}</a>
							<a data-value="DYNAMIC_CALL_TRACKING" (click)="listSearchData.searchPattern.type = 'DYNAMIC_CALL_TRACKING'">{{ 'DYNAMIC_CALL_TRACKING' | numberType }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.status | numberStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.status = undefined">Все</a>
							<a data-value="FREE" (click)="listSearchData.searchPattern.status = 'FREE'">{{ 'FREE' | numberStatus }}</a>
							<a data-value="BUSY" (click)="listSearchData.searchPattern.status = 'BUSY'">{{ 'BUSY' | numberStatus }}</a>
							<a data-value="LOCKED" (click)="listSearchData.searchPattern.status = 'LOCKED'">{{ 'LOCKED' | numberStatus }}</a>
							<a data-value="SELECTED" (click)="listSearchData.searchPattern.status = 'SELECTED'">{{ 'LOCKED' | numberStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<label style="margin-right: 5px">Только конфликты</label>
			<div class="base-toggle" style="margin-bottom: 0; margin-right: 10px">
				<input type="checkbox" id="input_is_conflict_mob" name="input_is_conflict" [(ngModel)]="listSearchData.searchPattern.conflict">
				<label for="input_is_conflict">
				<span class="toggle-btn">
					<span class="o"></span>
				</span>
				</label>
			</div>
		</div>
	</app-table-header>
	<div class="table-body">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let number of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">ID Billing</div>
								<div class="data">{{number.billingId}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">ID Public</div>
								<div class="data">{{number.publicId}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Имя АТС(ID)</div>
								<div class="data">
									<a *ngIf="number.billingPbxId"
									   routerLink="{{'/ats/' + number.billingPbxId + '/edit'}}">{{number.billingPbxName}} {{number.billingPbxId ? '(' + number.billingPbxId + ')' : ''}}</a><br/>
									<a *ngIf="number.publicPbxId !== number.billingPbxId"
									   routerLink="{{'/ats/' + number.publicPbxId + '/edit'}}">{{number.publicPbxName}} {{number.publicPbxId ? '(' + number.publicPbxId + ')' : ''}}</a>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Номер</div>
								<div class="data">{{number.number}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Тип</div>
								<div class="data">
									<div class="number-type" [ngClass]="number.billingType ? number.billingType : number.publicType">
										<span class="circle"></span><span>{{(number.billingType ? number.billingType : number.publicType) | numberType}}</span></div>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Статус</div>
								<div class="data">
									<div *ngIf="number.status" class="number-status" [ngClass]="number.status">
										<span class="circle"></span><span>{{number.status | numberStatus}}</span></div>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="data">
									<div class="action">
										<app-actions [isActivateUsed]="!number.outer && !number.isConflict && number.status === 'FREE'"
										             [isDeactivateUsed]="!number.outer && !number.isConflict && number.status !== 'FREE'"
										             [isAcceptUsed]="!number.outer && !number.isConflict && (number.status === 'SELECTED' || number.status === 'FREE')"
										             [isUnselectUsed]="!number.outer && !number.isConflict && number.status === 'SELECTED'"
										             [isSetupUsed]="!number.outer && number.isConflict"
										             (activate)="createActivateNumberModal(number)"
										             (deactivate)="createDeactivateNumberModal(number)"
										             (accept)="acceptNumberModal(number)"
										             (delete)="deleteNumberModal(number)"
										             (setup)="setup(number)"
										             (unselect)="unselectNumberModal(number)"></app-actions>
									</div>
								</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<app-modal [id]="numberPbxModal" [title]="'К какой АТС подключить номер?'" [additionalClass]="'edit-contact_popup'">
	<div body>
		<div>
			<div class="table-container" *ngIf="atsComponent.isDataAvailable">
				<app-table-header (onSubmitFilter)="atsComponent.loadPageAvailableForBilling()" (onClearFilter)="atsComponent.clearSearch()">
					<app-mks-paging-short *table-header-marker class="left-margin" [page]="atsComponent.page" [listSearchData]="atsComponent.listSearchData" (pageChange)="atsComponent.pageChanged($event)"
					                      (countChange)="atsComponent.countChange($event)"></app-mks-paging-short>
					<div *filter-marker class="inputs-row">
						<div class="input-block id">
							<text-input id="input_id_pbx" name="input_id_pbx" [(ngModel)]="atsComponent.listSearchData.searchPattern.idPbx" placeholder="ID"></text-input>
						</div>
						<div class="input-block name">
							<text-input id="input_name_pbx" name="input_name_pbx" [(ngModel)]="atsComponent.listSearchData.searchPattern.namePbx"
							            placeholder="Имя"></text-input>
						</div>
						<div class="input-block contractNumber">
							<text-input id="input_contract_number" name="input_contract_number" [(ngModel)]="atsComponent.listSearchData.searchPattern.contractNumber"
							            placeholder="Номер контракта"></text-input>
						</div>
					</div>
				</app-table-header>
				<div class="table-body scrollbar-inner">
					<table class="base-table">
						<thead>
						<tr>
							<td>
								<span>ID</span>
								<app-sort [component]="atsComponent" [property]="'id'"></app-sort>
							</td>
							<td>
								<span>Имя АТС</span>
								<app-sort [component]="atsComponent" [property]="'name'"></app-sort>
							</td>
							<td></td>
						</thead>
						<tbody>
						<tr *ngFor="let pbx of atsComponent.page.items" [ngClass]="{'mark-select': currentPbx && pbx.id === currentPbx.id}">
							<td class="id">{{pbx.id}}</td>
							<td>
								<a target="_blank" routerLink="{{'/ats/' + pbx.id + '/show'}}">{{pbx.name}}</a>
							</td>
							<td>
								<app-actions (next)="selectPbxInModal(pbx)"></app-actions>
							</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(numberPbxModal)">Отмена</button>
		<button *ngIf="!isAddExternalNumberOpened" class="btn base-btn" [disabled]="!currentPbx"
		        (click)="activationProcess(currentNumber, currentPbx.id)">{{isAcceptActivationOpened ? 'Применить' : 'Активировать'}}</button>
	</div>
</app-modal>

<app-modal [id]="numberResolveModal" [title]="'Решение конфликтной ситуации'" [additionalClass]="'small_popup'">
	<div body>
		<div>
			<span *ngIf="currentNumber.conflictType === conflictType.NUMBER_PUBLIC_MISS">Номер <strong class="bold">{{currentNumber.number}}</strong> найден в таблице биллинга но не найден в системе.</span>

			<span *ngIf="currentNumber.conflictType === conflictType.NUMBER_PBX_MISS_IN_PUBLIC">Номер <strong class="bold">{{currentNumber.number}}</strong>
					в биллинге привязан к АТС <strong class="bold">{{currentNumber.billingPbxName}}</strong>, а в системе не привязан.</span>

			<span *ngIf="currentNumber.conflictType === conflictType.NUMBER_BILLING_MISS">Номер <strong class="bold">{{currentNumber.number}}</strong> найден в системе но не найден в таблице биллинга.</span>

			<span *ngIf="currentNumber.conflictType === conflictType.NUMBER_PBX_MISS_IN_BILLING">Номер <strong class="bold">{{currentNumber.number}}</strong>
					в системе привязан к АТС <strong class="bold">{{currentNumber.publicPbxName}}</strong>, а в биллинге не привязан.</span>

			<span *ngIf="currentNumber.conflictType === conflictType.NUMBER_TYPE_MISSMATCH">
					Тип номера <strong class="bold">{{currentNumber.number}}</strong> в системе (<strong class="bold">{{currentNumber.publicType | numberType}}</strong>)
					отличается от типа в биллинге (<strong class="bold">{{currentNumber.billingType | numberType}}</strong>).</span>

			<span *ngIf="currentNumber.conflictType === conflictType.NUMBER_PBX_MISSMATCH">
					Номер <strong class="bold">{{currentNumber.number}}</strong> в биллинге привязан к АТС <strong class="bold">{{currentNumber.billingPbxName}}</strong>,
					а в системе к АТС <strong class="bold">{{currentNumber.publicPbxName}}</strong>.</span>
		</div>
		<br>
		<div class="select-block">
			<!-- TODO: going to migrate all places to ng-select -->
			<ng-select [items]="resolveOptions | async"
			           autofocus
			           [clearable]="false"
			           [(ngModel)]="selectedOption">
			</ng-select>
		</div>
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(numberResolveModal)">Отмена</button>
		<button class="btn base-btn" (click)="tryResolve(currentNumber, selectedOption)">Применить</button>
	</div>
</app-modal>

<app-modal [id]="promptModal" [title]="'Подтвердите свой выбор'" [additionalClass]="'small_popup'">
	<div body>
		<div>
			<span><strong class="bold">{{promptOption | async}}</strong></span>
		</div>
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(promptModal)">Отмена</button>
		<button class="btn base-btn" (click)="resolve(currentNumber, selectedOption, [promptModal, numberResolveModal])">Применить</button>
	</div>
</app-modal>

<app-modal [id]="deleteModal">
	<div body>Вы действительно хотите удалить номер    {{currentNumber.number}}?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(deleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="deleteNumber(currentNumber)">Удалить</button>
	</div>
</app-modal>

<app-modal [id]="unselectConfirmModal">
	<div body>Вы действительно хотите разблокировать номер    {{currentNumber.number}}?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(unselectConfirmModal)">Отмена</button>
		<button class="btn base-btn green" (click)="unselect(currentNumber)">Разблокировать</button>
	</div>
</app-modal>

<app-modal [id]="deactivateConfirmModal">
	<div body>Вы действительно хотите деактивировать номер    {{currentNumber.number}}?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(deactivateConfirmModal)">Отмена</button>
		<button class="btn base-btn green" (click)="deactivateNumber(currentNumber)">Применить</button>
	</div>
</app-modal>

<app-modal [id]="acceptConfirmModal">
	<div body>Вы действительно хотите применить номер    {{currentNumber.number}}?
		Номер будет обработан без взаимодействия с биллинг системой.
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(acceptConfirmModal)">Отмена</button>
		<button class="btn base-btn green" (click)="acceptNumber(currentNumber)">Применить</button>
	</div>
</app-modal>

<app-modal [id]="numberModal" [customLabel]="'Создание номера'" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formdata="ngForm">
			<div class="col">
				<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && number.invalid}">
					<label for="input_number_modal">Номер</label>
					<input required id="input_number_modal" name="input_number_modal"
					       [(ngModel)]="currentExternalNumber.number" #number="ngModel">
					<div *ngIf="formdata.submitted && number.invalid" class="invalid">
						<div *ngIf="number.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="select-block required" [ngClass]="{'invalid': formdata.submitted && !currentExternalNumber.type}">
					<app-select [selectName]="'Тип'"
					            [id]="'input_type'"
					            [labelKey]="'label'"
					            [value]="currentExternalNumber.type | numberType"
					            [autocomplete]="false"
					            [options]="types"
					            (selectChange)="selectType($event)"></app-select>
					<div *ngIf="formdata.submitted" class="invalid">
						<div *ngIf="!currentExternalNumber.type">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block" [ngClass]="{'invalid': formdata.submitted && !currentPbx}">
					<button *ngIf="!currentPbx" [disabled]="currentPbx" type="button" class="btn base-btn grey popup-btn__close"
					        (click)="createSelectPbxForSaveExternalNumberModal()">Выбрать АТС
					</button>
					<button *ngIf="currentPbx" type="button" class="btn base-btn grey popup-btn__close" (click)="currentPbx = null">Очистить АТС</button>
					<p *ngIf="currentPbx"><a target="_blank" routerLink="{{'/ats/' + currentPbx.id + '/show'}}">({{currentPbx.id}}) {{currentPbx.name}}</a></p>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(numberModal); formdata.onReset()">Отмена</button>
		<button *ngIf="modalService.getState(numberModal) === 'ADD'" class="btn base-btn" (click)="save($event, formdata)">Создать</button>
	</div>
</app-modal>
