export class Tariff {
    billingTariffId: number;
    atsTariffId: number;
    tariffName: string;
    startDate: string;
    finishDate: string;
    nextTariffId: number;
    nextTariffStartDate: string;

    recordConversationStatus: string;
    recordShelfLife: number;
    listNumbersStatus: string;
    campaignStatus: string;
    callTrackingStatus: string;
    callbackStatus: string;
    crmStatus: string;
}
