export class CallRecordSearch {

    constructor() {
    }

    externalNumberMask: string;
    contractNumber: string;
    direction: string;
    disposition: string;
    startDateTime: string;
    finishDateTime: string;
    accountLogin: string;
    pbxId: bigint;
    campaignName: string;
    callTrackingName: string;
    sourceMask: string;
    destinationMask: string;
}
