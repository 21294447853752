export class Order {

    constructor(propertyName: string, asc: boolean) {
        this.propertyName = propertyName;
        this.asc = asc;
    }

    propertyName: string;
    asc: boolean;
}
