import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {debounceTime} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {untilDestroyed} from 'ngx-take-until-destroy';

@Component({
    selector: 'app-select', templateUrl: './select.component.html', styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit, OnDestroy {

    @Input() id: string;
    @Input() selectName: string;
    @Input() labelKey;
    @Input() options = [];
    @Input() required = false;
    @Input() readOnly = false;
    @Input() autocomplete = false;
    @Output() selectChange = new EventEmitter();

    private _value: any;
    searchControl = new FormControl(this._value);
    private originalOptions: any[];
    private readonly element: any;
    dirty: boolean;

    constructor(private el: ElementRef) {
        this.element = el.nativeElement;
    }

    @Input()
    set value(value: any) {
        if(value) {
            this._value = value;
            this.searchControl.setValue(this.label);
        }
    }

    get value() {
        return this._value;
    }

    ngOnInit() {
        if (this._value !== undefined) {
            this.searchControl.setValue(this.label);
        }


        if (this.options !== undefined && this.autocomplete) {
            this.originalOptions = [...this.options];

            this.searchControl.valueChanges
                .pipe(debounceTime(300), untilDestroyed(this))
                .subscribe(term => this.search(term));

        }
    }

    search(value: string) {
        console.log("searching...");
        this.options = this.originalOptions.filter(option => option[this.labelKey].includes(value));
    }

    select(option: any) {
        this.value = option;
        this.selectChange.emit(option);
    }

    get label() {
        return this.value ? this.labelOf(this.value) : 'Select...';
    }

    labelOf(option: any) {
        if(this.labelKey !== undefined && typeof option !== 'string' && !(option instanceof String)) {
            return option[this.labelKey];
        } else {
            return option;
        }
    }

    ngOnDestroy() {
        this.element.remove();
    }

}
