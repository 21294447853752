<div class="base-tab__block active" *ngIf="isDataAvailable && isTabDataAvailable">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn right-margin" (click)="createAddAccountModal()">
				Добавить внутренний номер
			</button>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
			                (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table">
				<thead>
				<tr>
					<td>ID</td>
					<td>Номер</td>
					<td>Имя звонящего</td>
					<td>Исходящая схема</td>
					<td>Статус телефона</td>
					<td>Состояние</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let account of page.items">
					<td class="id">{{account.id}}</td>
					<td>{{account.number}}</td>
					<td>{{account.name}}</td>
					<td>{{account.callSchemaNumber}} {{account.callSchemaName}}</td>
					<td>
						<div class="ats-status" [ngClass]="account.peerStatus">
							<span class="circle"></span><span>{{account.peerStatus | peerStatus}}</span></div>
					<td>
						<div class="ats-status" [ngClass]="account.status">
							<span class="circle"></span><span>{{account.status | internalNumberStatus}}</span></div>
					<td>
						<app-actions (delete)="createDeleteEAccountModal(account)" (edit)="createEditAccountModal(account.id)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createAddAccountModal()">
				Добавить внутренний номер
			</button>
			<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
			                (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let account of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">ID</div>
									<div class="data">{{account.id}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Номер</div>
									<div class="data">{{account.number}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Имя звонящего</div>
									<div class="data">{{account.name}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Исходящая схема</div>
									<div class="data">{{account.callSchemaNumber}} {{account.callSchemaName}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Статус телефона</div>
									<div class="data">
										<div class="ats-status" [ngClass]="account.peerStatus">
											<span class="circle"></span><span>{{account.peerStatus | peerStatus}}</span></div>
									</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Состояние</div>
									<div class="data">
										<div class="ats-status" [ngClass]="account.status">
											<span class="circle"></span><span>{{account.status | internalNumberStatus}}</span></div>
									</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (delete)="createDeleteEAccountModal(account)" (edit)="createEditAccountModal(account.id)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="accountDeleteModal">
	<div body>Вы действительно хотите удалить внутренний номер "{{currentAccount.number}} - {{currentAccount.name}}"?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(accountDeleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="deleteAccount(currentAccount.id)">Удалить</button>
	</div>
</app-modal>

<app-modal [id]="accountModal" [additionalClass]="'large_popup'" [title]="'Внутренний номер'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataAccount="ngForm">
			<div class="col">
				<div class="input-block required" [ngClass]="{'invalid': formDataAccount.submitted && number.invalid}">
					<label for="input_account_number">Внутренний номер</label>
					<text-input id="input_account_number" name="input_account_number" required [(ngModel)]="currentAccount.number" #number="ngModel"></text-input>
					<div *ngIf="formDataAccount.submitted && number.invalid" class="invalid">
						<div *ngIf="number.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataAccount.submitted && name.invalid}">
					<label for="input_account_name">Имя звонящего</label>
					<text-input required id="input_account_name" name="input_account_name" [(ngModel)]="currentAccount.name" #name="ngModel"></text-input>
					<div *ngIf="formDataAccount.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="select-block">
					<app-select [selectName]="'Музыка ожидания'"
					            [id]="'input_moh'"
					            [labelKey]="'name'"
					            [value]="currentAccount.mohName"
					            [autocomplete]="true"
					            [readOnly]="false"
					            [options]="files"
					            (selectChange)="selectMoh($event)"></app-select>
				</div>
				<div class="select-block">
					<app-select [selectName]="'Музыка вместо гудков'"
					            [id]="'input_ringtone'"
					            [labelKey]="'name'"
					            [value]="currentAccount.ringtoneName"
					            [autocomplete]="true"
					            [readOnly]="false"
					            [options]="files"
					            (selectChange)="selectRingtone($event)"></app-select>
				</div>
				<div class="select-block">
					<app-select [selectName]="'Пользователи'"
					            [id]="'input_person'"
					            [labelKey]="'name'"
					            [value]="currentAccount.personName"
					            [autocomplete]="false"
					            [readOnly]="false"
					            [options]="users"
					            (selectChange)="selectPerson($event)"></app-select>
				</div>

				<div class="input-block">
					<label>Запись внутренних разговоров</label>
					<div class="base-toggle">
						<input type="checkbox" id="input_record" name="input_record" [checked]="currentAccount.recordConversations"
						       [(ngModel)]="currentAccount.recordConversations">
						<label for="input_record">
					          <span class="toggle-btn">
					            <span class="o"></span>
					          </span>
						</label>
					</div>
				</div>
				<div class="input-block">
					<label>Безусловный перевод звонка</label>
					<div class="base-toggle">
						<input type="checkbox" id="input_transfer" name="input_transfer" [checked]="currentAccount.phoneTransfer"
						       [(ngModel)]="currentAccount.phoneTransfer">
						<label for="input_transfer">
					          <span class="toggle-btn">
					            <span class="o"></span>
					          </span>
						</label>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="input-block">
					<label for="input_account_redirect">Номер для перевода звонка</label>
					<text-input id="input_account_redirect" name="input_account_redirect" [(ngModel)]="currentAccount.phoneRedirectNumber"></text-input>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataAccount.submitted && transferTimeout.invalid}">
					<label for="input_account_timeout">Время дозвона перед переводом звонка (сек.)</label>
					<input type="number" min="0" max="60" required placeholder="" id="input_account_timeout" name="input_account_timeout"
					       [(ngModel)]="currentAccount.transferTimeout" [min]="0" #transferTimeout="ngModel">
					<div *ngIf="formDataAccount.submitted && transferTimeout.invalid" class="invalid">
						<div *ngIf="transferTimeout.errors.required || transferTimeout.errors.max">Введите значение от 0 до 60</div>
					</div>
				</div>
				<div class="select-block">
					<app-select [selectName]="'Номер подстановки при переводе внутреннего звонка'"
					            [id]="'input_account_substitute_num'"
					            [labelKey]="'number'"
					            [value]="currentAccount.substituteNumber"
					            [autocomplete]="false"
					            [readOnly]="false"
					            [options]="externalNumbers"
					            (selectChange)="selectSubstituteNumber($event)"></app-select>
					<p class="info">
						По умолчанию будет выставлен номер из исходящей схемы: {{currentAccount.callSchemaExternalNumber}}
					</p>
				</div>
				<div class="select-block">
					<app-select [selectName]="'Статус'"
					            [id]="'input_status'"
					            [labelKey]="'status'"
					            [value]="currentAccount.status"
					            [autocomplete]="false"
					            [readOnly]="false"
					            [options]="statuses"
					            (selectChange)="selectStatus($event)"></app-select>
				</div>
				<div class="input-block">
					<label>Кол-во одновременных звонков</label>
					<input type="number" id="input_callLimit" name="input_callLimit" [(ngModel)]="currentAccount.callLimit">
				</div>
			</div>
			<div class="col">
				<div class="input-block">
					<label>SIP-пользователь/логин</label>
					<text-input id="input_peerName" name="input_peerName" [(ngModel)]="currentAccount.peerName"></text-input>
				</div>
				<div class="input-block">
					<label>Пароль</label>
					<text-input id="input_secret" name="input_secret" [(ngModel)]="currentAccount.secret"></text-input>
				</div>
				<div class="input-block">
					<label>SIP-сервер</label>
					<div class="value">{{currentAccount.pbxServerName}}</div>
				</div>
				<div class="input-block fmc-number">
					<label for="input_account_fmc">FMC номер</label>
					<text-input id="input_account_fmc" name="input_account_fmc" [(ngModel)]="currentAccount.fmc" #fmc="ngModel"></text-input>
				</div>
				<div class="input-block webrtc">
					<label>Поддержка WebRtc</label>
					<div class="base-toggle">
						<input type="checkbox" id="input_supportWebrtc" name="input_supportWebrtc" [checked]="currentAccount.supportWebrtc"
						       [(ngModel)]="currentAccount.supportWebrtc">
						<label for="input_supportWebrtc">
					          <span class="toggle-btn">
					            <span class="o"></span>
					          </span>
						</label>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(accountModal); formDataAccount.onReset()">Отмена</button>
		<button *ngIf="modalService.getState(accountModal) === 'ADD'" class="btn base-btn" (click)="create($event,formDataAccount)">Создать</button>
		<button *ngIf="modalService.getState(accountModal) === 'EDIT'" class="btn base-btn" (click)="update($event,formDataAccount)">Сохранить</button>
	</div>
</app-modal>
