import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ListSearchData} from "../model/common/list/listSearchData";
import {Page} from "../model/common/list/page";
import {SipPeerSearch} from "../model/common/list/sipPeerSearch";
import {SipPeer} from "../model/sipPeer";

@Injectable({
    providedIn: 'root'
})
export class SipPeerService {

    constructor(private http: HttpClient) {
    }

    getAll(searchData: ListSearchData<SipPeerSearch>) {
        return this.http.post<Page<SipPeer>>('api/sip-peer', searchData);
    }
}
