import {Order} from "./order";

export class ListSearchData<P> {
    page: number = 0;
    limit: number = 10;
    orders: Order[];
    searchPattern: P;

    constructor(pattern: P) {
        this.searchPattern = pattern;
    }
}
