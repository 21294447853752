import {Component, ViewChild} from '@angular/core';
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ModalService} from "../../../service/modal.service";
import {ActivatedRoute} from "@angular/router";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {AccountService} from "../../../service/account.service";
import {StorageFilesService} from "../../../service/storage-files.service";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {Page} from "../../../model/common/list/page";
import {StorageFile} from "../../../model/storageFile";
import {NgForm} from "@angular/forms";
import {StorageFileType} from "../../../model/storageFileType";
import {ModalState} from "../../../shared/modal/modal.state";
import {saveAs} from 'file-saver';
import {PlayerComponent} from "../../../shared/player/player.component";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-storage-files', templateUrl: './storage-files.component.html', styleUrls: ['./storage-files.component.css']
})
export class StorageFilesComponent extends BasePbxTabComponent {
    readonly storageFileModal = "storage-file-modal";
    readonly storageFileDeleteModal = "storage-file-delete-modal";
    readonly types = [{key: 'VOICE_FILE', label: StorageFileType.VOICE_FILE}, {key: 'MOH', label: StorageFileType.MOH}]

    listSearchData: ListSearchData<StorageFile>;
    pageLoaded: Promise<boolean>;
    page: Page<StorageFile>;
    currentStorageFile: StorageFile;

    @ViewChild(PlayerComponent) player:PlayerComponent;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService,
                protected accountService: AccountService, protected storageFilesService: StorageFilesService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit(): void {
        this.tab = 'storage-files';
        this.listSearchData = new ListSearchData(new StorageFile());
        this.loadPage();
    }

    loadPage() {
        this.storageFilesService.getListByPbxId(this.currentPbx.id, this.listSearchData).subscribe(value => {
            this.page = value;
            this.pageLoaded = Promise.resolve(true);
        })
    }

    pageChanged($event: number) {
        this.listSearchData.page = $event - 1;
        this.loadPage();
    }

    countChange($event: number) {
        this.listSearchData.limit = $event;
        this.loadPage();
    }

    createStorageFileModal() {
        this.currentStorageFile = new StorageFile();
        this.modalService.open(this.storageFileModal, ModalState.ADD);

    }

    createStorageFileDeleteModal(file: StorageFile) {
        this.currentStorageFile = file;
        this.modalService.open(this.storageFileDeleteModal, ModalState.DELETE);

    }

    download(file: StorageFile) {
        this.storageFilesService.download(file.id).toPromise().then(value => {
            saveAs(value, file.fileName);
        })
    }

    create($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentStorageFile) {
            this.storageFilesService.create(this.currentPbx.id, this.currentStorageFile).subscribe(value => {
                this.loadPage();
                this.closeModal(this.storageFileModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    handleFileInput(event) {
        if(event.target.files) {
            this.currentStorageFile.file = event.target.files.item(0);
        }
    }

    delete(currentStorageFile: StorageFile) {
        if(this.currentStorageFile && this.currentStorageFile.id) {
            this.storageFilesService.delete(currentStorageFile.id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.storageFileDeleteModal);
            })
        }
    }

    play(file: StorageFile) {
        this.currentStorageFile = file;
        this.player.play("api/storageFiles/" + this.currentStorageFile.id + "/play");
    }
}
