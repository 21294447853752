import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../service/authentication.service";
import {UserService} from "../../../service/user.service";
import {NgForm} from "@angular/forms";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-login', templateUrl: './login.component.html', styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    model: any = {};
    returnUrl: string;
    errorMessage: string;

    constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService, private userService: UserService, private http: HttpClient) {
        // redirect to dashboard if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/dashboard']);
        }
    }

    ngOnInit() {
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

        //
        $('.login-page__remember-pass').click(function (event) {
            event.preventDefault();
            $('.login-tabs').hide();
            $('.rember-pass-block').show();
        });
        $('.login-page__cancel-remember-pass').click(function (event) {
            event.preventDefault();
            $('.login-tabs').show();
            $('.rember-pass-block').hide();
        });
    }

    login($event: Event, form: NgForm) {
        if (form.valid) {
            console.log("Form valid")
            this.authenticationService.login(this.model.username, this.model.password)
                .subscribe(() => {
                    this.getUser();
                    this.getClientUrl();
                }, error => {
                    console.log(error);
                    this.errorMessage = "Неверный логин или пароль для входа";
                });
        }
    }

    private getUser() {
        this.userService.get().subscribe(user => {
            this.errorMessage = "";
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.authenticationService.currentUserSubject.next(user);
            this.router.navigate([this.returnUrl]);
        })
    }

    private getClientUrl() {
        let clientURL = localStorage.getItem("clientURL");
        if (clientURL === null || clientURL === "null") {
            this.http.get<string>('api/admin/client').subscribe(value => {
                clientURL = value;
                localStorage.setItem("clientURL", clientURL);
            });
        }
    }
}
