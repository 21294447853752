import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Page} from "../model/common/list/page";
import {Server} from "../model/server";
import {ListSearchData} from "../model/common/list/listSearchData";
import {AreaCode} from "../model/areaCode";
import {AreaCodeSearch} from "../model/common/list/areaCodeSearch";

@Injectable({
    providedIn: 'root'
})
export class AreaCodeService {

    constructor(private http: HttpClient) {
    }

    getById(id: number) {
        return this.http.get<AreaCode>('api/areaCode/' + id);
    }

    getAll(searchData: ListSearchData<AreaCodeSearch>) {
        return this.http.post<Page<AreaCode>>('api/areaCode', searchData);
    }

    save(code: AreaCode) {
        return this.http.put('api/areaCode', code);
    }

    delete(id: number) {
        return this.http.delete('api/areaCode/' + id);
    }
}
