import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {StorageFile} from "../model/storageFile";
import {ListSearchData} from "../model/common/list/listSearchData";
import {Page} from "../model/common/list/page";

@Injectable({
    providedIn: 'root'
})
export class StorageFilesService {

    constructor(private http: HttpClient) {
    }

    getByPbxId(id: number) {
        return this.http.get<StorageFile[]>(`api/storageFiles/pbx/${id}/moh`);
    }

    getListByPbxId(id: number, searchData: ListSearchData<StorageFile>) {
        return this.http.post<Page<StorageFile>>(`api/storageFiles/pbx/${id}/list`, searchData);
    }

    getRecords(searchData: ListSearchData<StorageFile>) {
        return this.http.post<Page<StorageFile>>(`api/storageFiles/records`, searchData);
    }

    create(pbxid: number, storageFile: StorageFile) {
        const formData: FormData = new FormData();
        formData.append('name', storageFile.name);
        formData.append('used', storageFile.used);
        formData.append('file', storageFile.file);

        return this.http.post(`api/storageFiles/pbx/${pbxid}`, formData);
    }

    delete(id: number) {
        return this.http.delete(`api/storageFiles/${id}`)
    }

    download(id: number) {
        return this.http.get(`api/storageFiles/${id}/download`, { responseType:'blob' })
    }
}
