export class ExternalNumber {
    id: number;
    pbxId: number;
    pbxName: string;
    number: string;
    type: string;
    action: string;
    inboundSchema: string;
    outboundSchema: string;
    createDatetime: string;
    outer: boolean;
    contractNumber: string;
}
