import {Component, OnInit} from '@angular/core';
import {LogoLoaderComponent} from "./shared/logo-loader/logo-loader.component";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
    selector: 'app-root', templateUrl: './app.component.html', styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    title = 'WebUi';

    public logoLoader = LogoLoaderComponent;

    constructor(private http: HttpClient, private router: Router) {
    }

    ngOnInit(): void {
    }
}
