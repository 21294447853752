import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {User} from "../model/user";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    isAdministrator(): boolean {
        return this.currentUserValue && this.currentUserValue.role === 'ADMINISTRATOR';
    }

    isManager(): boolean {
        return this.currentUserValue && this.currentUserValue.role === 'MANAGER';
    }

    isSupport(): boolean {
        return this.currentUserValue && this.currentUserValue.role === 'SUPPORT';
    }

    login(username: string, password: string) {
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
        var data = "j_username=" + encodeURIComponent(username) + "&j_password=" + encodeURIComponent(password) + "&_spring_security_remember_me=" + true + "&submit=Login";
        return this.http.post('api/authentication', data, {headers});
    }

    logout() {
        // remove user from local storage to log user out  admin@1telecom.ru
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        return this.http.post('api/logout', {}, {});
    }
}
