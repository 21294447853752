<div class="base-tab__block active" *ngIf="isDataAvailable && pageLoaded">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createHuntGroupModal()">
				Добавить группу
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по названию"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table" *ngIf="page">
				<thead>
				<tr>
					<td>ID</td>
					<td>Номер</td>
					<td>Название</td>
					<td>Стратегия</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let huntGroup of page.items">
					<td>{{huntGroup.id}}</td>
					<td>{{huntGroup.number}}</td>
					<td>{{huntGroup.name}}</td>
					<td>{{huntGroup.strategy | strategy}}</td>
					<td>
						<app-actions (edit)="createHuntGroupEditModal(huntGroup)" (delete)="createHuntGroupDeleteModal(huntGroup)"
						             (members)="createHuntGroupConfigModal(huntGroup.id)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createHuntGroupModal()">
				Добавить группу
			</button>
			<div *table-header-marker class="input-block search">
				<text-input id="input_1_mob" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по названию"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body">
			<table class="base-table" *ngIf="page">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let huntGroup of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">ID</div>
									<div class="data">{{huntGroup.id}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Номер</div>
									<div class="data">{{huntGroup.number}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Название</div>
									<div class="data">{{huntGroup.name}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Стратегия</div>
									<div class="data">{{huntGroup.strategy | strategy}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (edit)="createHuntGroupEditModal(huntGroup)" (delete)="createHuntGroupDeleteModal(huntGroup)"
											             (members)="createHuntGroupConfigModal(huntGroup.id)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="huntGroupModal" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataHuntGroup="ngForm">
			<div class="col" *ngIf="currentHuntGroup">
				<div class="input-block required" [ngClass]="{'invalid': formDataHuntGroup.submitted && name.invalid}">
					<label for="input_13">Название группы</label>
					<text-input id="input_13" name="input_13" required [(ngModel)]="currentHuntGroup.name" #name="ngModel"></text-input>
					<div *ngIf="formDataHuntGroup.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Введите название группы</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataHuntGroup.submitted && number.invalid}">
					<label for="input_23">Внутренний номер</label>
					<text-input id="input_23" name="input_23" required [(ngModel)]="currentHuntGroup.number" #number="ngModel"></text-input>
					<div *ngIf="formDataHuntGroup.submitted && number.invalid" class="invalid">
						<div *ngIf="name.errors.required">Введите номер</div>
					</div>
				</div>
				<div class="select-block">
					<label>Стратегия дозвона</label>
					<ng-select name="input_333"
					           [items]="strategies"
					           bindValue="key"
					           [clearable]="false"
					           [(ngModel)]="currentHuntGroup.strategy">
					</ng-select>
				</div>
				<div class="input-block">
					<label for="input_33">Интервал дозвона до следующего в очереди (сек.)</label>
					<input type="number" id="input_33" name="input_33" [(ngModel)]="currentHuntGroup.timeout" #timeout="ngModel" style="max-width: 80px;">
				</div>
				<div class="input-block">
					<label for="input_34">Завершить дозвон (сек.)</label>
					<input type="number" id="input_34" name="input_34" [(ngModel)]="currentHuntGroup.fullTimeout" #fullTimeout="ngModel" style="max-width: 80px;">
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(huntGroupModal); formDataHuntGroup.onReset()">Отмена</button>
		<button *ngIf="modalService.getState(huntGroupModal) === 'ADD'" class="btn base-btn" (click)="create($event, formDataHuntGroup)">Создать</button>
		<button *ngIf="modalService.getState(huntGroupModal) === 'EDIT'" class="btn base-btn" (click)="update($event, formDataHuntGroup)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="huntGroupDeleteModal" [title]="'Удалить группу'">
	<div body *ngIf="currentHuntGroup">
		<div class="settings-delete_popup--content">
			Удалить группу <span class="b">{{currentHuntGroup.name}}</span>?
		</div>
		<div class="settings-delete_popup--content" *ngIf="currentHuntGroup.numbers !== ''">
			Номера группы: <span class="b">{{currentHuntGroup.numbers}}</span>
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentHuntGroup.id)">Удалить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="huntGroupConfigModal" [additionalClass]="'huntGroups-numbers_popup'" [title]="'Добавить/Изменить номера для группы ' ">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body *ngIf="currentHuntGroup">
		<div class="internal-numbers__table --desktop" *ngIf="!isMobile">
			<div class="table-body scrollbar-inner">
				<table class="base-table">
					<thead>
					<tr>
						<td>№</td>
						<td>Номер</td>
						<td>Имя звонящего</td>
						<td>Ротация/Удаление</td>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let member of currentHuntGroup.members">
						<td>{{member.priority}}</td>
						<td>{{member.number}}</td>
						<td>{{member.name}}</td>
						<td>
							<div class="action">
								<app-actions (up)="moveUp(member)" (down)="moveDown(member)" (delete)="deleteMember(member)"></app-actions>
							</div>
						</td>
					</tr>
					<!--  -->
					</tbody>
				</table>
			</div>
		</div>
		<form action="">
			<div class="select-block">
				<label>Добавить номер</label>
				<ng-select name="input_333"
				           [items]="accounts"
				           bindLabel="number"
				           [(ngModel)]="account">
				</ng-select>
			</div>
			<button class="btn base-btn" (click)="addNumber()">+ Добавить</button>
		</form>
	</div>

	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(huntGroupConfigModal)">Отмена</button>
		<button class="btn base-btn" (click)="saveMembers()">Сохранить</button>
	</div>
</app-modal>
