<button class="btn table-action-btn icon base-tooltip top" (click)="show.emit('')" *ngIf="isShowUsed" data-tooltip="Показать">
	<svg>
		<use xlink:href="#eye"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="edit.emit('')" *ngIf="isEditUsed" data-tooltip="Редактировать">
	<svg>
		<use xlink:href="#edit"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="clientLogin.emit('')" *ngIf="isClientLoginUsed" data-tooltip="Открыть в клиенте">
	<svg>
		<use xlink:href="#client-login"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="next.emit('')" *ngIf="isNextUsed" data-tooltip="Выбрать">
	<svg>
		<use xlink:href="#next"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="deactivate.emit('')" *ngIf="isDeactivateUsed" data-tooltip="Деактивировать">
	<svg>
		<use xlink:href="#off"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="activate.emit('')" *ngIf="isActivateUsed" data-tooltip="Активировать">
	<svg>
		<use xlink:href="#on"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="accept.emit('')" *ngIf="isAcceptUsed" data-tooltip="Применить">
	<svg>
		<use xlink:href="#accept-circle"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="unselect.emit('')" *ngIf="isUnselectUsed" data-tooltip="Разблокировать">
	<svg>
		<use xlink:href="#unlock"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="setup.emit('')" *ngIf="isSetupUsed" data-tooltip="Решить конфликт">
	<svg>
		<use xlink:href="#setup"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="up.emit('')" *ngIf="isUpUsed" data-tooltip="Вверх">
	<svg>
		<use xlink:href="#up"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="down.emit('')" *ngIf="isDownUsed" data-tooltip="Вниз">
	<svg>
		<use xlink:href="#down"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="members.emit('')" *ngIf="isMembersUsed" data-tooltip="">
	<svg>
		<use xlink:href="#members"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="play.emit('')" *ngIf="isPlayUsed" data-tooltip="Воспроизвести">
	<svg>
		<use xlink:href="#play"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="download.emit('')" *ngIf="isDownloadUsed" data-tooltip="Скачать">
	<svg>
		<use xlink:href="#download"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="password.emit('')" *ngIf="isPasswordUsed" data-tooltip="Изменить пароль">
	<svg>
		<use xlink:href="#key"></use>
	</svg>
</button>
<button class="btn table-action-btn icon base-tooltip top" (click)="delete.emit('')" *ngIf="isDeleteUsed" data-tooltip="Удалить">
	<svg>
		<use xlink:href="#remove"></use>
	</svg>
</button>
