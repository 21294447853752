import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit() {
      // scroll bar init
      $(function(){
          $(".scrollbar-inner").each(function() {
              $(this).scrollbar();
          });
      });
      $('.main-sidebar__hamburger').on('click',function(){
          $(this).toggleClass('minimize');
          if( $('.main-sidebar').hasClass('minimize') ){
              $('.main-sidebar').removeClass('minimize')
          }else{
              $('.main-sidebar').addClass('minimize')
          }
      });


      // sidebar navigation btn
      $('.navigation-btn').click(function() {
          if (!$(this).parent().hasClass('opened')) {
              $('.navigation-head.opened').next('.navigation-list').slideUp()
              $('.navigation-head.opened').removeClass('opened');
          }
          $(this).parent().next('.navigation-list').slideToggle();
          $(this).parent().toggleClass('opened');
      });

      $('.navigation-link').click(function() {
          if($(this).parent().prev('.navigation-head').hasClass('opened')) {
              $('.navigation-head.opened').next('.navigation-list').slideUp()
              $('.navigation-head.opened').removeClass('opened');
          }
      });

      // header search open
      $('.header-search__open').click(function() {
          $(this).parent().addClass('opened');
          $('.main-container__header').addClass('search');
      });
      $('.header-search__close').click(function() {
          $(this).parent().removeClass('opened');
          $('.main-container__header').removeClass('search');
      });

      // complete task
      $('.complete-task-btn').click(function() {
          $(this).parent().toggleClass('completed');
      });

      // base-btn-select_btn
      $('.base-btn-select_btn').click(function() {
          $(this).parent().toggleClass('active');
      });


      // popup open _btn
      $('.popup-btn__open').click(function() {
          $('.base-popup').addClass('opened');
      });
      // popup close _btn
      $('.popup-btn__close').click(function() {
          $('.base-popup').removeClass('opened');
      });

      // table sorting btn
      $('.table-sorting__btn').click(function() {
          if($(this).hasClass('up')){
              $(this).removeClass('up');
              $(this).addClass('down');
          }else if($(this).hasClass('down')){
              $(this).removeClass('down');
          }else{
              $(this).addClass('up');
          }
      });

      // page filters toggle
      $('.page-filters__btn').click(function() {
          console.log(1)
          if (!$(this).hasClass('opened')) {
              $('.page-filters').slideUp()
              $('.page-filters__btn.opened').removeClass('opened');
          }
          $('.page-filters').slideToggle();
          $(this).toggleClass('opened');
      });
  }

}
