import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {FaxesService} from "../../../service/faxes.service";
import {Page} from "../../../model/common/list/page";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {Fax} from "../../../model/fax";
import {ModalState} from "../../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-faxes',
  templateUrl: './faxes.component.html',
  styleUrls: ['./faxes.component.css']
})
export class FaxesComponent extends BasePbxTabComponent {
    readonly faxModal = "fax-modal";
    readonly faxDeleteModal = "fax-delete-modal";

    pageLoaded: Promise<boolean>;
    page: Page<Fax>;
    listSearchData: ListSearchData<Fax>;
    currentFax: Fax;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService,
                public faxesService: FaxesService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'faxes';
        let fax = new Fax();
        fax.pbxId = this.currentPbx.id;
        this.listSearchData = new ListSearchData(fax);
        this.loadPage();
    }

    loadPage() {
        this.faxesService.getListByPbx(this.currentPbx.id, this.listSearchData).subscribe(value => {
            this.page = value;
            this.pageLoaded = Promise.resolve(true);
        })
    }

    pageChanged(page: number) {
        this.listSearchData.page = page - 1;
        this.loadPage();
    }

    countChange(count: number) {
        this.listSearchData.limit = count;
        this.loadPage();
    }

    createModal() {
        this.currentFax = new Fax();
        this.modalService.open(this.faxModal, ModalState.ADD);
    }

    createEditModal(fax: Fax) {
        this.faxesService.getById(fax.id).subscribe(value => {
            this.currentFax = value;
            this.modalService.open(this.faxModal, ModalState.EDIT);
        });
    }

    createDeleteModal(fax: Fax) {
        this.currentFax = fax;
        this.modalService.open(this.faxDeleteModal, ModalState.DELETE);
    }

    delete(id: number) {
        if(this.currentFax && this.currentFax.id === id) {
            this.faxesService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.faxDeleteModal);
            })
        }
    }

    create($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentFax) {
            this.faxesService.create(this.currentPbx.id, this.currentFax).subscribe(id => {
                this.loadPage();
                this.closeModal(this.faxModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    update($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentFax) {
            this.faxesService.update(this.currentFax).subscribe(id => {
                this.loadPage();
                this.closeModal(this.faxModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }
}
