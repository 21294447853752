import {Component, OnDestroy, OnInit} from '@angular/core';
import {AtsService} from "../../service/ats.service";
import {PbxForm} from "../../model/pbxForm";
import {ActivatedRoute} from "@angular/router";
import {map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {RouteStateService} from "../../service/route-state.service";

@Component({
    selector: 'app-pbx', //
    templateUrl: './pbx.component.html', //
    styleUrls: ['./pbx.component.css']
})
export class PbxComponent implements OnInit, OnDestroy {
    currentPbx: PbxForm = new PbxForm();
    state: string;
    tab = "";
    private destroy = new Subject<void>();
    constructor(private atsService: AtsService, private route: ActivatedRoute, private routeStateService: RouteStateService) {

    }

    ngOnInit() {
        this.atsService.currentPbx$.subscribe(value => {
            this.currentPbx = value;
        });

        this.atsService.currentTab$.subscribe(value => {
            setTimeout(() => this.tab = value, 0)
        });

        this.initJS();
        this.state = this.route.snapshot.paramMap.get('state');
        this.route.paramMap
            .pipe(map(paramMap => paramMap), takeUntil(this.destroy))
            .subscribe(routePathParam => this.routeStateService.updatePathParamState(routePathParam));
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
        this.atsService.setCurrentPbx(new PbxForm());
        this.routeStateService.updatePathParamState(null);
    }

    private initJS() {
        // scroll
        $(function(){
            $(".scrollbar-inner").each(function() {
                $(this).scrollbar();
            });
        });
    }
}
