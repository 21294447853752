<div class="title">
	<span class="over-title">Настройка</span>
	Список пользователей
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn popup-btn__open right-margin" (click)="createAddUserModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id" name="input_id" [(ngModel)]="listSearchData.searchPattern.id" placeholder="ID"></text-input>
			</div>
			<div class="input-block login">
				<text-input id="input_login" name="input_login" [(ngModel)]="listSearchData.searchPattern.login" placeholder="Логин"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_name" name="input_name" [(ngModel)]="listSearchData.searchPattern.firstName" placeholder="Имя"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Роль" value="{{listSearchData.searchPattern.role | role}}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.role = undefined">Все</a>
							<a data-value="ADMINISTRATOR" (click)="listSearchData.searchPattern.role = 'ADMINISTRATOR'">{{ 'ADMINISTRATOR' | role }}</a>
							<a data-value="MANAGER" (click)="listSearchData.searchPattern.role = 'MANAGER'">{{ 'MANAGER' | role }}</a>
						</div>
					</div>
				</dd>
			</dl>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td >
					<span>ID</span>
					<app-sort [component]="this" [property]="'id'"></app-sort>
				</td>
				<td >
					<span>Дата и время создания</span>
					<app-sort [component]="this" [property]="'createDatetime'"></app-sort>
				</td>
				<td >
					<span>Имя</span>
					<app-sort [component]="this" [property]="'firstName'"></app-sort>
				</td>
				<td >
					<span>Логин</span>
					<app-sort [component]="this" [property]="'login'"></app-sort>
				</td>
				<td >
					<span>Роль</span>
					<app-sort [component]="this" [property]="'role'"></app-sort>
				</td>
				<td></td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let user of page.items">
				<td class="id">{{user.id}}</td>
				<td>{{user.createDatetime}}</td>
				<td>{{user.firstName}} {{user.surName}}</td>
				<td>{{user.login}}</td>
				<td>{{user.role | role}}</td>
				<td class="actions">
					<app-actions *ngIf="user.role === 'ADMINISTRATOR'"
					             (show)="createShowUserModal(user.id)"
					             (edit)="createEditUserModal(user.id)"></app-actions>
					<app-actions *ngIf="user.role !== 'ADMINISTRATOR'"
					             (show)="createShowUserModal(user.id)"
					             (edit)="createEditUserModal(user.id)"
					             (delete)="createDeleteUserModal(user)"></app-actions>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn popup-btn__open" (click)="createAddUserModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id_mob" name="input_id" [(ngModel)]="listSearchData.searchPattern.id" placeholder="ID"></text-input>
			</div>
			<div class="input-block login">
				<text-input id="input_login_mob" name="input_login" [(ngModel)]="listSearchData.searchPattern.login" placeholder="Логин"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_name_mob" name="input_name" [(ngModel)]="listSearchData.searchPattern.firstName" placeholder="Имя"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Роль" value="{{listSearchData.searchPattern.role | role}}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.role = undefined">Все</a>
							<a data-value="ADMINISTRATOR" (click)="listSearchData.searchPattern.role = 'ADMINISTRATOR'">{{ 'ADMINISTRATOR' | role }}</a>
							<a data-value="MANAGER" (click)="listSearchData.searchPattern.role = 'MANAGER'">{{ 'MANAGER' | role }}</a>
						</div>
					</div>
				</dd>
			</dl>
		</div>
	</app-table-header>
	<div class="table-body">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let user of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">ID</div>
								<div class="data">{{user.id}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Дата и время создания</div>
								<div class="data">{{user.createDatetime}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Имя</div>
								<div class="data">{{user.firstName}} {{user.surName}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Логин</div>
								<div class="data">{{user.login}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Роль</div>
								<div class="data">{{user.role | role}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="data">
									<div class="action">
										<app-actions *ngIf="user.role === 'ADMINISTRATOR'"
										             (show)="createShowUserModal(user.id)"
										             (edit)="createEditUserModal(user.id)"></app-actions>
										<app-actions *ngIf="user.role !== 'ADMINISTRATOR'"
										             (show)="createShowUserModal(user.id)"
										             (edit)="createEditUserModal(user.id)"
										             (delete)="createDeleteUserModal(user)"></app-actions>
									</div>
								</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<app-modal [id]=userModal>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formData="ngForm">
			<div class="col" *ngIf="currentUser">
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && name.invalid}">
					<label for="input_name">Имя</label>
					<text-input id="input_name_modal" name="input_name" required [(ngModel)]="currentUser.firstName" #name="ngModel"
					            [readOnly]="modalService.getState(userModal) === 'SHOW'"></text-input>
					<div *ngIf="formData.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Введите имя пользователя</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && surname.invalid}">
					<label for="input_surname">Фамилия</label>
					<text-input id="input_surname" name="input_surname" required [(ngModel)]="currentUser.surName" #surname="ngModel"
					            [readOnly]="modalService.getState(userModal) === 'SHOW'"></text-input>
					<div *ngIf="formData.submitted && surname.invalid" class="invalid">
						<div *ngIf="surname.errors.required">Введите фамилию пользователя</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && email.invalid}">
					<label for="input_email">E-mail</label>
					<text-input id="input_email" name="input_email" required [(ngModel)]="currentUser.email" #email="ngModel"
					            [readOnly]="modalService.getState(userModal) === 'SHOW'"></text-input>
					<div *ngIf="formData.submitted && email.invalid" class="invalid">
						<div *ngIf="email.errors.required">Введите e-mail</div>
					</div>
				</div>
			</div>
			<div class="col" *ngIf="currentUser">
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && login.invalid}">
					<label for="input_login">Логин</label>
					<text-input id="input_login_modal" name="input_login" required [(ngModel)]="currentUser.login" #login="ngModel"
					            [readOnly]="modalService.getState(userModal) === 'SHOW'"></text-input>
					<div *ngIf="formData.submitted && login.invalid" class="invalid">
						<div *ngIf="login.errors.required">Введите логин пользователя</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && password.invalid}">
					<label for="input_password">Пароль</label>
					<text-input id="input_password" name="input_password" required [(ngModel)]="currentUser.password" #password="ngModel"
					            [readOnly]="modalService.getState(userModal) === 'SHOW'"></text-input>
					<div *ngIf="formData.submitted && password.invalid" class="invalid">
						<div *ngIf="password.errors.required">Введите пароль пользователя</div>
					</div>
				</div>
				<div class="select-block">
					<app-select [selectName]="'Роль'"
					            [id]="'input_role'"
					            [labelKey]="'label'"
					            [value]="currentUser.role | role"
					            [autocomplete]="false"
					            [readOnly]="modalService.getState(userModal) === 'SHOW' || currentUser.role === 'ADMINISTRATOR'"
					            [options]="roles"
					            (selectChange)="selectRole($event)"></app-select>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(userModal); formData.onReset()">Отмена</button>
		<button *ngIf="modalService.getState(userModal) === 'ADD'" class="btn base-btn" (click)="save($event, formData)">Создать</button>
		<button *ngIf="modalService.getState(userModal) === 'EDIT'" class="btn base-btn" (click)="save($event, formData)">Сохранить</button>
	</div>
</app-modal>

<app-modal [id]=userDeleteModal>
	<div body>Вы действительно хотите удалить пользователя {{currentUser.login}} с ID {{currentUser.id}}?</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(userDeleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentUser.id)">Удалить</button>
	</div>
</app-modal>
