<main class="1ats__page login-page">
	<div class="main-wrap">
		<div class="login-block">
			<a href="#" class="logo">
				<!-- Generator: Adobe Illustrator 22.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
				<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				     viewBox="0 0 100 46" style="enable-background:new 0 0 100 46;" xml:space="preserve">
<style type="text/css">
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#XMLID_2_);}
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
</style>
					<g>
	<linearGradient id="XMLID_2_" gradientUnits="userSpaceOnUse" x1="5.5058" y1="-5.6353" x2="21.8431" y2="52.143">
		<stop  offset="0" style="stop-color:#F8AF19"/>
		<stop  offset="1" style="stop-color:#EF791B"/>
	</linearGradient>
						<path id="XMLID_128_" class="st0" d="M13.3,37.9c1.6,0,3-1.3,3-3s-1.3-3-3-3c-1.6,0-3,1.3-3,3S11.7,37.9,13.3,37.9z M16,29.9
		c1.6,0,3-1.3,3-3s-1.3-3-3-3c-1.6,0-3,1.3-3,3S14.4,29.9,16,29.9z M18.8,22c1.6,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S17.2,22,18.8,22
		z M21.5,14.1c1.6,0,3-1.3,3-3c0-1.6-1.3-3-3-3s-3,1.3-3,3C18.6,12.7,19.9,14.1,21.5,14.1z M24.2,6.1c1.6,0,3-1.3,3-3s-1.3-3-3-3
		s-3,1.3-3,3S22.6,6.1,24.2,6.1z M3,45.8c1.6,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S1.3,45.8,3,45.8z M5.7,37.9c1.6,0,3-1.3,3-3
		s-1.3-3-3-3s-3,1.3-3,3S4,37.9,5.7,37.9z M8.4,29.9c1.6,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S6.7,29.9,8.4,29.9z M11.2,22
		c1.6,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S9.6,22,11.2,22z M13.9,14.1c1.6,0,3-1.3,3-3c0-1.6-1.3-3-3-3c-1.6,0-3,1.3-3,3
		C10.9,12.7,12.2,14.1,13.9,14.1z M16.6,6.1c1.6,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S14.9,6.1,16.6,6.1z M6.2,14.1c1.6,0,3-1.3,3-3
		c0-1.6-1.3-3-3-3s-3,1.3-3,3C3.3,12.7,4.6,14.1,6.2,14.1z"/>
						<path id="XMLID_123_" class="st1" d="M75.9,24.5c0,7.7,5.3,13.5,13.7,13.5c4.8,0,8.2-2,10.5-4.4L96.7,29c-1.8,1.8-4.3,3.2-6.9,3.2
		c-5,0-7.4-4-7.4-7.8c0-3.8,2.1-7.4,7.4-7.4c2.3,0,4.8,1.2,6.5,2.7l2.9-4.8c-2.5-2.5-6.3-3.7-9.8-3.7C81.5,11.1,75.9,16.9,75.9,24.5
		z M59.8,37.6h6.4V17H74v-5.5H51.9V17h7.9V37.6z M36.7,27l1.5-5.1c0.5-1.6,1.1-4.5,1.1-4.5h0.1c0,0,0.6,2.8,1.1,4.5l1.5,5.1H36.7z
		 M27.1,37.6h6.6l1.6-5.5h8.1l1.6,5.5h6.6l-8.8-26h-6.7L27.1,37.6z"/>
</g>
</svg>

			</a>
			<div class="login-form">
				<div class="login-tabs base-tab">
					<!-- <div class="base-tab__header">
						<button class="btn base-tab__btn active" data-tabname="number">По Номеру</button>
						<button class="btn base-tab__btn" data-tabname="password">По паролю</button>
					</div> -->
					<div class="base-tab__body">
						<div class="base-tab__block active" data-tabname="password">
							<div *ngIf="errorMessage" class="alert">
								<p style="color: #f51d39">{{errorMessage}}</p>
							</div>
							<form name="form" #formDataLogin="ngForm">
								<div class="input-block white" [ngClass]="{'invalid': formDataLogin.submitted && username.invalid}">
									<label for="input_login">Логин</label>
									<input type="text" id="input_login" name="input_login" [(ngModel)]="model.username" #username="ngModel" required>
									<div *ngIf="formDataLogin.submitted && username.invalid" class="invalid">
										<div *ngIf="username.errors.required">Введите логин</div>
									</div>
								</div>
								<div class="input-block white" [ngClass]="{'invalid': formDataLogin.submitted && username.invalid}">
									<label for="input_pass">Пароль</label>
									<input type="password" id="input_pass" name="input_pass" [(ngModel)]="model.password" #password="ngModel" required>
									<div *ngIf="formDataLogin.submitted && password.invalid" class="invalid">
										<div *ngIf="password.errors.required">Введите пароль</div>
									</div>
								</div>
								<div class="row">
									<button class="btn base-link login-page__remember-pass">Забыли пароль?</button>
								</div>
								<button class="btn base-btn btn-submit" (click)="login($event, formDataLogin)">Войти</button>
							</form>
						</div>
						<!-- <div class="base-tab__block active" data-tabname="number">
							<form action="">
								<div class="input-block white">
									<label for="input_number">Номер</label>
									<input type="text" id="input_number" name="input_number">
								</div>
								<div class="input-block white">
									<label for="input_pass2">Пароль</label>
									<input type="password" id="input_pass2" name="input_pass2">
								</div>
								<div class="row">
									<button class="btn base-link login-page__remember-pass">Забыли пароль?</button>
								</div>
								<button class="btn base-btn btn-submit">Войти</button>
							</form>
						</div> -->
					</div>
				</div>
				<div class="rember-pass-block">
					<div class="header">
						<div class="top">
							<span class="title">Востановление пароля</span>
							<button class="btn base-link login-page__cancel-remember-pass">отменить</button>
						</div>
						<div class="info">
							Вы получите письмо, где будет ссылка для потверждения действия.
						</div>
					</div>
					<div class="body">
						<form action="">
							<div class="input-block white">
								<label for="input_email">Почта</label>
								<input type="text" id="input_email" name="input_email">
							</div>
							<button class="btn base-btn btn-submit">Восcтановить</button>
						</form>
					</div>
				</div>
			</div>
			<div class="row user-manual">
				<a href="#" class="user-manual-link">
					<svg>
						<use xlink:href="#information"/>
					</svg>
					<span>Руководство пользователя</span>
				</a>
			</div>
		</div>
	</div>
</main>
