export class ServerSearch {

    constructor() {
    }

    public id: number;
    public name: string;
    public status: string;
    public callManagerId: number;
}
