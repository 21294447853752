import {ChangeDetectionStrategy, Component, forwardRef, Injectable, Input} from '@angular/core';
import {DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl} from "ng-pick-datetime";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Moment} from "moment-timezone/moment-timezone";
import * as moment from 'moment';
import {MomentDateTimeAdapter} from "ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class";

export const MY_CUSTOM_FORMATS = {
    parseInput: 'DD.MM.YYYY HH:mm',
    fullPickerInput: 'DD.MM.YYYY HH:mm',
    datePickerInput: 'DD.MM.YYYY',
    timePickerInput: 'HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
};

@Injectable()
export class DefaultIntl extends OwlDateTimeIntl {
    cancelBtnLabel = 'Отмена';
    setBtnLabel = 'OK';
}

@Component({
    selector: 'app-datetime-picker',
    templateUrl: './date-time-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimePickerComponent), multi: true},
        {provide: OWL_DATE_TIME_LOCALE, useValue: 'ru'},
        {provide: OwlDateTimeIntl, useClass: DefaultIntl}
        ],
})
export class DateTimePickerComponent implements ControlValueAccessor {

    private _value: Moment;

    @Input() placeholder: string = null;
    @Input() readOnly: boolean = false;

    get value() {
        return this._value;
    }

    set value(v) {
        if (typeof v === 'string') {
            v = moment(v);
        }
        if (v) {
            v.utc(true);
        }
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    constructor() {
    }

    onChange = (_) => {
    };
    onTouched = () => {
    };

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error("Method not implemented.");
    }

}
