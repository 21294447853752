<header class="main-container__header">
	<div class="base-dropdown">
		<div class="base-dropdown-link w-icon">
			<svg>
				<use xlink:href="#add"></use>
			</svg>
			<span>Действия</span>
		</div>
		<div class="base-dropdown-wrap">
			<div class="base-dropdown-list">
				<a href="#" class="base-dropdown-list-item">Option 1</a>
				<a href="#" class="base-dropdown-list-item">Option 2</a>
				<a href="#" class="base-dropdown-list-item">Option 3</a>
			</div>
		</div>
	</div>
	<div class="base-dropdown">
		<div class="base-dropdown-link w-icon">
			<svg>
				<use xlink:href="#graph"></use>
			</svg>
			<span>Отчеты</span>
		</div>
		<div class="base-dropdown-wrap">
			<div class="base-dropdown-list">
				<a href="#" class="base-dropdown-list-item">Option 1</a>
				<a href="#" class="base-dropdown-list-item">Option 2</a>
				<a href="#" class="base-dropdown-list-item">Option 3</a>
			</div>
		</div>
	</div>
	<div class="header-controls">
		<div class="header-search">
			<button class="btn header-search__open">
				<svg>
					<use xlink:href="#search"></use>
				</svg>
			</button>
			<div class="input-mask">
				<text-input placeholder="Поиск"></text-input>
			</div>
			<button class="btn header-search__close">
				<svg>
					<use xlink:href="#cancel"></use>
				</svg>
			</button>
			<a href="#" class="base-link">Расширенный поиск</a>
		</div>
		<a href="#" class="header-add-task base-tooltip" data-tooltip="Add Task">
			<svg>
				<use xlink:href="#list"></use>
			</svg>
		</a>
		<div class="header-language">
			<div class="base-dropdown right">
				<div class="base-dropdown-link w-icon">
					<svg>
						<use xlink:href="#united-kingdom--clr"></use>
					</svg>
				</div>
				<div class="base-dropdown-wrap">
					<div class="base-dropdown-list">
						<a href="#" class="base-dropdown-list-item">
							<svg>
								<use xlink:href="#russia--clr"></use>
							</svg>
							<span>Русский</span>
						</a>
						<a href="#" class="base-dropdown-list-item">
							<svg>
								<use xlink:href="#germany--clr"></use>
							</svg>
							<span>German</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</header>
