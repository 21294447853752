<div class="base-tab__block active" *ngIf="isDataAvailable && pageLoaded">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<div *table-header-marker class="integration-api-keys">
				<span class="left">API ключ: {{apiKeys.apiKey}}</span>
				<span class="right">Секретный API ключ: {{apiKeys.apiSecretKey}}</span>
			</div>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить интеграцию
			</button>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table" *ngIf="page">
				<thead>
				<tr>
					<td>Номер</td>
					<td>Имя</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let integration of page">
					<td>{{integration.externalNumber}}</td>
					<td>{{integration.name}}</td>
					<td>
						<app-actions (edit)="createEditModal(integration)" (delete)="createDeleteModal(integration)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить интеграцию
			</button>
		</app-table-header>
		<div class="table-body">
			<table class="base-table" *ngIf="page">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let integration of page">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Номер</div>
									<div class="data">{{integration.externalNumber}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Имя</div>
									<div class="data">{{integration.name}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (edit)="createEditModal(integration)" (delete)="createDeleteModal(integration)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="integrationModal" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formData="ngForm">
			<div class="col" *ngIf="currentIntegration">
				<div class="select-block">
					<app-select [selectName]="'Внешний номер'"
					            [id]="'input_integration_externalNumber'"
					            [labelKey]="'number'"
					            [value]="currentIntegration.externalNumber"
					            [autocomplete]="false"
					            [readOnly]="false"
					            [options]="externalNumbers"
					            (selectChange)="selectExternalNumber($event)"></app-select>
				</div>
				<div class="input-block">
					<label>Использовать готовую интеграцию?</label>
					<div class="base-toggle">
						<input type="checkbox" id="input_isCustom" name="input_isCustom" [checked]="isPrepared"
						       [(ngModel)]="isPrepared">
						<label for="input_isCustom">
					          <span class="toggle-btn">
					            <span class="o"></span>
					          </span>
						</label>
					</div>
				</div>
					<div *ngIf="!isPrepared" class="input-block required" [ngClass]="{'invalid': formData.submitted && integrationName.invalid}">
						<label for="integrationName">Название интеграции</label>
						<input id="integrationName" name="integrationName" required [(ngModel)]="currentIntegration.name" #integrationName="ngModel">
						<div *ngIf="formData.submitted && integrationName.invalid" class="invalid">
							<div *ngIf="integrationName.errors.required">Введите имя интеграции</div>
						</div>
					</div>
				<div *ngIf="isPrepared" class="select-block">
					<app-select [selectName]="'Готовая интеграция'"
					            [id]="'input_integration_selectedCRM'"
					            [labelKey]="'showVal'"
					            [value]="selectedCRM.showVal"
					            [autocomplete]="false"
					            [readOnly]="false"
					            [options]="crmSystems"
					            (selectChange)="selectCRM($event)"></app-select>
				</div>
				<div *ngIf="!isPrepared || selectedCRM.valueField == '1C'"> <!-- Disabled for Gentleman. Why? -->
					<div class="input-block">
						<label for="callNotificationUrl">Адрес уведомления о звонке</label>
						<input id="callNotificationUrl" name="callNotificationUrl" [(ngModel)]="currentIntegration.callNotificationUrl">
					</div>
					<div class="input-block">
						<label for="getManagersUrl">Адрес получения менеджеров</label>
						<input id="getManagersUrl" name="getManagersUrl" [(ngModel)]="currentIntegration.getManagersUrl">
					</div>
				</div>
				<div *ngIf="isPrepared">
					<div *ngIf="selectedCRM.valueField != '1C' && selectedCRM.valueField != 'Bitrix24' && selectedCRM.valueField != 'AmoCRM' && selectedCRM.valueField != 'Roistat'">
						<div class="input-block required" [ngClass]="{'invalid': formData.submitted && crmKey.invalid}">
							<label for="crmKey">API ключ CRM</label>
							<input id="crmKey" name="crmKey" required [(ngModel)]="currentIntegration.key" #crmKey="ngModel">
						</div>
						<div class="input-block" *ngIf="selectedCRM.valueField =='Planfix' || selectedCRM.valueField =='SynergyCRM'">
							<label for="uniqueApiKey">API ключ</label>
							<input *ngIf="currentIntegration.uniqueApiKey" disabled id="uniqueApiKey" name="uniqueApiKey" [(ngModel)]="currentIntegration.uniqueApiKey">
							<button type="button" *ngIf="!currentIntegration.uniqueApiKey" class="btn base-btn" (click)="generateUniqueApiKey(currentIntegration)">Получить ключ</button>
						</div>
					</div>
					<!-- AmoCRM -->
					<div *ngIf="selectedCRM.valueField =='AmoCRM'" class="input-block">
						<label for="domainName">Адрес системы (без 'https://')</label>
						<input id="domainName" name="domainName" [(ngModel)]="currentIntegration.domainName" (change)="checkDomainValid(currentIntegration)">
					</div>
					<!-- Planfix -->
					<div *ngIf="selectedCRM.valueField =='Planfix'" class="input-block">
						<label for="domainPlanfix">Домен</label>
						<input id="domainPlanfix" name="domainPlanfix" [(ngModel)]="currentIntegration.domainName">
					</div>
					<!-- SynergyCRM -->
					<div *ngIf="selectedCRM.valueField =='SynergyCRM'" class="input-block">
						<label for="callNotificationUrlSynergy">Адрес уведомления о звонке</label>
						<input id="callNotificationUrlSynergy" name="callNotificationUrlSynergy" [(ngModel)]="currentIntegration.callNotificationUrl">
					</div>
					<!-- Bitrix24 -->
					<div *ngIf="selectedCRM.valueField=='Bitrix24'">
						<div class="input-block">
							<label for="domainBitrix">Адрес системы (без 'https://')</label>
							<input id="domainBitrix" name="domainBitrix" [(ngModel)]="currentIntegration.domainName" (change)="checkDomainValid(currentIntegration)">
						</div>
						<div class="input-block">
							<label>Автоматическое создание лида для незнакомых номеров</label>
							<div class="base-toggle">
								<input type="checkbox" id="autoAddLead" name="autoAddLead" [checked]="currentIntegration.autoAddLead"
								       [(ngModel)]="currentIntegration.autoAddLead">
								<label for="autoAddLead">
					          <span class="toggle-btn">
					            <span class="o"></span>
					          </span>
								</label>
							</div>
						</div>
					</div>
					<!-- RetailCRM -->
					<div *ngIf="selectedCRM.valueField =='RetailCRM'" class="input-block">
						<label for="domainRetail">Адрес системы</label>
						<input id="domainRetail" name="domainRetail" [(ngModel)]="currentIntegration.domainName">
					</div>
					<!-- Roistat -->
					<div *ngIf="selectedCRM.valueField != 'Roistat'" class="input-block">
						<label>Получение менеджеров по номеру телефона</label>
						<div class="base-toggle">
							<input type="checkbox" id="getManagers" name="getManagers" [checked]="currentIntegration.getManagers"
							       [(ngModel)]="currentIntegration.getManagers">
							<label for="getManagers">
					          <span class="toggle-btn">
					            <span class="o"></span>
					          </span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(integrationModal); formData.onReset(); currentIntegration = null">Отмена</button>
		<button *ngIf="modalService.getState(integrationModal) === 'ADD'" class="btn base-btn" (click)="save($event, formData)">Создать</button>
		<button *ngIf="modalService.getState(integrationModal) === 'EDIT'" class="btn base-btn" (click)="save($event, formData)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="integrationDeleteModal" [title]="'Удалить интеграцию'">
	<div body *ngIf="currentIntegration">
		<div class="settings-delete_popup--content">
			Удалить интеграцию: <span class="b">{{currentIntegration.externalNumber}} - {{currentIntegration.name}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(integrationDeleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentIntegration.id)">Удалить</button>
	</div>
</app-modal>
