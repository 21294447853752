<div class="base-tab__block active">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn right-margin" (click)="createAclModal()">
				Добавить IP
			</button>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table">
				<thead>
				<tr>
					<td>ID</td>
					<td>Название</td>
					<td>Доступный адрес/диапазон адресов</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let pbxAcl of pbxAcls">
					<td>{{pbxAcl.id}}</td>
					<td>{{pbxAcl.type === 'SIMPLE' ? 'IP адрес' : 'Диапазон адресов'}}</td>
					<td>{{pbxAcl.type === 'SIMPLE' ? pbxAcl.address : pbxAcl.type === 'RANGE' ? pbxAcl.addressFrom + ' - ' + pbxAcl.addressTo : pbxAcl.addressSubnet}}</td>
					<td>
						<app-actions (show)="showAclModal(pbxAcl)" (delete)="createDeleteAclModal(pbxAcl)" (edit)="editAclModal(pbxAcl.id)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createAclModal()">
				Добавить IP
			</button>
		</app-table-header>
		<div class="table-body">
			<table class="base-table">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let pbxAcl of pbxAcls">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">ID</div>
									<div class="data">{{pbxAcl.id}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Название</div>
									<div class="data">{{pbxAcl.type === 'SIMPLE' ? 'IP адрес' : 'Диапазон адресов'}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Доступный адрес/диапазон адресов</div>
									<div class="data">{{pbxAcl.type === 'SIMPLE' ? pbxAcl.address : pbxAcl.type === 'RANGE' ? pbxAcl.addressFrom + ' - ' + pbxAcl.addressTo : pbxAcl.addressSubnet}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (show)="showAclModal(pbxAcl)" (delete)="createDeleteAclModal(pbxAcl)"
											             (edit)="editAclModal(pbxAcl.id)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="'acl-delete-modal'">
	<div body>Вы действительно хотите удалить IP адрес
		"{{currentPbxAcl.type === 'SIMPLE' ? currentPbxAcl.address : currentPbxAcl.type === 'RANGE' ? currentPbxAcl.addressFrom + ' - ' + currentPbxAcl.addressTo : currentPbxAcl.addressSubnet}}
		"?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('acl-delete-modal')">Отмена</button>
		<button class="btn base-btn red" (click)="deleteAcl(currentPbxAcl.id)">Удалить</button>
	</div>
</app-modal>

<app-modal [id]="'pbx-acl-modal'" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataIp="ngForm">
			<div class="col">
				<div class="input-block required" [ngClass]="{'invalid': formDataIp.submitted && ip.invalid}">
					<label for="input_ip">IP адрес</label>
					<input [disabled]="modalService.getState('pbx-acl-modal') === 'SHOW'" type="text" required id="input_ip" name="input_ip"
					       [(ngModel)]="currentPbxAcl.address"
					       pattern="^((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?))(-(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)$)?(\/([1-2.]?[0-9]$)|(\/3[0-2]$))?$"
					       #ip="ngModel">

					<div *ngIf="formDataIp.submitted && ip.invalid" class="invalid">
						<div *ngIf="ip.errors.required">Поле не может быть пустым</div>
						<div *ngIf="ip.errors.pattern">Не соответствует формату IPv4</div>
					</div>

				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('pbx-acl-modal'); formDataIp.onReset()">Отмена</button>
		<button *ngIf="modalService.getState('pbx-acl-modal') === 'ADD'" class="btn base-btn" (click)="createACL($event, formDataIp)">Создать</button>
		<button *ngIf="modalService.getState('pbx-acl-modal') === 'EDIT'" class="btn base-btn" (click)="updateACL($event, formDataIp)">Сохранить</button>
	</div>
</app-modal>
