import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User} from "../model/user";
import {Page} from "../model/common/list/page";
import {ChangePassword} from "../model/changePassword";
import {ListSearchData} from "../model/common/list/listSearchData";
import {UserSearch} from "../model/common/list/userSearch";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    get() {
        return this.http.get<User>('api/admin')
    }

    getById(id: number) {
        return this.http.get<User>(`api/persons/${id}`);
    }

    getByCompanyId(id: number) {
        return this.http.get<User[]>(`api/persons/company/${id}`);
    }

    getPage(listSearchData: ListSearchData<UserSearch>, isClient: boolean) {
        return this.http.post<Page<User>>(`api/persons/page/` + (isClient ? 'client' : 'admin'), listSearchData);
    }

    save(person: User) {
        return this.http.post(`api/persons`, person);
    }

    changePassword(passwords: ChangePassword) {
        return this.http.post(`api/persons/changePassword`, passwords);
    }

    delete(id: number) {
        return this.http.delete(`api/persons/${id}`);
    }
}
