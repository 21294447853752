<div class="title">
	Записанные разговоры
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<app-mks-paging-short *table-header-marker class="left-margin" [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                      (countChange)="countChange($event)"></app-mks-paging-short>
		<div *filter-marker class="inputs-row" style="width: auto">
			<div class="input-block id">
				<text-input id="input_pbx_id" name="input_pbx_id" [(ngModel)]="listSearchData.searchPattern.pbxId" placeholder="АТС ID"></text-input>
			</div>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата начала'" [(ngModel)]="listSearchData.searchPattern.startDateTime"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата окончания'" [(ngModel)]="listSearchData.searchPattern.finishDateTime"></app-datetime-picker>
			</div>
			<div class="input-block">
				<text-input id="input_src" name="input_src" [(ngModel)]="listSearchData.searchPattern.sourceMask" placeholder="Номер звонящего"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_dest" name="input_src" [(ngModel)]="listSearchData.searchPattern.destinationMask" placeholder="Номер вызываемого"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td>
					<span>ID</span>
					<app-sort [component]="this" [property]="'_id'"></app-sort>
				</td>
				<td>
					<span>АТС</span>
					<app-sort [component]="this" [property]="'pbxId'"></app-sort>
				</td>
				<td>
					<span>Название</span>
					<app-sort [component]="this" [property]="'name'"></app-sort>
				</td>
				<td>
					<span>Файл</span>
				</td>
				<td></td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let record of page.items">
				<td>{{record.fileId}}</td>
				<td>{{record.pbxId}}</td>
				<td>{{record.name}}</td>
				<td>{{record.fileName}}</td>
				<td>
					<app-actions *ngIf="record.fileId !== null" (play)="play(record)" (download)="download(record)"></app-actions>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<app-mks-paging-short *table-header-marker class="left-margin" [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                      (countChange)="countChange($event)"></app-mks-paging-short>
		<div *filter-marker class="inputs-row" style="width: auto">
			<div class="input-block id">
				<text-input id="input_pbx_id_mob" name="input_pbx_id" [(ngModel)]="listSearchData.searchPattern.pbxId" placeholder="АТС ID"></text-input>
			</div>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата начала'" [(ngModel)]="listSearchData.searchPattern.startDateTime"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата окончания'" [(ngModel)]="listSearchData.searchPattern.finishDateTime"></app-datetime-picker>
			</div>
			<div class="input-block">
				<text-input id="input_src_mob" name="input_src" [(ngModel)]="listSearchData.searchPattern.sourceMask" placeholder="Номер звонящего"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_dest_mob" name="input_src" [(ngModel)]="listSearchData.searchPattern.destinationMask" placeholder="Номер вызываемого"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let record of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">ID</div>
								<div class="data">{{record.fileId}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">АТС</div>
								<div class="data">{{record.pbxId}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Название</div>
								<div class="data">{{record.name}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Файл</div>
								<div class="data">{{record.fileName}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="data">
									<div class="action">
										<app-actions *ngIf="record.fileId !== null" (play)="play(record)" (download)="download(record)"></app-actions>
									</div>
								</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<app-player (download)="download(currentStorageFile)"></app-player>
