import {Component} from '@angular/core';
import {Company} from "../../../model/company";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {CompanyService} from "../../../service/company.service";
import {ModalService} from "../../../service/modal.service";
import {AtsService} from "../../../service/ats.service";
import {RouteStateService} from "../../../service/route-state.service";
import {PersonData} from "../../../model/personData";
import {saveAs} from 'file-saver';
import {ModalState} from "../../../shared/modal/modal.state";
import {AuthenticationService} from "../../../service/authentication.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-company-details', templateUrl: './company-details.component.html', styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent extends BasePbxTabComponent {
    readonly scanDeleteModal = "scan-delete-modal";

    company: Company = new Company();
    personData: PersonData = new PersonData();
    scanNoToDelete: number;
    scanNameToDelete: string;

    constructor(public deviceService: DeviceDetectorService, private companyService: CompanyService, private router: Router, public modalService: ModalService, protected atsService: AtsService,
                protected route: ActivatedRoute, protected routeStateService: RouteStateService, public authenticationService: AuthenticationService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'company';
        this.getCompany();
        this.getPersonData();
    }

    getCompany() {
        this.companyService.getById(this.currentPbx.companyId).subscribe(value => {
            this.company = value;
        })
    }

    getPersonData() {
        this.companyService.getPersonDataByCompanyId(this.currentPbx.companyId).subscribe(value => {
            this.personData = value;
        })
    }

    saveCompany(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.company) {
            this.companyService.save(this.company, this.company.id).subscribe(() => {
                this.tab = 'company';
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                let observable = this.companyService.saveScans(this.company, this.personData);
                if (observable) {
                    observable.subscribe(() => {
                    }, (e) => {
                        this.validationCodes = e.error.codes;
                    }, () => {
                        this.exitEditMode();
                    })
                } else {
                    this.exitEditMode();
                }
            });
        }
    }

    download(scanNo: number, scanName: string) {
        this.companyService.downloadScan(this.currentPbx.companyId, scanNo).toPromise().then(value => {
            saveAs(value, scanName);
        })
    }

    createScanDeleteModal(scanNo: number, scanName: string) {
        this.scanNoToDelete = scanNo;
        this.scanNameToDelete = scanName;
        this.modalService.open(this.scanDeleteModal, ModalState.DELETE);
    }

    delete(scanNo: number) {
        this.companyService.deleteScan(this.currentPbx.companyId, scanNo).subscribe(() => {
            this.getPersonData();
            this.closeModal(this.scanDeleteModal);
        })
    }

    handleFileInput(event, whichFile) {
        if(event.target.files) {
            let file = event.target.files.item(0);
            switch (whichFile) {
                case '1':
                    this.personData.scan1 = file;
                    break;
                case '2':
                    this.personData.scan2 = file;
                    break;
                case '3':
                    this.personData.legalScan1 = file;
                    break;
                case '4':
                    this.personData.legalScan2 = file;
                    break;
            }
        }
    }

    private exitEditMode() {
        this.validationCodes = [];
        this.router.navigate(['ats', this.currentPbx.id, 'show', 'company']);
        this.state = 'show';
    }
}
