import {Injectable} from '@angular/core';
import {ListSearchData} from "../model/common/list/listSearchData";
import {Page} from "../model/common/list/page";
import {HttpClient} from "@angular/common/http";
import {AccountSearch} from "../model/common/list/accountSearch";
import {Account} from "../model/account";

@Injectable({
    providedIn: 'root'
})
export class InternalNumbersService {

    constructor(private http: HttpClient) {
    }

    getAll(id: number, searchData: ListSearchData<AccountSearch>) {
        return this.http.post<Page<Account>>(`api/pbx/${id}/account/search`, searchData);
    }

    getById(id: number, pbxId: number) {
        return this.http.get<Account>(`api/pbx/${pbxId}/account/${id}`);
    }

    getForCreate(pbxId: number) {
        return this.http.get<Account>(`api/pbx/${pbxId}/account-create`);
    }

    delete(id: number) {
        return this.http.delete(`api/account/${id}`);
    }

    update(account: Account, id: number, pbxId: number) {
        return this.http.put(`api/pbx/${pbxId}/account/${id}`, account);
    }

    create(account: Account, pbxId: number) {
        return this.http.post(`api/pbx/${pbxId}/account`, account);
    }
}
