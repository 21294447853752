import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {ModalState} from "../../../shared/modal/modal.state";
import {HuntGroupsService} from "../../../service/hunt-groups.service";
import {HuntGroup} from "../../../model/huntGroup";
import {Page} from "../../../model/common/list/page";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {HuntGroupPattern} from "../../../model/huntGroupPattern";
import {HuntGroupStrategy} from "../../../model/huntGroupStrategy";
import {NgForm} from "@angular/forms";
import {HuntGroupMember} from "../../../model/huntGroupMember";
import {forkJoin} from "rxjs";
import {Account} from "../../../model/account";
import {HuntGroupAccounts} from "../../../model/huntGroupAccounts";
import {AccountService} from "../../../service/account.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-hunt-groups', templateUrl: './hunt-groups.component.html', styleUrls: ['./hunt-groups.component.css']
})
export class HuntGroupsComponent extends BasePbxTabComponent {
    readonly huntGroupModal = "hunt-group-modal";
    readonly huntGroupDeleteModal = "hunt-group-delete-modal";
    readonly huntGroupConfigModal = "hunt-group-config-modal";
    readonly strategies = [{key: 'RING_ALL', label: HuntGroupStrategy.RING_ALL}, {
        key: 'ROUND_ROBIN',
        label: HuntGroupStrategy.ROUND_ROBIN
    }, {key: 'RANDOM_UNIFORM_DISTRIBUTION', label: HuntGroupStrategy.RANDOM_UNIFORM_DISTRIBUTION}]

    pageLoaded: Promise<boolean>;

    page: Page<HuntGroup>;
    listSearchData: ListSearchData<HuntGroupPattern>;
    accounts: Account[];
    account: Account;
    huntGroupsAccounts: HuntGroupAccounts;

    currentHuntGroup: HuntGroup;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService, protected huntGroupsService: HuntGroupsService, protected accountService: AccountService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'hunt-groups';
        this.listSearchData = new ListSearchData(new HuntGroupPattern(this.currentPbx.id));
        this.loadPage();
    }

    loadPage() {
        this.huntGroupsService.getListByPbx(this.currentPbx.id, this.listSearchData).subscribe(value => {
            this.page = value;
            this.pageLoaded = Promise.resolve(true);
        })
    }

    pageChanged($event: number) {
        this.listSearchData.page = $event - 1;
        this.loadPage();
    }

    countChange($event: number) {
        this.listSearchData.limit = $event;
        this.loadPage();
    }

    createHuntGroupModal() {
        this.currentHuntGroup = new HuntGroup();
        this.currentHuntGroup.strategy = 'RING_ALL';
        this.currentHuntGroup.fullTimeout = 0;
        this.currentHuntGroup.timeout = 0;
        this.currentHuntGroup.type = 'HUNT_GROUP';
        this.modalService.open(this.huntGroupModal, ModalState.ADD);
    }

    createHuntGroupEditModal(huntGroup: HuntGroup) {
        this.huntGroupsService.getById(huntGroup.id).subscribe(value => {
            this.currentHuntGroup = value;
            this.modalService.open(this.huntGroupModal, ModalState.EDIT);
        });
    }

    createHuntGroupConfigModal(id: number) {
        let loadHuntGroup = this.huntGroupsService.getById(id);
        let loadAccounts = this.accountService.getAccountsByPbx(this.currentPbx.id);

        forkJoin([loadHuntGroup, loadAccounts]).subscribe(value => {
            this.currentHuntGroup = value[0];
            this.accounts = this.filterAccounts(value[1]);
            this.huntGroupsAccounts = new HuntGroupAccounts(id, this.currentHuntGroup.members.map(value1 => value1.id));
            this.modalService.open(this.huntGroupConfigModal, ModalState.EDIT);
        })
    }

    private filterAccounts(accounts: Account[]) {
        return accounts.filter(value1 => {
            let huntGroupMember = this.currentHuntGroup.members.find(value2 => value2.id === value1.id);
            return huntGroupMember === undefined;
        });
    }

    createHuntGroupDeleteModal(huntGroup: HuntGroup) {
        this.currentHuntGroup = huntGroup;
        this.currentHuntGroup.numbers = '';
        if (this.currentHuntGroup.members) {
            this.currentHuntGroup.members.forEach(value => {
                console.log(value);
                this.currentHuntGroup.numbers = this.currentHuntGroup.numbers + ' ' + value.number;
            })
        }
        this.modalService.open(this.huntGroupDeleteModal, ModalState.DELETE);
    }

    delete(id: number) {
        if (this.currentHuntGroup && this.currentHuntGroup.id === id) {
            this.huntGroupsService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.huntGroupDeleteModal);
            })
        }
    }

    create($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentHuntGroup) {
            this.currentHuntGroup.members = [];
            this.huntGroupsService.create(this.currentPbx.id, this.currentHuntGroup).subscribe(id => {
                this.loadPage();
                this.closeModal(this.huntGroupModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    update($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentHuntGroup && this.currentHuntGroup.id) {
            this.currentHuntGroup.members = [];
            this.huntGroupsService.update(this.currentPbx.id, this.currentHuntGroup).subscribe(id => {
                this.loadPage();
                this.closeModal(this.huntGroupModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    moveUp(memberUp: HuntGroupMember) {
        let upPriority = memberUp.priority - 1;
        let lowerPriority = memberUp.priority;
        let memberDown = this.currentHuntGroup.members.find(value => value.priority === upPriority);
        if(memberDown) {
            this.move(memberDown, lowerPriority, memberUp, upPriority);
        }
    }

    private move(memberDown: HuntGroupMember, lowerPriority: number, memberUp: HuntGroupMember, upPriority: number) {
        memberDown.priority = lowerPriority;
        memberUp.priority = upPriority;
        this.currentHuntGroup.members.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
        this.huntGroupsAccounts = new HuntGroupAccounts(this.currentHuntGroup.id, this.currentHuntGroup.members.map(value1 => value1.id));
    }

    moveDown(memberDown: HuntGroupMember) {
        let lowerPriority = memberDown.priority + 1;
        let upPriority = memberDown.priority;
        let memberUp = this.currentHuntGroup.members.find(value => value.priority === lowerPriority);
        if(memberUp) {
            this.move(memberDown, lowerPriority, memberUp, upPriority);
        }
    }

    deleteMember(member: HuntGroupMember) {
        let index = this.currentHuntGroup.members.indexOf(member, 0);
        if(index > -1) {
            this.currentHuntGroup.members.splice(index, 1);
            this.findNextAndUpdatePriority(this.currentHuntGroup.members, member.priority)
            this.huntGroupsAccounts = new HuntGroupAccounts(this.currentHuntGroup.id, this.currentHuntGroup.members.map(value1 => value1.id));
        }
    }

    private findNextAndUpdatePriority(members: HuntGroupMember[], priority: number) {
        let huntGroupMember = members.find(value => value.priority === (priority + 1));
        if(huntGroupMember) {
            huntGroupMember.priority = priority;
            this.findNextAndUpdatePriority(members, (priority + 1))
        }
    }

    addNumber() {
        if(this.account && this.huntGroupsAccounts.accountIds.indexOf(this.account.id, 0) < 0) {
            let member = new HuntGroupMember();
            member.name = this.account.name;
            member.number = this.account.number;
            member.id = this.account.id;
            if(this.currentHuntGroup.members.length > 0) {
                member.priority = Math.max.apply(Math, this.currentHuntGroup.members.map(function(o) { return o.priority; })) + 1;
            } else {
                member.priority = 1;
            }
            this.currentHuntGroup.members.push(member);
            this.huntGroupsAccounts = new HuntGroupAccounts(this.currentHuntGroup.id, this.currentHuntGroup.members.map(value1 => value1.id));
        }
    }

    saveMembers() {
        if(this.huntGroupsAccounts) {
            this.huntGroupsService.saveMembers(this.huntGroupsAccounts.id, this.huntGroupsAccounts.accountIds).subscribe(value => {
                this.closeModal(this.huntGroupConfigModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
            })
        }
    }
}
