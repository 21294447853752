import {Component} from '@angular/core';
import * as moment from 'moment-timezone';
import {Router} from "@angular/router";
import {BaseListPageComponent} from "../../baseListPage.component";
import {PbxSearch} from "../../../model/common/list/pbxSearch";
import {Pbx} from "../../../model/pbx";
import {PbxStatus} from "../../../model/pbxStatus";
import {Server} from "../../../model/server";
import {ModalService} from "../../../service/modal.service";
import {AtsService} from "../../../service/ats.service";
import {ClientAuthService} from "../../../service/client-auth.service";
import {HttpClient} from "@angular/common/http";
import {ServerService} from "../../../service/server.service";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {ModalState} from "../../../shared/modal/modal.state";
import {PbxMasterCreate} from "../../../model/pbxMasterCreate";
import {PbxForm} from "../../../model/pbxForm";
import {NgForm} from "@angular/forms";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-ats', //
    templateUrl: './ats.component.html', //
    styleUrls: ['./ats.component.css']
})
export class AtsComponent extends BaseListPageComponent<Pbx, PbxSearch> {
    isDataAvailable = false;
    currentPbx: Pbx = new Pbx();
    timezones = [];
    status: PbxStatus;
    servers: Server[];
    popupDataReady = false;
    timezonesLoaded = false;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private atsService: AtsService, private clientAuthService: ClientAuthService, private http: HttpClient, private serverService: ServerService, private router: Router) {
        super(deviceService, new ListSearchData(new PbxSearch()));
    }

    ngOnInit() {
        this.loadPage();
        this.loadDefaultCountryCode();
        this.loadTimezones();

    }

    private loadTimezones() {
        let timeZones = moment.tz.names();
        let index = 0;

        for (let i in timeZones) {
            this.timezones[index++] = {
                showVal: "(UTC " + moment.tz(timeZones[i]).format('Z') + ") " + timeZones[i].replace('_', ' '), valueField: timeZones[i]
            }
        }
        this.timezonesLoaded = true;
    }

    private loadDefaultCountryCode() {
        let countryCode = localStorage.getItem("countryCode");
        this.http.get<string>('api/pbx/config/countryCode').subscribe(value => {
            countryCode = value;
            localStorage.setItem("countryCode", countryCode);
        });
    }

    loadPage() {
        this.atsService.getAll(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    loadPageAvailableForBilling() {
        this.listSearchData.searchPattern.availableForBilling = true;
        this.atsService.getAll(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    client(id: number) {
        this.atsService.getCredentialsById(id).subscribe(value => {
            let creds = value.substring(1, value.length - 1).split("','");
            this.clientAuthService.tryAuth(creds[0], creds[1]);
        });
    }

    createDeletePbxModal(pbx: Pbx) {
        this.atsService.getById(pbx.id).subscribe(value => {
            this.currentPbx = value;
            this.modalService.open("pbx-delete-modal", ModalState.DELETE);
        });
    }

    createMasterAddPbxModal() {
        this.currentPbx = new PbxMasterCreate();
        (<PbxMasterCreate>this.currentPbx).countryCode = localStorage.getItem("countryCode");
        (<PbxMasterCreate>this.currentPbx).timeZone = "Europe/Moscow";
        this.modalService.open("pbx-master-modal", ModalState.ADD);
    }

    createAddPbxModal() {
        this.serverService.getList().subscribe(value => {
            this.servers = value;

            this.currentPbx = new PbxForm();
            (<PbxForm>this.currentPbx).countryCode = localStorage.getItem("countryCode");
            (<PbxForm>this.currentPbx).timeZone = "Europe/Moscow";
            (<PbxForm>this.currentPbx).serverId = this.servers[0].id;
            (<PbxForm>this.currentPbx).serverName = this.servers[0].name;
            (<PbxForm>this.currentPbx).serverName = this.servers[0].name;
            (<PbxForm>this.currentPbx).companyType = "INDIVIDUAL";
            (<PbxForm>this.currentPbx).status = 'DISABLED';
            this.popupDataReady = true;
            this.modalService.open("pbx-modal", ModalState.ADD);
        });
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.validationCodes = [];
        this.popupDataReady = false;
    }

    delete(id: number) {
        if (this.currentPbx && this.currentPbx.id === id) {
            this.atsService.delete(this.currentPbx.id).subscribe(() => {
                this.closeModal("pbx-delete-modal");
                this.loadPage();
            }, (e) => this.validationCodes = e.error.codes, () => this.validationCodes = []);
        }
    }

    ofMaster() {
        return (<PbxMasterCreate>this.currentPbx);
    }

    ofForm() {
        return (<PbxForm>this.currentPbx);
    }

    create(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentPbx) {
            this.atsService.create(this.ofForm()).subscribe((value) => {
                this.currentPbx.id = value.id;
                this.router.navigate(['/ats/' + value.id + '/edit']);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
            });
        }
    }

    createMaster(event: Event, form: NgForm) {
        if (form.valid && this.currentPbx) {
            this.atsService.createMaster(this.currentPbx).subscribe(() => {
                this.closeModal("pbx-master-modal");
                this.loadPage();
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            });
        }

    }

    setServer(server: any) {
        if (server) {
            this.ofForm().serverId = server.id;
            this.ofForm().serverName = server.name;
        } else {
            this.ofForm().serverId = null;
            this.ofForm().serverName = null;
        }
    }

    setStatus(status: string) {
        this.currentPbx.status = status;
    }

    selectTimezone($event: any) {
        this.ofMaster().timeZone = $event.valueField
    }

    server() {
        if (this.currentPbx !== undefined && this.ofForm().serverId !== undefined) {
            let server = new Server();
            server.id = this.ofForm().serverId;
            server.name = this.ofForm().serverName;
            return server;
        } else {
            return new Server();
        }
    }

    show(id: number) {
        this.router.navigate(['/ats', id, 'show']);
    }

    edit(id: number) {
        this.router.navigate(['/ats', id, 'edit'])
    }

    clearSearch() {
        this.listSearchData.searchPattern = new PbxSearch();
    }
}

