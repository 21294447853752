<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="30px" width="30px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;"
     xml:space="preserve">
		<g>
			<circle cx="30" cy="29.879" r="2"/>
			<path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M29,7.879c0-0.553,0.448-1,1-1s1,0.447,1,1 v1c0,0.553-0.448,1-1,1s-1-0.447-1-1V7.879z M9,30.879H8c-0.552,0-1-0.447-1-1s0.448-1,1-1h1c0.552,0,1,0.447,1,1 S9.552,30.879,9,30.879z M15.858,45.436l-0.707,0.707c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293 c-0.391-0.391-0.391-1.023,0-1.414l0.707-0.707c0.391-0.391,1.023-0.391,1.414,0S16.249,45.045,15.858,45.436z M15.858,15.736 c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293l-0.707-0.707c-0.391-0.391-0.391-1.023,0-1.414 s1.023-0.391,1.414,0l0.707,0.707C16.249,14.713,16.249,15.346,15.858,15.736z M31,51.879c0,0.553-0.448,1-1,1s-1-0.447-1-1v-1 c0-0.553,0.448-1,1-1s1,0.447,1,1V51.879z M30,33.879c-1.858,0-3.411-1.28-3.858-3H19c-0.552,0-1-0.447-1-1s0.448-1,1-1h7.142 c0.364-1.399,1.459-2.494,2.858-2.858V15.879c0-0.553,0.448-1,1-1s1,0.447,1,1v10.142c1.72,0.447,3,1.999,3,3.858 C34,32.085,32.206,33.879,30,33.879z M46.263,46.143c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293l-0.707-0.707 c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l0.707,0.707C46.654,45.119,46.654,45.752,46.263,46.143z M46.263,15.029 l-0.707,0.707c-0.195,0.195-0.451,0.293-0.707,0.293s-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l0.707-0.707 c0.391-0.391,1.023-0.391,1.414,0S46.654,14.639,46.263,15.029z M52,30.879h-1c-0.552,0-1-0.447-1-1s0.448-1,1-1h1 c0.552,0,1,0.447,1,1S52.552,30.879,52,30.879z"/>
		</g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	</svg>