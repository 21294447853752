<div class="base-tab__block active" data-tabname="tariff">
	<div *ngIf="currentPbx.status === 'DISABLED'" class="title">АТС отключена</div>
	<div *ngIf="currentPbx.status === 'REMOVED'" class="title">АТС удалена</div>
	<div *ngIf="!tariff" class="title">Невозможно получить данные по тарифу и услугам</div>
	<form class="form-to-show" *ngIf="currentPbx.status === 'ACTIVE' && tariff">
		<div class="col">
			<div class="input-block">
				<label>Актуальный баланс</label>
				<div class="value">{{balance}}</div>
			</div>
			<div class="input-block" *ngIf="tariff.atsTariffId">
				<label>ID тарифа</label>
				<div class="value">{{tariff.atsTariffId}}</div>
			</div>
			<div class="input-block">
				<label>Название тарифа</label>
				<div class="value" *ngIf="tariff.atsTariffId">{{tariff.tariffName}}</div>
				<div class="infobox" *ngIf="!tariff.atsTariffId">В системе нет стандартного тарифа соответствующего id тарифа в биллинге. Возможно этот тариф является персонализированым.</div>
			</div>
			<div class="input-block">
				<label>ID тарифа в биллинге</label>
				<div class="value">{{tariff.billingTariffId}}</div>
			</div>
			<div class="input-block">
				<label>Дата начала</label>
				<div class="value">{{tariff.startDate}}</div>
			</div>
			<div class="input-block">
				<label>Дата окончания</label>
				<div class="value">{{tariff.finishDate}}</div>
			</div>
			<div class="input-block">
				<label>ID следующего тарифа</label>
				<div class="value">{{tariff.nextTariffId}}</div>
			</div>
			<div class="input-block">
				<label>Дата начала следующего тарифа</label>
				<div class="value">{{tariff.nextTariffStartDate}}</div>
			</div>
		</div>
		<div class="col">
			<div class="input-block">
				<label>Статус услаги "Запись разговоров"</label>
				<div class="value ats-status" [ngClass]="tariff.recordConversationStatus">
					<span class="circle"></span><span>{{tariff.recordConversationStatus | tariffServiceStatusType}}</span>
				</div>
			</div>
			<div class="input-block">
				<label>Количество дней хранения записей</label>
				<div class="value">{{tariff.recordShelfLife}}</div>
			</div>
			<div class="input-block">
				<label>Статус услуги "Списки номеров"</label>
				<div class="value ats-status" [ngClass]="tariff.listNumbersStatus">
					<span class="circle"></span><span>{{tariff.listNumbersStatus | tariffServiceStatusType}}</span>
				</div>
			</div>
			<div class="input-block">
				<label>Статус услуги "Автообзвон"</label>
				<div class="value ats-status" [ngClass]="tariff.campaignStatus">
					<span class="circle"></span><span>{{tariff.campaignStatus | tariffServiceStatusType}}</span>
				</div>
			</div>
			<div class="input-block">
				<label>Статус услуги "Колл-Трекинг"</label>
				<div class="value ats-status" [ngClass]="tariff.callTrackingStatus">
					<span class="circle"></span><span>{{tariff.callTrackingStatus | tariffServiceStatusType}}</span>
				</div>
			</div>
			<div class="input-block">
				<label>Статус услуги "Интеграции с CRM"</label>
				<div class="value ats-status" [ngClass]="tariff.crmStatus">
					<span class="circle"></span><span>{{tariff.crmStatus | tariffServiceStatusType}}</span>
				</div>
			</div>
			<div class="input-block">
				<label>Статус услуги "Виджет "Перезвони мне""</label>
				<div class="value ats-status" [ngClass]="tariff.callbackStatus">
					<span class="circle"></span><span>{{tariff.callbackStatus | tariffServiceStatusType}}</span>
				</div>
			</div>
		</div>
	</form>
</div>
