<main class="1ats__page">
	<div class="main-wrap">
		<app-sidebar></app-sidebar>
		<div class="main-container fixed-pagination">
			<div class="scrollbar-inner">
<!--				<app-header></app-header>-->
				<section class="main-container__body">
					<router-outlet></router-outlet>
				</section>
			</div>
		</div>
	</div>
</main>
