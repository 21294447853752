import {Order} from "../model/common/list/order";
import {DeviceDetectionComponent} from "./deviceDetection.component";
import {DeviceDetectorService} from "ngx-device-detector";

export abstract class BaseListOrderComponent extends DeviceDetectionComponent {
    order: Order[];

    protected constructor(public deviceService: DeviceDetectorService) {
        super(deviceService)
        this.order = [new Order("id", true)];
    }

    abstract loadPage();

    orderBy(property: string) {
        let prevOrder = this.order.find(value => value.propertyName == property);
        if (prevOrder != undefined) {
            if (prevOrder.asc) {
                prevOrder.asc = !prevOrder.asc;
            } else {
                const index = this.order.indexOf(prevOrder, 0);
                this.order.splice(index, 1);
            }
        } else {
            this.order.push(new Order(property, true));
        }
        this.loadPage();
    }

    findOrderProperty(property: string) {
        return this.order.find(value => value.propertyName == property);
    }
}
