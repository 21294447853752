<div class="row">
	<button class="btn maximize-row-btn" (click)="isSliderOpen = !isSliderOpen">
		<svg>
			<use xlink:href="#down-arrow"></use>
		</svg>
	</button>
	<ng-container *ngFor="let tmpl of mobileSliderTemplates" [ngTemplateOutlet]="tmpl"></ng-container>
</div>

<div class="collapse-block" *ngIf="isSliderOpen">
	<ng-container *ngFor="let tmpl of mobileSliderLastTemplates" [ngTemplateOutlet]="tmpl"></ng-container>
</div>