import {OnInit} from '@angular/core';
import {Page} from "../model/common/list/page";
import {ListSearchData} from "../model/common/list/listSearchData";
import {BaseListOrderComponent} from "./baseListOrder.component";
import {DeviceDetectorService} from "ngx-device-detector";

export abstract class BaseListPageComponent<T, P> extends BaseListOrderComponent implements OnInit {
    page: Page<T>;
    listSearchData: ListSearchData<P>;

    validationCodes: number[];

    protected constructor(public deviceService: DeviceDetectorService, listSearchData: ListSearchData<P>) {
        super(deviceService);
        this.listSearchData = listSearchData;
        this.listSearchData.orders = this.order;
    }

    ngOnInit() {
        this.loadPage();
    }

    abstract loadPage();

    pageChanged($event: number) {
        this.listSearchData.page = $event - 1;
        this.loadPage();
    }

    countChange($event: number) {
        this.listSearchData.limit = $event;
        this.loadPage();
    }
}
