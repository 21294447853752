import {AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Directive, ElementRef, Input, Type, ViewChild, ViewContainerRef} from '@angular/core';
import {RedirectNodeComponent} from "./node/redirect-node.component";
import {VoiceMenuNodeComponent} from "./node/voice-menu-node.component";
import {HangupNodeComponent} from "./node/hangup-node.component";
import {VoiceMailNodeComponent} from "./node/voice-mail-node.component";
import {PlaybackNodeComponent} from "./node/playback-node.component";
import {InputNumberNodeComponent} from "./node/input-number-node.component";
import {GotoNodeComponent} from "./node/goto-node.component";
import {FaxNodeComponent} from "./node/fax-node.component";
import {QueueNodeComponent} from "./node/queue-node.component";
import {CallNodeComponent} from "./node/call-node.component";
import {DateNodeComponent} from "./node/date-node.component";
import {NumberNodeComponent} from "./node/number-node.component";
import {AnswerNodeComponent} from "./node/answer-node.component";
import {TagNodeComponent} from "./node/tag-node.component";

declare var $:JQueryStatic;

@Component({
    selector: '[schema-node]',
    template: '<ng-container #dynamicNode></ng-container>'
})
export class SchemaNodeComponent implements AfterViewInit {
    @Input('node') node;
    @Input('rootNode') rootNode: boolean;
    @Input('schema') schema: string

    @ViewChild('dynamicNode', {read: ViewContainerRef}) dynamicNode: ViewContainerRef;

    nodeBuildIndex: number = 0;

    constructor(private element: ElementRef, private componentResolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) {
    }

    ngAfterViewInit() {
        if (this.rootNode && this.schema != null) {
            this.nodeBuildIndex = 0;
            this.node = JSON.parse(this.schema).root;
        }
        if (this.node != null) {
            this.drawNode();
        }

        if (this.node != null) {
            this.nodeBuildIndex--;
            if (this.nodeBuildIndex === 0) {
                this.drawLine();
            }
        }
    }

    drawLine() {
        $('.scheme-item__tel__scname .yes-no').each(function(index, elm) {
            let elmBegin = $(elm).children('.yes:not(.ng-hide)').first().children('.icon').first();
            let position = elmBegin.position();
            if (position == undefined) {
                elmBegin = $(elm).children('.tech-menu').first().children('.yes').first().children('.icon').first();
                position = elmBegin.position();
            }
            const elmEnd = $(elm).children('.tech-end').first().children('.icon').first();

            let left;
            let width;
            if (position == undefined) {
                width = 0;
                left = 0;
            } else {
                left = position.left + 22;
                width = elmEnd.position().left - left + 7;
            }

            $(elm).find('>.tech-line').css({
                'left': left + 'px',
                'width': width + 'px'
            });
        });
    }

    drawNode() {
        if (!this.rootNode) {
            this.nodeBuildIndex++;
        }
        if (this.node != null) {
            let classByNodeType = this.getClassByNodeType(this.node._type_);
            if (classByNodeType != null) {
                let component = this.dynamicNode.createComponent(this.componentResolver.resolveComponentFactory(classByNodeType)).instance;
                component.node = this.node;
                this.cdr.detectChanges();
            }
        }
    }

    getClassByNodeType(type: string) {
        let result: Type<any>;
        switch (type) {
            case "redirect":
                result = RedirectNodeComponent;
                break;
            case "playback":
                result = PlaybackNodeComponent;
                break;
            case "inputNumber":
                result = InputNumberNodeComponent;
                break;
            case "voiceMenu":
                result = VoiceMenuNodeComponent;
                break;
            case "goto":
                result = GotoNodeComponent;
                break;
            case "voiceMail":
                result = VoiceMailNodeComponent;
                break;
            case "fax":
                result = FaxNodeComponent;
                break;
            case "queue":
                result = QueueNodeComponent;
                break;
            case "hangup":
                result = HangupNodeComponent;
                break;
            case "call":
                result = CallNodeComponent;
                break;
            case "date":
                result = DateNodeComponent
                break;
            case "number":
                result = NumberNodeComponent
                break;
            case "answer":
                result = AnswerNodeComponent;
                break;
            case "tag":
                result = TagNodeComponent;
                break;
            default:
                break;
        }
        return result;
    }
}
