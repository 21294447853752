import {Component} from '@angular/core';
import {ListSearchData} from "../../model/common/list/listSearchData";
import {BaseListPageComponent} from "../baseListPage.component";
import {ModalService} from "../../service/modal.service";
import {Order} from "../../model/common/list/order";
import {CallRecord} from "../../model/common/callRecord/callRecord";
import {CallRecordSearch} from "../../model/common/list/callRecordSearch";
import {CallRecordService} from "../../service/call-record.service";
import {ModalState} from "../../shared/modal/modal.state";
import {AuthenticationService} from "../../service/authentication.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-callRecord', //
    templateUrl: './callRecord.component.html', //
    styleUrls: ['./callRecord.component.css']
})
export class CallRecordComponent extends BaseListPageComponent<CallRecord, CallRecordSearch> {
    currentCallRecord: CallRecord = new CallRecord();
    isDataAvailable = false;

    dataTabs: string[] = ["CALL", "CHANNEL", "COMPANY", "CALL_TRACKING"]
    currentDataTab: string = this.dataTabs[0];

    validationCodes: number[];

    constructor(public deviceService: DeviceDetectorService, public authenticationService: AuthenticationService, public modalService: ModalService, private callRecordService: CallRecordService) {
        super(deviceService, new ListSearchData(new CallRecordSearch()));
        this.order.length = 0;
        this.order.push(new Order("callDate", false));
    }

    loadPage() {
        this.callRecordService.getAll(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    createShowCallRecordModal(id: string) {
        this.callRecordService.getById(id).subscribe((value) => {
            this.currentCallRecord = value;
            this.modalService.open("callRecord-modal", ModalState.SHOW)
        });
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.validationCodes = [];
    }

    getAudioPath(id: bigint) {
        return window.location.pathname.substring(0, window.location.pathname.indexOf("/", 2)) + '/api/call-record/download/' + id;
    }

    clearSearch() {
        this.listSearchData.searchPattern = new CallRecordSearch();
    }
}
