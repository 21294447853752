import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Company} from "../model/company";
import {PersonData} from "../model/personData";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private http: HttpClient) {
    }

    getById(id: number) {
        return this.http.get<Company>(`api/companies/${id}`)
    }

    save(company: Company, id: number) {
        return this.http.put(`api/companies/${id}`, company);
    }

    saveScans(company: Company, personData: PersonData) {
        if (company.type == 'INDIVIDUAL') {
            let formData = this.prepareScanUploadRequest(personData, true);
            formData.append('fullName', personData.fullName);
            formData.append('passportNumber', personData.passportNumber);
            return this.http.post(`api/companies/personData/${company.id}/person`, formData);
        } else if (company.type == 'LEGAL_PERSON' && (personData.legalScan1 || personData.legalScan2)) {
            return this.http.post(`api/companies/personData/${company.id}/legal`, this.prepareScanUploadRequest(personData, false));
        }
    }

    getPersonDataByCompanyId(companyId: number) {
        return this.http.get<PersonData>(`api/companies/personData/${companyId}`)
    }

    deleteScan(companyId: number, scanNo: number) {
        return this.http.delete(`api/companies/personData/deleteScan/${companyId}/${scanNo}`)
    }

    downloadScan(companyId: number, scanNo: number) {
        return this.http.get(`api/companies/personData/download/${companyId}/${scanNo}`, { responseType:'blob' })
    }

    private prepareScanUploadRequest(personData: PersonData, isPerson: boolean): FormData {
        let whichFiles: string = '';
        let files: Array<File> = [];
        if (isPerson) {
            if (personData.scan1) {
                files.push(personData.scan1)
                whichFiles += '1';
            }
            if (personData.scan2) {
                files.push(personData.scan2)
                whichFiles += '2';
            }
        } else {
            if (personData.legalScan1) {
                files.push(personData.legalScan1)
                whichFiles += '1';
            }
            if (personData.legalScan2) {
                files.push(personData.legalScan2)
                whichFiles += '2';
            }
        }

        const formData: FormData = new FormData();
        formData.append('whichFiles', whichFiles);
        for (let i = 0; i < files.length; i++) {
            formData.append(`file`, files[i]);
        }
        return formData;
    }
}
