<div class="base-tab__block active" *ngIf="isDataAvailable && pageLoaded">
	<div class="table-container --desktop">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить пользователя
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1" name="input_1" [(ngModel)]="listSearchData.searchPattern.surName" (keyup.enter)="loadPage()"
				            placeholder="Поиск по фамилии"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table" *ngIf="page">
				<thead>
				<tr>
					<td>Имя</td>
					<td>Логин</td>
					<td>Роль</td>
					<td>E-mail</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let person of page.items">
					<td>{{person.name}}</td>
					<td>{{person.login}}</td>
					<td>{{person.role | role}}</td>
					<td>{{person.email}}</td>
					<td>
						<app-actions (edit)="createEditModal(person)" (delete)="createDeleteModal(person)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить пользователя
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1_mob" name="input_1" [(ngModel)]="listSearchData.searchPattern.surName" (keyup.enter)="loadPage()"
				            placeholder="Поиск по фамилии"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body">
			<table class="base-table" *ngIf="page">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let person of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Имя</div>
									<div class="data">{{person.name}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Логин</div>
									<div class="data">{{person.login}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Роль</div>
									<div class="data">{{person.role | role}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">E-mail</div>
									<div class="data">{{person.email}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (edit)="createEditModal(person)" (delete)="createDeleteModal(person)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="personModal">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formData="ngForm">
			<div class="col" *ngIf="currentPerson">
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && name.invalid}">
					<label for="input_name">Имя</label>
					<text-input id="input_name" name="input_name" required [(ngModel)]="currentPerson.firstName" #name="ngModel"></text-input>
					<div *ngIf="formData.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Введите имя пользователя</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && surname.invalid}">
					<label for="input_surname">Фамилия</label>
					<text-input id="input_surname" name="input_surname" required [(ngModel)]="currentPerson.surName" #surname="ngModel"></text-input>
					<div *ngIf="formData.submitted && surname.invalid" class="invalid">
						<div *ngIf="surname.errors.required">Введите фамилию пользователя</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && email.invalid}">
					<label for="input_email">E-mail</label>
					<text-input id="input_email" name="input_email" required [(ngModel)]="currentPerson.email" #email="ngModel"></text-input>
					<div *ngIf="formData.submitted && email.invalid" class="invalid">
						<div *ngIf="email.errors.required">Введите e-mail</div>
					</div>
				</div>
			</div>
			<div class="col" *ngIf="currentPerson">
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && login.invalid}">
					<label for="input_login">Логин</label>
					<text-input id="input_login" name="input_login" required [(ngModel)]="currentPerson.login" #login="ngModel"></text-input>
					<div *ngIf="formData.submitted && login.invalid" class="invalid">
						<div *ngIf="login.errors.required">Введите логин пользователя</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && password.invalid}">
					<label for="input_password">Пароль</label>
					<text-input id="input_password" name="input_password" required [(ngModel)]="currentPerson.password" #password="ngModel"></text-input>
					<div *ngIf="formData.submitted && password.invalid" class="invalid">
						<div *ngIf="password.errors.required">Введите пароль пользователя</div>
					</div>
				</div>
				<div class="select-block">
					<app-select [selectName]="'Роль'"
					            [id]="'input_role'"
					            [labelKey]="'label'"
					            [value]="currentPerson.role | role"
					            [autocomplete]="false"
					            [readOnly]="false"
					            [options]="roles"
					            (selectChange)="selectRole($event)"></app-select>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(personModal); formData.onReset()">Отмена</button>
		<button *ngIf="modalService.getState(personModal) === 'ADD'" class="btn base-btn" (click)="save($event, formData)">Создать</button>
		<button *ngIf="modalService.getState(personModal) === 'EDIT'" class="btn base-btn" (click)="save($event, formData)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="personDeleteModal" [title]="'Удалить пользователя'">
	<div body *ngIf="currentPerson">
		<div>
			Удалить пользователя: <span class="b">{{currentPerson.name}} ({{currentPerson.login}})</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(personDeleteModal); formData.onReset()">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentPerson.id)">Удалить</button>
	</div>
</app-modal>
