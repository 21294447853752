<div class="title">
	Информация о звонках
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<app-mks-paging-short *table-header-marker class="left-margin" [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                      (countChange)="countChange($event)"></app-mks-paging-short>
		<div *filter-marker class="inputs-row">
			<div class="input-block">
				<text-input id="input_ext_num_mask" name="input_ext_num_mask" [(ngModel)]="listSearchData.searchPattern.externalNumberMask"
				            placeholder="Маска номера"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_contract_number" name="input_contract_number" [(ngModel)]="listSearchData.searchPattern.contractNumber"
				            placeholder="Номер контракта"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt>
					<input type="text" name="scope" placeholder="Тип звонка" value="{{listSearchData.searchPattern.direction | callType}}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.direction = undefined">Все</a>
							<a data-value="INBOUND" (click)="listSearchData.searchPattern.direction = 'INBOUND'">{{ 'INBOUND' | callType }}</a>
							<a data-value="OUTBOUND" (click)="listSearchData.searchPattern.direction = 'OUTBOUND'">{{ 'OUTBOUND' | callType }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<dl class="select select-filter scrollable">
				<dt>
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.disposition | callStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.disposition = undefined">Все</a>
							<a data-value="RING" (click)="listSearchData.searchPattern.disposition = 'RING'">{{ 'RING' | callStatus }}</a>
							<a data-value="ANSWERED" (click)="listSearchData.searchPattern.disposition = 'ANSWERED'">{{ 'ANSWERED' | callStatus }}</a>
							<a data-value="NO_ANSWERED" (click)="listSearchData.searchPattern.disposition = 'NO_ANSWERED'">{{ 'NO_ANSWERED' | callStatus }}</a>
							<a data-value="FAILED" (click)="listSearchData.searchPattern.disposition = 'FAILED'">{{ 'FAILED' | callStatus }}</a>
							<a data-value="BUSY" (click)="listSearchData.searchPattern.disposition = 'BUSY'">{{ 'BUSY' | callStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата начала'" [(ngModel)]="listSearchData.searchPattern.startDateTime"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата окончания'" [(ngModel)]="listSearchData.searchPattern.finishDateTime"></app-datetime-picker>
			</div>
			<div class="input-block">
				<text-input id="input_account_login_search" name="input_account_login_search" [(ngModel)]="listSearchData.searchPattern.accountLogin"
				            placeholder="Логин"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_pbx_id_search" name="input_pbx_id_search" [(ngModel)]="listSearchData.searchPattern.pbxId" placeholder="ID АТС"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_campaign_name_search" name="input_campaign_name_search" [(ngModel)]="listSearchData.searchPattern.campaignName"
				            placeholder="Название кампании"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_call_tracking_name_search" name="input_call_tracking_name_search" [(ngModel)]="listSearchData.searchPattern.callTrackingName"
				            placeholder="Название Колл-Трекинга"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td>
					<span>Дата звонка</span>
					<app-sort [component]="this" [property]="'callDate'"></app-sort>
				</td>
				<td>
					<span>Звонок с</span>
				</td>
				<td>
					<span>Звонок на</span>
				</td>
				<td>
					<span>Переадресация</span>
				</td>
				<td>
					<span>Длительность<br>звонка/разговора</span>
				</td>
				<td>
					<span>Тип звонка</span>
					<app-sort [component]="this" [property]="'direction'"></app-sort>
				</td>
				<td>
					<span>Статус</span>
					<app-sort [component]="this" [property]="'disposition'"></app-sort>
				</td>
				<td *ngIf="authenticationService.isAdministrator()">
					<span>Запись разговора</span>
				</td>
				<td></td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let callRecord of page.items">
				<td class="datetime">{{callRecord.callDate | date:"dd.MM.yyyy HH.mm.ss"}}</td>
				<td class="phone">{{callRecord.source}}</td>
				<td class="phone">{{callRecord.destination}}</td>
				<td class="phone">{{callRecord.redirect}}</td>
				<td>{{callRecord.duration}}/{{callRecord.talkTime}}</td>
				<td>{{callRecord.direction | callType}}</td>
				<td class="status">
					<div class="callRecord-status" [ngClass]="callRecord.disposition">
						<span class="circle"></span><span>{{callRecord.disposition | callStatus}}</span></div>
				</td>
				<td *ngIf="authenticationService.isAdministrator()" class="player">
					<audio *ngIf="callRecord.storageFileId" src="{{getAudioPath(callRecord.storageFileId)}}" controls preload="metadata"></audio>
				</td>
				<td class="actions-small">
					<app-actions (show)="createShowCallRecordModal(callRecord._id)"></app-actions>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<app-mks-paging-short *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                      (countChange)="countChange($event)"></app-mks-paging-short>
		<div *filter-marker class="inputs-row">
			<div class="input-block">
				<text-input id="input_ext_num_mask_mob" name="input_ext_num_mask" [(ngModel)]="listSearchData.searchPattern.externalNumberMask"
				            placeholder="Маска номера"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_contract_number_mob" name="input_contract_number" [(ngModel)]="listSearchData.searchPattern.contractNumber"
				            placeholder="Номер контракта"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt>
					<input type="text" name="scope" placeholder="Типо звонка" value="{{listSearchData.searchPattern.direction | callType}}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.direction = undefined">Все</a>
							<a data-value="INBOUND" (click)="listSearchData.searchPattern.direction = 'INBOUND'">{{ 'INBOUND' | callType }}</a>
							<a data-value="OUTBOUND" (click)="listSearchData.searchPattern.direction = 'OUTBOUND'">{{ 'OUTBOUND' | callType }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<dl class="select select-filter scrollable">
				<dt>
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.disposition | callStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.disposition = undefined">Все</a>
							<a data-value="RING" (click)="listSearchData.searchPattern.disposition = 'RING'">{{ 'RING' | callStatus }}</a>
							<a data-value="ANSWERED" (click)="listSearchData.searchPattern.disposition = 'ANSWERED'">{{ 'ANSWERED' | callStatus }}</a>
							<a data-value="NO_ANSWERED" (click)="listSearchData.searchPattern.disposition = 'NO_ANSWERED'">{{ 'NO_ANSWERED' | callStatus }}</a>
							<a data-value="FAILED" (click)="listSearchData.searchPattern.disposition = 'FAILED'">{{ 'FAILED' | callStatus }}</a>
							<a data-value="BUSY" (click)="listSearchData.searchPattern.disposition = 'BUSY'">{{ 'BUSY' | callStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата начала'" [(ngModel)]="listSearchData.searchPattern.startDateTime"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата окончания'" [(ngModel)]="listSearchData.searchPattern.finishDateTime"></app-datetime-picker>
			</div>
			<div class="input-block">
				<text-input id="input_account_login_search_mob" name="input_account_login_search" [(ngModel)]="listSearchData.searchPattern.accountLogin"
				            placeholder="Логин"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_pbx_id_search_mob" name="input_pbx_id_search" [(ngModel)]="listSearchData.searchPattern.pbxId" placeholder="ID АТС"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_campaign_name_search_mob" name="input_campaign_name_search" [(ngModel)]="listSearchData.searchPattern.campaignName"
				            placeholder="Название кампании"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_call_tracking_name_search_mob" name="input_call_tracking_name_search" [(ngModel)]="listSearchData.searchPattern.callTrackingName"
				            placeholder="Название Колл-Трекинга"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let callRecord of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Дата звонка</div>
								<div class="data">{{callRecord.callDate | date:"dd.MM.yyyy HH.mm.ss"}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Звонок с</div>
								<div class="data">{{callRecord.source}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Звонок на</div>
								<div class="data">{{callRecord.destination}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Переадресация</div>
								<div class="data">{{callRecord.redirect}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Длительность звонка/разговора</div>
								<div class="data">{{callRecord.duration}}/{{callRecord.talkTime}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Тип звонка</div>
								<div class="data">{{callRecord.direction | callType}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Статус</div>
								<div class="data">
									<div class="callRecord-status" [ngClass]="callRecord.disposition">
										<span class="circle"></span><span>{{callRecord.disposition | callStatus}}</span></div>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<audio *ngIf="authenticationService.isAdministrator() && callRecord.storageFileId" src="{{getAudioPath(callRecord.storageFileId)}}"
								       controls preload="metadata"></audio>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="data">
									<div class="action">
										<app-actions (show)="createShowCallRecordModal(callRecord._id)"></app-actions>
									</div>
								</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<app-modal [id]="'callRecord-modal'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<div class="base-tab">
			<div class="base-tab__header">
				<div class="scrollbar-inner">
					<button class="btn base-tab__btn" [ngClass]="{'active': currentDataTab === dataTabs[0]}" (click)="currentDataTab = dataTabs[0]">Звонок</button>
					<button class="btn base-tab__btn" [ngClass]="{'active': currentDataTab === dataTabs[1]}" *ngIf="currentCallRecord.channelData" (click)="currentDataTab = dataTabs[1]">Channel Data</button>
					<button class="btn base-tab__btn" [ngClass]="{'active': currentDataTab === dataTabs[2]}" *ngIf="currentCallRecord.campaignNumberData" (click)="currentDataTab = dataTabs[2]">Кампания</button>
					<button class="btn base-tab__btn" [ngClass]="{'active': currentDataTab === dataTabs[3]}" *ngIf="currentCallRecord.callTrackingData" (click)="currentDataTab = dataTabs[3]">Колл-Трекинг</button>
				</div>
			</div>
			<div class="base-tab__body">
				<div class="base-tab__block active" *ngIf="currentDataTab === dataTabs[0]">
					<form class="form-to-show">
						<div class="col">
							<div class="input-block">
								<label for="input_id">ID</label>
								<text-input id="input_id" name="input_id" [readOnly]="true"
								            [(ngModel)]="currentCallRecord._id"></text-input>
							</div>
							<div class="input-block">
								<label for="input_call_date">Время звонка</label>
								<app-datetime-picker id="input_call_date" name="input_call_date" [(ngModel)]="currentCallRecord.callDate"
								                     [readOnly]="true"></app-datetime-picker>
							</div>
							<div class="input-block">
								<label for="input_source">Звонок с</label>
								<text-input id="input_source" name="input_source" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.source"></text-input>
							</div>
							<div class="input-block">
								<label for="input_destination">Звонок на</label>
								<text-input id="input_destination" name="input_destination" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.destination"></text-input>
							</div>
							<div class="input-block">
								<label for="input_redirect">Переадресация</label>
								<text-input id="input_redirect" name="input_redirect" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.redirect"></text-input>
							</div>
							<div class="input-block">
								<label for="input_answered">Внутренний номер</label>
								<text-input id="input_answered" name="input_answered" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.answered"></text-input>
							</div>
							<div class="input-block">
								<label for="input_account_login">Логин пользователя</label>
								<text-input id="input_account_login" name="input_account_login" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.accountLogin"></text-input>
							</div>
						</div>
						<div class="col">
							<div class="input-block">
								<label for="input_duration">Длительность разговора</label>
								<text-input id="input_duration" name="input_duration" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.duration"></text-input>
							</div>
							<div class="input-block">
								<label for="input_talk_time">Время разговора</label>
								<text-input id="input_talk_time" name="input_talk_time" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.talkTime"></text-input>
							</div>
							<div class="input-block">
								<label for="input_direction">Тип звонка</label>
								<text-input id="input_direction" name="input_direction" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.direction"></text-input>
							</div>
							<div class="input-block">
								<label for="input_disposition">Статус</label>
								<text-input id="input_disposition" name="input_disposition" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.disposition"></text-input>
							</div>
							<div class="input-block">
								<label for="input_pbx_id">ID АТС</label>
								<text-input id="input_pbx_id" name="input_pbx_id" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.pbxId"></text-input>
							</div>
							<div class="input-block">
								<label for="input_call_schema_id">ID Схемы звонка</label>
								<text-input id="input_call_schema_id" name="input_call_schema_id" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.callSchemaId"></text-input>
							</div>
						</div>
					</form>
				</div>
				<div class="base-tab__block" *ngIf="currentDataTab === dataTabs[1]">
					<form class="form-to-show">
						<div class="col">
							<div class="input-block">
								<label for="input_channel_id">Channel ID</label>
								<text-input id="input_channel_id" name="input_channel_id" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.channelData.channelId"></text-input>
							</div>
							<div class="input-block">
								<label for="input_caller_id">Caller ID</label>
								<text-input id="input_caller_id" name="input_caller_id" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.channelData.callerId"></text-input>
							</div>
							<div class="input-block">
								<label for="input_context">Context</label>
								<text-input id="input_context" name="input_context" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.channelData.context"></text-input>
							</div>
						</div>
						<div class="col">
							<div class="input-block">
								<label for="input_state">State</label>
								<text-input id="input_state" name="input_state" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.channelData.state"></text-input>
							</div>
							<div class="input-block">
								<label for="input_commands">Commands</label>
								<text-input id="input_commands" name="input_commands" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.channelData.commands"></text-input>
							</div>
							<div class="input-block">
								<label for="input_user_field">User Field</label>
								<text-input id="input_user_field" name="input_user_field" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.channelData.userField"></text-input>
							</div>
						</div>
					</form>
				</div>
				<div class="base-tab__block" *ngIf="currentDataTab === dataTabs[2]">
					<form class="form-to-show">
						<div class="col">
							<div class="input-block">
								<label for="input_campaign_id">ID Кампании</label>
								<text-input id="input_campaign_id" name="input_campaign_id" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.campaignNumberData.campaignId"></text-input>
							</div>
							<div class="input-block">
								<label for="input_campaign_name">Имя кампании</label>
								<text-input id="input_campaign_name" name="input_campaign_name" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.campaignNumberData.campaignName"></text-input>
							</div>
							<div class="input-block">
								<label for="input_number">Номер</label>
								<text-input id="input_number" name="input_number" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.campaignNumberData.number"></text-input>
							</div>
						</div>
					</form>
				</div>
				<div class="base-tab__block" *ngIf="currentDataTab === dataTabs[3]">
					<form class="form-to-show">
						<div class="col">
							<div class="input-block">
								<label for="input_call_tracking_id">ID Колл-Трекинга</label>
								<text-input id="input_call_tracking_id" name="input_call_tracking_id" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.callTrackingData.callTrackingId"></text-input>
							</div>
							<div class="input-block">
								<label for="input_call_tracking_name">Имя Колл-Трекинга</label>
								<text-input id="input_call_tracking_name" name="input_call_tracking_name" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.callTrackingData.callTrackingName"></text-input>
							</div>
							<div class="input-block">
								<label for="input_source_type">Тип источника</label>
								<text-input id="input_source_type" name="input_source_type" [readOnly]="true"
								            [(ngModel)]="currentCallRecord.callTrackingData.callTrackingSourceType"></text-input>
							</div>
						</div>
						<div class="col"
						     *ngIf="currentCallRecord.callTrackingData.callTrackingConfigData && currentCallRecord.callTrackingData.callTrackingConfigData.length > 0">
							<div class="group-block">
								<div class="title">Метки</div>
								<div class="inputs">
									<div class="input-block" *ngFor="let item of currentCallRecord.callTrackingData.callTrackingConfigData; let i = index">
										<label for="{{'input_source_label_' + i}}">{{item.type}}</label>
										<text-input id="{{'input_source_label_' + i}}" name="{{'input_source_label_' + i}}" [readOnly]="true"
										            [(ngModel)]="item.value"></text-input>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('callRecord-modal')">Отмена</button>
	</div>
</app-modal>
