import {HuntGroupMember} from "./huntGroupMember";

export class HuntGroup {
    id: number;
    number: string;
    name: string;
    type: string;
    strategy: string;
    timeout: number;
    fullTimeout: number;
    retries: number;
    members: HuntGroupMember[];
    callWidgetId: number;

    numbers: string = '';
}
