<div class="base-tab__block active" data-tabname="numbers_out">
	<div class="title">
		Схема телефонии
		<button class="btn base-btn grey popup-btn__close" [routerLink]="[ '/ats', currentPbx.id, 'show', 'call-schemas']">Отмена</button>
		<button *ngIf="state === 'show'" class="btn base-btn" [routerLink]="[ '/ats',currentPbx.id,'edit','call-schemas', 'call-schema', this.currentCallSchema.id]">Редактирование</button>
		<button *ngIf="state === 'add'" class="btn base-btn" (click)="createCallSchema($event, formDataCS)">Создать</button>
		<button *ngIf="state === 'edit'" class="btn base-btn" (click)="updateCallSchema($event, formDataCS)">Сохранить</button>
	</div>
	<div>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div>
		<form #formDataCS="ngForm" style="flex-wrap: wrap;">
			<div class="col" style="width: 25%;">
				<div class="input-block" style="max-width: 170px" [ngClass]="{'invalid': formDataCS.submitted && name.invalid,
					'required': state != 'show', 'disabled': state == 'show'}">
					<label for="input_schema_name">Название</label>
					<text-input required id="input_schema_name" name="input_schema_name" [(ngModel)]="currentCallSchema.name" #name="ngModel"></text-input>
					<div *ngIf="formDataCS.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
			</div>
			<div class="col" style="width: 25%;">
				<div class="select-block" style="max-width: 170px">
					<label>Активная</label>
					<div class="base-toggle" [ngClass]="{'disabled': state == 'show'}">
						<input type="checkbox" id="schema_active" name="schema_active" [checked]="currentCallSchema.active" [(ngModel)]="currentCallSchema.active">
						<label for="schema_active">
								          <span class="toggle-btn">
								            <span class="o"></span>
								          </span>
						</label>
					</div>
				</div>
			</div>
			<div class="col" style="width: 25%;">
				<div class="select-block"  style="max-width: 170px"
				     [ngClass]="{'required': state != 'show', 'disabled': state == 'show', 'invalid': formDataCS.submitted && !currentCallSchema.type}">
					<div class="select-block">
						<app-select [selectName]="'Тип схемы звонка'"
						            [id]="'input_call_schema_type'"
						            [labelKey]="'label'"
						            [value]="currentCallSchema.type | schemaType"
						            [autocomplete]="false"
						            [readOnly]="state === 'show'"
						            [options]="schemaTypes"
						            (selectChange)="selectSchemaType($event)"></app-select>
					</div>
					<div *ngIf="formDataCS.submitted" class="invalid">
						<div *ngIf="!currentCallSchema.type">Поле не может быть пустым</div>
					</div>
				</div>
			</div>
			<div class="col" style="width: 25%;">
				<div class="select-block" style="max-width: 170px" [ngClass]="{'required': state != 'show' && currentCallSchema.type !== 'AUTOCALL', 'disabled': state == 'show',
					'invalid': formDataCS.submitted && num.invalid}">
					<label for="input_schema_number">Внешний номер</label>
					<dl class="select select-filter select-flag scrollable" [ngClass]="{'disabled': modalService.readonly('call-schema-modal')}">
						<dt class="">
							<input [required]="currentCallSchema.type !== 'AUTOCALL'" type="text" name="input_schema_number"
							       id="input_schema_number" [ngClass]="{'required': currentCallSchema.type !== 'AUTOCALL'}"
							       [(ngModel)]="currentCallSchema.externalNumberNumber" #num="ngModel">
						</dt>
						<dd>
							<div class="select-options">
								<div class="scroll-cont scrollbar-inner">
									<a *ngFor="let number of externalNumbers"
									   (click)="currentCallSchema.externalNumberId = number.id; currentCallSchema.externalNumberNumber = number.number">{{number.number}}</a>
								</div>
							</div>
						</dd>
					</dl>
					<div *ngIf="formDataCS.submitted && num.invalid" class="invalid">
						<div *ngIf="type.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
			</div>
			<div class="col" style="width: 100%;">
				<div class="input-block required textarea">
					<label for="input_schema">Схема звонка</label>
					<textarea *ngIf="state != 'show'" name="input_schema" id="input_schema" [(ngModel)]="currentCallSchema.schema"></textarea>
					<!--  -->
					<div *ngIf="state == 'show'" class="callSchema__page--scheme-section" (mousedown)="grabScheme($event)" (mouseup)="grabSchemeOut($event)" (mouseleave)="grabSchemeOut($event)" ui-view="main">
						<div class="callScheme--wrap">
							<div class="scheme-item" style="margin: 0 auto;">
								<div class="scheme-item__tel">
									<div class="scheme-item__tel__scname">
										<div class="item cn-down">
											<i class="icon calling" data-config="start-call">
												<call-ico></call-ico>
												<span class="counter"><span>1</span></span>
											</i>
											<div class="name" *ngIf="currentCallSchema.type == 'INBOUND'">Поступил новый звонок</div>
											<div class="name" *ngIf="currentCallSchema.type == 'OUTBOUND'">Исходящий звонок</div>
											<div class="name" *ngIf="currentCallSchema.type == 'AUTOCALL'">Совершён автозвонок</div>
										</div>
										<div *ngIf="currentCallSchema.schema" schema-node rootNode="true" schema="{{currentCallSchema.schema}}" id="firstItem" ng-show="currentCallSchema.schema != null"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
