export class HuntGroupPattern {
    constructor(id: number) {
        this.pbxId = id;
    }

    id: number;
    name: string = '';
    pbxId: number;
    type = 'HUNT_GROUP';
}
