<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="30px" width="30px" viewBox="0 0 53.938 53.938"
     style="enable-background:new 0 0 53.938 53.938;" xml:space="preserve">
		<g>
			<path d="M43.58,11.165c-0.391-0.391-1.023-0.391-1.414,0l-41,41c-0.391,0.391-0.391,1.023,0,1.414 c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293l41-41C43.971,12.189,43.971,11.556,43.58,11.165z"/>
			<path d="M14.294,36.209l7.716-7.716l-4.028-4.028c-2.045-2.045-2.233-3.928-0.631-6.292c0.224-0.31,1.649-1.575,2.869-2.595 c0.778-0.464,1.312-1.151,1.546-1.996c0.311-1.123,0.081-2.444-0.653-3.731c-0.173-0.296-4.289-7.27-8.085-9.277 c-0.709-0.375-1.506-0.573-2.307-0.573c-1.318,0-2.558,0.514-3.489,1.445L4.695,3.984c-7.401,7.402-6.031,16.596,3.962,26.588 L14.294,36.209z"/>
			<path d="M53.359,40.905c-2.008-3.795-8.981-7.912-9.288-8.092c-0.895-0.51-1.83-0.779-2.704-0.779c-1.301,0-2.367,0.596-3.012,1.68 c-1.021,1.221-2.287,2.646-2.592,2.867c-2.378,1.61-4.237,1.429-6.295-0.629l-3.217-3.217l-7.716,7.716l4.826,4.826 c5.745,5.746,11.224,8.659,16.281,8.659c0.001,0,0.001,0,0.001,0c3.721,0,7.188-1.581,10.306-4.698l2.537-2.537 C54.028,45.16,54.379,42.831,53.359,40.905z"/>
		</g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	</svg>