import {Injectable} from '@angular/core';
import {PbxAcl} from "../model/pbxAcl";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class AclService {

    constructor(private http: HttpClient) {
    }

    createAcl(id: number, acl: PbxAcl) {
        return this.http.post<number>(`api/pbx/${id}/acl`, acl);
    }

    updateAcl(pbxid: number, id: number, acl: PbxAcl) {
        return this.http.put<number>(`api/pbx/${pbxid}/acl/${id}`, acl);
    }

    getAcl(id: number) {
        return this.http.get<PbxAcl>(`api/acl/${id}`);
    }

    deleteAcl(id: number) {
        return this.http.delete(`api/acl/${id}`);
    }
}
