import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NgForm} from "@angular/forms";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {Page} from "../../../model/common/list/page";
import {VoiceMail} from "../../../model/voiceMail";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {VoiceMailSearch} from "../../../model/common/list/voiceMailSearch";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {VoiceMailsService} from "../../../service/voice-mails.service";
import {ModalState} from "../../../shared/modal/modal.state";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-voice-mails', templateUrl: './voice-mails.component.html', styleUrls: ['./voice-mails.component.css']
})
export class VoiceMailsComponent extends BasePbxTabComponent {
    public readonly voiceMailDeleteModal = "voice-mail-delete-modal";
    public readonly voiceMailModal = "voice-mail-modal";

    page: Page<VoiceMail>;
    listSearchData: ListSearchData<VoiceMailSearch>;

    currentVoiceMail: VoiceMail = new VoiceMail();
    isTabDataAvailable: Promise<boolean>;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService, private voiceMailsService: VoiceMailsService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'voice-mails';
        this.listSearchData = new ListSearchData(new VoiceMailSearch(this.currentPbx.id));
        this.loadPage();
    }

    loadPage() {
        this.voiceMailsService.search(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isTabDataAvailable = Promise.resolve(true);
        })
    }

    pageChanged($event: number) {
        this.listSearchData.page = $event - 1;
        this.loadPage();
    }

    countChange($event: number) {
        this.listSearchData.limit = $event;
        this.loadPage();
    }

    createDeleteModal(voiceMail: VoiceMail) {
        this.currentVoiceMail = voiceMail;
        this.modalService.open(this.voiceMailDeleteModal, ModalState.DELETE);
    }

    createEditModal(id: number) {
        this.voiceMailsService.getById(id).subscribe(value => {
            this.currentVoiceMail = value;
            this.modalService.open(this.voiceMailModal, ModalState.EDIT);
        })
    }

    createAddModal() {
        this.currentVoiceMail = new VoiceMail();
        this.modalService.open(this.voiceMailModal, ModalState.ADD);
    }

    delete(id: number) {
        if (this.currentVoiceMail && this.currentVoiceMail.id === id) {
            this.voiceMailsService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.voiceMailDeleteModal);
            })
        }
    }

    update($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentVoiceMail) {
            this.voiceMailsService.update(this.currentVoiceMail.id, this.currentVoiceMail).subscribe(() => {
                this.loadPage();
                this.closeModal(this.voiceMailModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    create($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentVoiceMail) {
            this.voiceMailsService.create(this.currentPbx.id, this.currentVoiceMail).subscribe(() => {
                this.loadPage();
                this.closeModal(this.voiceMailModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

}
