import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Page} from "../model/common/list/page";
import {Conference} from "../model/conference";
import {ListSearchData} from "../model/common/list/listSearchData";
import {ConferencePattern} from "../model/conferencePattern";

@Injectable({
    providedIn: 'root'
})
export class ConferenceService {

    constructor(private http: HttpClient) {
    }

    getByPbxId(id: number) {
        return this.http.get<Conference[]>(`api/pbx/${id}/conferences`);
    }

    getById(id: number) {
        return this.http.get<Conference>(`api/conferences/${id}`)
    }

    create(pbxid: number, Conference: Conference) {
        return this.http.post(`api/pbx/${pbxid}/conferences`, Conference);
    }

    update(Conference: Conference) {
        return this.http.put(`api/conferences`, Conference);
    }

    delete(id: number) {
        return this.http.delete(`api/conferences/${id}`)
    }

    getListByPbx(pbxId: number, searchData: ListSearchData<ConferencePattern>) {
        return this.http.post<Page<Conference>>(`api/pbx/${pbxId}/conferences/list`, searchData);
    }
}
