<div class="base-tab__block active" data-tabname="ats" *ngIf="isDataAvailable">
	<app-validation [codes]="validationCodes"></app-validation>
	<form [ngClass]="{'form-to-show': state === 'show'}" #formDataPbx="ngForm">
		<div class="col">
			<div class="input-block disabled">
				<label for="input_id">ID</label>
				<text-input id="input_id" name="input_id" [(ngModel)]="currentPbx.id" [disabled]="true" [hidden]="state === 'show'"></text-input>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.id}}</div>
			</div>
			<div class="input-block" [ngClass]="{'required': state === 'edit', 'invalid': formDataPbx.submitted && name.invalid}">
				<label for="input_name">Название</label>
				<text-input required id="input_name" name="input_name" [(ngModel)]="currentPbx.name" [hidden]="state === 'show'" #name="ngModel"></text-input>
				<div *ngIf="formDataPbx.submitted && name.invalid" class="invalid">
					<div *ngIf="name.errors.required">Поле не может быть пустым</div>
				</div>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.name}}</div>
			</div>
			<div class="input-block" [ngClass]="{'required': state === 'edit', 'invalid': formDataPbx.submitted && acode.invalid}">
				<label for="input_area">Код города</label>
				<text-input required id="input_area" name="input_area" [(ngModel)]="currentPbx.areaCode" [hidden]="state === 'show'" #acode="ngModel"></text-input>
				<div *ngIf="formDataPbx.submitted && acode.invalid" class="invalid">
					<div *ngIf="acode.errors.required">Поле не может быть пустым</div>
				</div>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.areaCode}}</div>
			</div>
			<div class="input-block" [ngClass]="{'required': state === 'edit', 'invalid': formDataPbx.submitted && ccode.invalid}">
				<label for="input_country">Код страны</label>
				<text-input required id="input_country" name="input_country" [(ngModel)]="currentPbx.countryCode" #ccode="ngModel"
				            [hidden]="state === 'show'"></text-input>
				<div *ngIf="formDataPbx.submitted && ccode.invalid" class="invalid">
					<div *ngIf="ccode.errors.required">Поле не может быть пустым</div>
				</div>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.countryCode}}</div>
			</div>
		</div>
		<div class="col">
			<div class="input-block">
				<label for="input_numb">Номер для уведомлений</label>
				<text-input id="input_numb" name="input_numb" [(ngModel)]="currentPbx.notificationPhone" [hidden]="state === 'show'"></text-input>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.notificationPhone}}</div>
			</div>
			<div class="input-block">
				<label for="input_numb">Номер регистрации</label>
				<text-input id="input_numb_reg" name="input_numb_reg" [(ngModel)]="currentPbx.registrationNumber" [hidden]="state === 'show'"></text-input>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.registrationNumber}}</div>
			</div>
			<div class="input-block" [ngClass]="{'required': state === 'edit', 'invalid': formDataPbx.submitted && callLimit.invalid}">
				<label for="input_calls">Кол-во одновременных звонков</label>
				<input type="number" required min="0" id="input_calls" name="input_calls" [(ngModel)]="currentPbx.callLimit" [hidden]="state === 'show'"
				       #callLimit="ngModel">
				<div *ngIf="formDataPbx.submitted && callLimit.invalid" class="invalid">
					<div *ngIf="callLimit.errors.required">Поле не может быть пустым</div>
				</div>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.callLimit}}</div>
			</div>
			<div class="select-block" [ngClass]="{'required': state === 'edit'}">
				<label>Статус</label>
				<div class="value ats-status" [ngClass]="currentPbx.status" *ngIf="state === 'show' || state === 'edit'">
					<span class="circle"></span><span>{{currentPbx.status | status}}</span>
					<button style="margin-left: 10px" class="btn base-btn btn__general red" *ngIf="currentPbx.status !== 'DISABLED'" (click)="createPbxBlockModal()">Отключить</button>
					<button style="margin-left: 10px" class="btn base-btn btn__general green" *ngIf="currentPbx.status === 'DISABLED'" (click)="createPbxUnblockModal()">Активировать</button>
				</div>
			</div>
			<div class="select-block">
				<label *ngIf="state === 'show'">Сервер</label>
				<div class="value" *ngIf="state === 'show'">{{currentPbx.serverName}}</div>
				<app-select *ngIf="state === 'edit'" [selectName]="'Сервер'"
				            [id]="'input_pbx_serv'"
				            [labelKey]="'name'"
				            [value]="server()"
				            [required]="true"
				            [autocomplete]="true"
				            [readOnly]="false"
				            [options]="servers"
				            (selectChange)="setServer($event)"></app-select>
			</div>
		</div>
	</form>
	<button class="btn base-btn btn__general" *ngIf="state === 'edit'" (click)="save($event, formDataPbx)">Сохранить</button>
	<button class="btn base-btn btn__general" *ngIf="state === 'show'" [routerLink]="[ '/ats',currentPbx.id,'edit']" (click)="state = 'edit'">Редактирование</button>
	<button class="btn base-btn btn__general grey" *ngIf="state === 'show'" [routerLink]="['/ats']">Отмена</button>
	<button class="btn base-btn btn__general grey" *ngIf="state === 'edit'" [routerLink]="[ '/ats',currentPbx.id,'show']" (click)="state = 'show'">Отмена</button>
</div>
<!--  -->
<app-modal [id]="pbxBlockModal" [title]="'Отключить АТС'">
	<div body *ngIf="currentPbx">
		<div class="settings-delete_popup--content">
			Отключить АТС <span class="b">{{currentPbx.name}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(pbxBlockModal)">Отмена</button>
		<button class="btn base-btn red" (click)="block()">Отключить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="pbxUnblockModal" [title]="'Активировать АТС'">
	<div body *ngIf="currentPbx">
		<div class="settings-delete_popup--content">
			Активировать АТС <span class="b">{{currentPbx.name}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(pbxUnblockModal)">Отмена</button>
		<button class="btn base-btn green" (click)="unblock()">Активировать</button>
	</div>
</app-modal>