<div class="base-tab__block active" *ngIf="isDataAvailable && pageLoaded">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить конференцию
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по названию"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table" *ngIf="page">
				<thead>
				<tr>
					<td>Название конференции</td>
					<td>Внутренний номер</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let conference of page.items">
					<td>{{conference.name}}</td>
					<td>{{conference.number}}</td>
					<td>
						<app-actions (edit)="createEditModal(conference)" (delete)="createDeleteModal(conference)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить конференцию
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1_mob" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по названию"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body">
			<table class="base-table" *ngIf="page">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let conference of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Название конференции</div>
									<div class="data">{{conference.name}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Внутренний номер</div>
									<div class="data">{{conference.number}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (edit)="createEditModal(conference)" (delete)="createDeleteModal(conference)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="conferenceModal" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formData="ngForm">
			<div class="col" *ngIf="currentConference">
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && number.invalid}">
					<label for="input_13">Внутренний номер</label>
					<text-input id="input_13" name="input_13" required [(ngModel)]="currentConference.number" #number="ngModel"></text-input>
					<div *ngIf="formData.submitted && number.invalid" class="invalid">
						<div *ngIf="number.errors.required">Введите номер</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && name.invalid}">
					<label for="input_24">Название конференции</label>
					<text-input id="input_24" name="input_24" required [(ngModel)]="currentConference.name" #name="ngModel"></text-input>
					<div *ngIf="formData.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Введите название конференции</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && pin.invalid}">
					<label for="input_25">Пин</label>
					<text-input id="input_25" name="input_25" required [(ngModel)]="currentConference.pin" #pin="ngModel"></text-input>
					<div *ngIf="formData.submitted && pin.invalid" class="invalid">
						<div *ngIf="pin.errors.required">Введите пин</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(conferenceModal); formData.onReset()">Отмена</button>
		<button *ngIf="modalService.getState(conferenceModal) === 'ADD'" class="btn base-btn" (click)="create($event, formData)">Создать</button>
		<button *ngIf="modalService.getState(conferenceModal) === 'EDIT'" class="btn base-btn" (click)="update($event, formData)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="conferenceDeleteModal" [title]="'Удалить конференцию'">
	<div body *ngIf="currentConference">
		<div class="settings-delete_popup--content">
			Удалить конференцию: <span class="b">{{currentConference.number}} - {{currentConference.name}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentConference.id)">Удалить</button>
	</div>
</app-modal>
