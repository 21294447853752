import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AtsComponent} from "./pbxlist/ats.component";
import {PbxComponent} from "./pbx.component";
import {PbxDetailsComponent} from "./pbx-details/pbx-details.component";
import {CompanyDetailsComponent} from "./company-details/company-details.component";
import {FormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {SharedModule} from "../../shared/shared.module";
import {AclComponent} from "./acl/acl.component";
import {ConferencesComponent} from "./conferences/conferences.component";
import {ExternalNumbersComponent} from "./external-numbers/external-numbers.component";
import {FaxesComponent} from "./faxes/faxes.component";
import {HuntGroupsComponent} from "./hunt-groups/hunt-groups.component";
import {InternalNumbersComponent} from "./internal-numbers/internal-numbers.component";
import {QueuesComponent} from "./queues/queues.component";
import {VoiceMailsComponent} from "./voice-mails/voice-mails.component";
import {routing} from "./pbx.routing";
import {NgSelectModule} from "@ng-select/ng-select";
import {StorageFilesComponent} from './storage-files/storage-files.component';
import {MatSliderModule} from "@angular/material/slider";
import {PersonComponent} from './person/person.component';
import {TariffComponent} from "./tariff/tariff.component";
import {InputTrimModule} from "ng2-trim-directive";

import {SchemaNodeComponent} from "../../shared/call-schema/schema-node.component";
import {RedirectNodeComponent} from "../../shared/call-schema/node/redirect-node.component";
import {RedirectIcoComponent} from "../../shared/call-schema/ico/redirect-ico.component";
import {VoiceMenuIcoComponent} from "../../shared/call-schema/ico/voice-menu-ico.component";
import {VoiceMenuNodeComponent} from "../../shared/call-schema/node/voice-menu-node.component";
import {HangupNodeComponent} from "../../shared/call-schema/node/hangup-node.component";
import {HangupIcoComponent} from "../../shared/call-schema/ico/hangup-ico.component";
import {VoiceMailIcoComponent} from "../../shared/call-schema/ico/voice-mail-ico.component";
import {VoiceMailNodeComponent} from "../../shared/call-schema/node/voice-mail-node.component";
import {PlaybackIcoComponent} from "../../shared/call-schema/ico/playback-ico.component";
import {PlaybackNodeComponent} from "../../shared/call-schema/node/playback-node.component";
import {InputNumberIcoComponent} from "../../shared/call-schema/ico/input-number-ico.component";
import {GotoIcoComponent} from "../../shared/call-schema/ico/goto-ico.component";
import {GotoNodeComponent} from "../../shared/call-schema/node/goto-node.component";
import {InputNumberNodeComponent} from "../../shared/call-schema/node/input-number-node.component";
import {CallIcoComponent} from "../../shared/call-schema/ico/call-ico.component";
import {AnswerIcoComponent} from "../../shared/call-schema/ico/answer-ico.component";
import {NumberIcoComponent} from "../../shared/call-schema/ico/number-ico.component";
import {DateIcoComponent} from "../../shared/call-schema/ico/date-ico.component";
import {QueueIcoComponent} from "../../shared/call-schema/ico/queue-ico.component";
import {FaxIcoComponent} from "../../shared/call-schema/ico/fax-ico.component";
import {AnswerNodeComponent} from "../../shared/call-schema/node/answer-node.component";
import {NumberNodeComponent} from "../../shared/call-schema/node/number-node.component";
import {DateNodeComponent} from "../../shared/call-schema/node/date-node.component";
import {CallNodeComponent} from "../../shared/call-schema/node/call-node.component";
import {QueueNodeComponent} from "../../shared/call-schema/node/queue-node.component";
import {FaxNodeComponent} from "../../shared/call-schema/node/fax-node.component";
import {CallSchemaComponent} from "./call-schemas/call-schema/call-schema.component";
import {TagNodeComponent} from "../../shared/call-schema/node/tag-node.component";
import {TagIcoComponent} from "../../shared/call-schema/ico/tag-ico.component";
import {CallSchemasComponent} from "./call-schemas/call-schemas.component";
import {IntegrationsComponent} from "./integrations/integrations.component";

@NgModule({
    declarations: [//
        AtsComponent,//
        AclComponent,//
        ConferencesComponent,//
        ExternalNumbersComponent,//
        CallSchemasComponent,//
        CallSchemaComponent,//
        FaxesComponent,//
        IntegrationsComponent,//
        HuntGroupsComponent,//
        InternalNumbersComponent,//
        QueuesComponent,//
        VoiceMailsComponent,//
        PbxComponent,//
        PbxDetailsComponent,//
        CompanyDetailsComponent, //
        TariffComponent, //
        StorageFilesComponent, //
        PersonComponent, //

        SchemaNodeComponent, //
        // call schema icon templates
        AnswerIcoComponent, //
        NumberIcoComponent, //
        DateIcoComponent, //
        CallIcoComponent, //
        HangupIcoComponent, //
        QueueIcoComponent, //
        FaxIcoComponent, //
        VoiceMailIcoComponent, //
        GotoIcoComponent, //
        VoiceMenuIcoComponent, //
        InputNumberIcoComponent, //
        PlaybackIcoComponent, //
        RedirectIcoComponent, //
        TagIcoComponent, //


        // call schema node templates
        AnswerNodeComponent, //
        NumberNodeComponent, //
        DateNodeComponent, //
        CallNodeComponent, //
        HangupNodeComponent, //
        QueueNodeComponent, //
        FaxNodeComponent, //
        VoiceMailNodeComponent, //
        GotoNodeComponent, //
        VoiceMenuNodeComponent, //
        InputNumberNodeComponent, //
        PlaybackNodeComponent, //
        RedirectNodeComponent, //
        TagNodeComponent //
    ], imports: [SharedModule, CommonModule, FormsModule, RouterModule, routing, NgSelectModule, MatSliderModule, InputTrimModule], //
    exports: [//
        AtsComponent,//
        AclComponent,//
        ConferencesComponent,//
        ExternalNumbersComponent,//
        CallSchemasComponent,//
        CallSchemaComponent,//
        FaxesComponent,//
        IntegrationsComponent,//
        HuntGroupsComponent,//
        InternalNumbersComponent,//
        QueuesComponent,//
        VoiceMailsComponent,//
        PbxComponent,//
        PbxDetailsComponent,//
        CompanyDetailsComponent, //
        TariffComponent, //
        StorageFilesComponent, //

        SchemaNodeComponent, //
        // call schema icon templates
        AnswerIcoComponent, //
        NumberIcoComponent, //
        DateIcoComponent, //
        CallIcoComponent, //
        HangupIcoComponent, //
        QueueIcoComponent, //
        FaxIcoComponent, //
        VoiceMailIcoComponent, //
        GotoIcoComponent, //
        VoiceMenuIcoComponent, //
        InputNumberIcoComponent, //
        PlaybackIcoComponent, //
        RedirectIcoComponent, //
        TagIcoComponent, //

        // call schema node templates
        AnswerNodeComponent, //
        NumberNodeComponent, //
        DateNodeComponent, //
        CallNodeComponent, //
        HangupNodeComponent, //
        QueueNodeComponent, //
        FaxNodeComponent, //
        VoiceMailNodeComponent, //
        GotoNodeComponent, //
        VoiceMenuNodeComponent, //
        InputNumberNodeComponent, //
        PlaybackNodeComponent, //
        RedirectNodeComponent, //
        TagNodeComponent //
    ]
})
export class PbxModule {
}
