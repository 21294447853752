import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalState} from "./modal.state";
import {ModalService} from "../../service/modal.service";

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit, OnDestroy {
    @Input('id') id: string;
    state: ModalState;
    private readonly element: any;
    @Input('additionalClass')
    additionalClass: string;
    @Input('title')
    title: string;
    @Input('customLabel')
    customLabel: string;

    constructor(public modalService: ModalService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }

        document.body.appendChild(this.element);
        this.modalService.add(this);
    }

    open(id: string, state: ModalState) {
        this.state = state;
        $('#' + id).addClass('opened');
    }

    close(id: string) {
        this.state = ModalState.NONE;
        $('#' + id).removeClass('opened');
    }

    ngOnDestroy(): void {
        this.element.remove();
    }
}
