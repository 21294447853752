import {Injectable} from '@angular/core';
import {ModalComponent} from "../shared/modal/modal.component";
import {ModalState} from "../shared/modal/modal.state";

@Injectable({providedIn: 'root'})
export class ModalService {
    private modalInstances: ModalComponent[] = [];

    add(modal: ModalComponent) {
        this.modalInstances.push(modal);
    }

    remove(id: string) {
        this.modalInstances = this.modalInstances.filter(x => x.id !== id);
    }

    open(id: string, state: ModalState) {
        this.modalInstances.filter(x => x.id === id)[0].open(id, state);
    }

    close(id: string) {
        this.modalInstances.filter(x => x.id === id)[0].close(id);
    }

    getState(id: string) {
        return this.modalInstances.filter(x => x.id === id)[0].state;
    }

    setState(id: string, state: ModalState) {
        return this.modalInstances.filter(x => x.id === id)[0].state = state;
    }

    readonly(id: string) {
        return this.modalInstances.filter(x => x.id === id)[0] === undefined || this.modalInstances.filter(x => x.id === id)[0].state === 'SHOW';
    }
}
