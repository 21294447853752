import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {ParamMap} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class RouteStateService {
    private pathParamState = new BehaviorSubject<ParamMap>(null);
    pathParam: Observable<ParamMap>;

    constructor() {
        this.pathParam = this.pathParamState.asObservable();
    }

    updatePathParamState(newPathParam: ParamMap) {
        this.pathParamState.next(newPathParam);
    }
}
