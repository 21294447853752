import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ListSearchData} from "../model/common/list/listSearchData";
import {Page} from "../model/common/list/page";
import {Queue} from "../model/queue";
import {QueueMember} from "../model/queueMember";
import {QueuePattern} from "../model/queuePattern";

@Injectable({
  providedIn: 'root'
})
export class QueuesService {


    constructor(private http: HttpClient) {
    }

    getById(id: number) {
        return this.http.get<Queue>(`api/queues/${id}`)
    }

    getListMembers(id: number) {
        return this.http.get<QueueMember[]>(`api/queues/${id}/members`)
    }

    create(pbxid: number, queue: Queue) {
        return this.http.post(`api/pbx/${pbxid}/queues`, queue);
    }

    update(queue: Queue) {
        return this.http.put(`api/queues/${queue.id}`, queue);
    }

    saveMembers(id: number, queueMembers: QueueMember[]) {
        return this.http.put(`api/queues/${id}/members`, queueMembers);
    }

    delete(id: number) {
        return this.http.delete(`api/queues/${id}`)
    }

    getListByPbx(pbxId: number, searchData: ListSearchData<QueuePattern>) {
        return this.http.post<Page<Queue>>(`api/pbx/${pbxId}/queues/list`, searchData);
    }
}
