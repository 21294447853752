<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="30px" width="30px" viewBox="0 0 53.9 53.9"
     style="enable-background:new 0 0 53.9 53.9;" xml:space="preserve">
		<style type="text/css">
			.st0 {
                display: none;
            }
		</style>
	<path d="M53.4,40.9c-2-3.8-9-7.9-9.3-8.1c-0.9-0.5-1.8-0.8-2.7-0.8c-1.3,0-2.4,0.6-3,1.7c-1,1.2-2.3,2.6-2.6,2.9 c-2.4,1.6-4.2,1.4-6.3-0.6L18,24.5c-2-2-2.2-3.9-0.6-6.3c0.2-0.3,1.6-1.6,2.9-2.6c0.8-0.5,1.3-1.2,1.5-2c0.3-1.1,0.1-2.4-0.7-3.7 c-0.2-0.3-4.3-7.3-8.1-9.3C12.3,0.2,11.5,0,10.7,0C9.4,0,8.2,0.5,7.2,1.4L4.7,4c-4,4-5.5,8.6-4.3,13.5c1,4.1,3.7,8.5,8.3,13.1 l14.7,14.7c5.7,5.7,11.2,8.7,16.3,8.7c0,0,0,0,0,0c3.7,0,7.2-1.6,10.3-4.7l2.5-2.5C54,45.2,54.4,42.8,53.4,40.9z"/>
	<polygon points="35.7,4.8 35.7,7.6 43.9,7.6 28.7,22.8 30.8,24.9 46,9.7 46,17.9 48.9,17.9 48.9,4.8 "/>
	<polygon class="st0" points="28.8,5 30.2,6.4 32.9,3.6 32.9,20.8 52.4,20.8 52.4,19 34.8,19 34.8,3.6 37.6,6.4 38.9,5 33.9,0 "/>
	</svg>