import {OnInit} from '@angular/core';
import {PbxForm} from "../../model/pbxForm";
import {ActivatedRoute} from "@angular/router";
import {ModalService} from "../../service/modal.service";
import {AtsService} from "../../service/ats.service";
import {RouteStateService} from "../../service/route-state.service";
import {DeviceDetectionComponent} from "../deviceDetection.component";
import {DeviceDetectorService} from "ngx-device-detector";

export abstract class BasePbxTabComponent extends DeviceDetectionComponent implements OnInit {
    currentPbx: PbxForm = new PbxForm();
    balance: number;
    protected id: any;
    tab: string = 'ats';

    validationCodes: number[];
    isDataAvailable: Promise<boolean>;
    state: string;

    protected constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected atsService: AtsService, protected route: ActivatedRoute, protected routeStateService: RouteStateService) {
        super(deviceService);
    }

    ngOnInit() {
        this.atsService.currentPbx$.subscribe(value => {
            this.currentPbx = value;
        });
        this.routeStateService.pathParam.subscribe(value => {
            if(value !== null) {
                this.id = value.get('id');
                this.state = value.get('state');
                this.loadPbx(false);
                this.atsService.setCurrentTab(this.tab);
            }
        });
    }

    abstract tabOnInit(): void;

    closeModal(id: string) {
        this.modalService.close(id);
        this.validationCodes = [];
    }

    protected loadPbx(force: boolean) {
        if (force || this.currentPbx === null || this.currentPbx.id === undefined) {
            this.atsService.getById(this.id).subscribe(value => {
                this.currentPbx = value;
                this.atsService.setCurrentPbx(value);
                this.tabOnInit();
                this.isDataAvailable = Promise.resolve(true);
            });
        } else {
            this.tabOnInit();
            this.isDataAvailable = Promise.resolve(true);
        }
    }

    findIndexToUpdate(newItem) {
        return newItem.id === this;
    }

    getBalance() {
        this.atsService.getBalanceById(this.currentPbx.id).subscribe(value => {
            this.balance = value;
        })
    }
}
