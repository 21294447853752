export class PbxSearch {
    constructor() {
    }

    public operation: string;
    public balance: number;
    public idPbx: number;
    public namePbx: string;
    public notificationPhone: string;
    public companyName: string;
    public contractNumber: string;
    public personName: string;
    public externalNumber: string;
    public registrationNumber: string;
    public startCreateDate: Date;
    public endCreateDate: Date;
    public login: string;
    public email: string;
    public status: string;
    public availableForBilling: boolean;
}
