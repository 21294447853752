<div class="logo-loader">
	<svg viewBox="0 0 28 46" class="logo-lights-animation">
		<g>
			<circle class="l6" style="fill:#F3901A;" cx="8.8" cy="27" r="3"/>
			<path class="l6" style="fill:#F59D1A;" d="M6.6,14.2c1.6,0,3-1.3,3-3c0-1.6-1.3-3-3-3c-1.7,0-3,1.3-3,3C3.7,12.8,5,14.2,6.6,14.2z"/>
			<circle class="l5" style="fill:#F59D1A;" cx="14.3" cy="11.2" r="3"/>
			<circle class="l5" style="fill:#F4971A;" cx="11.6" cy="19" r="3"/>
			<circle class="l5" style="fill:#F6A31A;" cx="17" cy="3.2" r="3"/>
			<circle class="l4" style="fill:#F1861B;" cx="3.4" cy="42.8" r="3"/>
			<path class="l4" style="fill:#F59D1A;" d="M21.9,8.2c-1.7,0-3,1.3-3,3c0.1,1.6,1.4,3,3,3c1.6,0,3-1.3,3-3C24.9,9.5,23.6,8.2,21.9,8.2z"/>
			<circle class="l3" style="fill:#F28B1A;" cx="6.1" cy="35" r="3"/>
			<circle class="l3" style="fill:#F4971A;" cx="19.2" cy="19" r="3"/>
			<circle class="l2" style="fill:#F6A31A;" cx="24.6" cy="3.2" r="3"/>
			<circle class="l2" style="fill:#F28B1A;" cx="13.7" cy="35" r="3"/>
			<circle class="l1" style="fill:#F3901A;" cx="16.4" cy="27" r="3"/>
		</g>
	</svg>
</div>
