import {Component} from '@angular/core';
import {BasePbxTabComponent} from "../basePbxTab.component";
import {PbxAcl} from "../../../model/pbxAcl";
import {AtsService} from "../../../service/ats.service";
import {AclService} from "../../../service/acl.service";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {ActivatedRoute} from "@angular/router";
import {ModalState} from "../../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-acl', //
    templateUrl: './acl.component.html', //
    styleUrls: ['./acl.component.css']
})
export class AclComponent extends BasePbxTabComponent {
    currentPbxAcl: PbxAcl = new PbxAcl();
    pbxAcls: PbxAcl[];


    constructor(public deviceService: DeviceDetectorService, protected atsService: AtsService, protected aclService: AclService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService) {
        super(deviceService, modalService, atsService, route,routeStateService);
    }

    tabOnInit() {
        this.pbxAcls = [...this.currentPbx.pbxAcls];
        this.tab = "acl";
    }

    createAclModal() {
        this.currentPbxAcl = new PbxAcl();
        this.modalService.open("pbx-acl-modal", ModalState.ADD);
    }

    createDeleteAclModal(acl: PbxAcl) {
        this.aclService.getAcl(acl.id).subscribe(value => {
            this.currentPbxAcl = value;
            this.modalService.open("acl-delete-modal", ModalState.DELETE);
        });
    }

    deleteAcl(id: number) {
        if(this.currentPbxAcl && this.currentPbxAcl.id === id) {
            this.aclService.deleteAcl(id).subscribe(() => {
                const updatedItem = this.pbxAcls.find(this.findIndexToUpdate, this.currentPbxAcl.id);
                let index = this.pbxAcls.indexOf(updatedItem);
                this.pbxAcls.splice(index, 1);
                this.closeModal("acl-delete-modal");
            })
        }
    }

    createACL(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentPbxAcl) {
            this.fillAddresses();
            this.aclService.createAcl(this.currentPbx.id, this.currentPbxAcl).subscribe((id) => {
                this.aclService.getAcl(id).subscribe(value => {
                    this.currentPbxAcl = value;
                    this.pbxAcls.push(this.currentPbxAcl);
                });
                this.closeModal("pbx-acl-modal");
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            });
        }

    }

    updateACL(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentPbxAcl) {
            this.fillAddresses();
            this.aclService.updateAcl(this.currentPbx.id, this.currentPbxAcl.id, this.currentPbxAcl).subscribe((id) => {
                this.aclService.getAcl(id).subscribe(value => {
                    this.currentPbxAcl = value;
                    const updatedItem = this.pbxAcls.find(this.findIndexToUpdate, this.currentPbxAcl.id);
                    let index = this.pbxAcls.indexOf(updatedItem);
                    this.pbxAcls[index] = this.currentPbxAcl;
                });
                this.closeModal("pbx-acl-modal");
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            });
        }

    }

    private fillAddresses() {
        if (this.currentPbxAcl.address.includes('-')) {
            this.currentPbxAcl.type = "RANGE";
            const addresses = this.currentPbxAcl.address.split("-", 2);
            this.currentPbxAcl.addressFrom = addresses[0];
            this.currentPbxAcl.addressTo = addresses[1];
            this.currentPbxAcl.address = null;
        } else if (this.currentPbxAcl.address.includes('/')) {
            this.currentPbxAcl.type = "SUBNET";
            this.currentPbxAcl.addressSubnet = this.currentPbxAcl.address;
            this.currentPbxAcl.address = null;
        } else {
            this.currentPbxAcl.type = "SIMPLE";
            this.currentPbxAcl.addressFrom = null;
            this.currentPbxAcl.addressSubnet = null;
            this.currentPbxAcl.addressTo = null;
        }
    }

    editAclModal(id: number) {
        this.aclService.getAcl(id).subscribe(value => {
            this.currentPbxAcl = value;
            this.fillPbxAclAddress();
            this.modalService.open("pbx-acl-modal", ModalState.EDIT);
        });
    }

    private fillPbxAclAddress() {
        if (this.currentPbxAcl.type === "RANGE") {
            this.currentPbxAcl.address = this.currentPbxAcl.addressFrom + "-" + this.currentPbxAcl.addressTo;
        } else if (this.currentPbxAcl.type === "SUBNET") {
            this.currentPbxAcl.address = this.currentPbxAcl.addressSubnet;
        }
    }

    showAclModal(pbxAcl: PbxAcl) {
        this.aclService.getAcl(pbxAcl.id).subscribe(value => {
            this.currentPbxAcl = value;
            this.fillPbxAclAddress();
            this.modalService.open("pbx-acl-modal", ModalState.SHOW);
        });
    }
}
