import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ListSearchData} from "../model/common/list/listSearchData";
import {Page} from "../model/common/list/page";
import {CallRecordSearch} from "../model/common/list/callRecordSearch";
import {CallRecord} from "../model/common/callRecord/callRecord";
import {StorageFileData} from "../model/common/callRecord/storageFileData";

@Injectable({
    providedIn: 'root'
})
export class CallRecordService {

    constructor(private http: HttpClient) {
    }

    getById(id: string) {
        return this.http.get<CallRecord>('api/call-record/' + id);
    }

    getAll(searchData: ListSearchData<CallRecordSearch>) {
        return this.http.post<Page<CallRecord>>('api/call-record', searchData);
    }

    getAllStorageFiles(searchData: ListSearchData<CallRecordSearch>) {
        return this.http.post<Page<StorageFileData>>('api/call-record/storageFiles', searchData);
    }
}
