import {ChannelData} from "./channelData";
import {CampaignNumberData} from "./campaignNumberData";
import {CallTrackingData} from "./callTrackingData";

export class CallRecord {
    _id: string;
    callDate: string;
    source: string;
    destination: string;
    redirect: string;
    duration: bigint;
    talkTime: bigint;
    direction: string;
    disposition: string;
    callSchemaId: bigint;
    storageFileId: bigint;

    pbxId: bigint;
    answered: string;
    accountLogin: string;
    channelData: ChannelData;
    campaignNumberData: CampaignNumberData;
    callTrackingData: CallTrackingData;

}
