import {Pipe, PipeTransform} from "@angular/core";
import {PbxStatus} from "../../model/pbxStatus";

@Pipe({name: 'status'})
export class StatusPipe implements PipeTransform {
    transform(value: any) {
        return PbxStatus[value];
    }

}
