<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="30px" width="30px" viewBox="0 0 612 612"
     style="enable-background:new 0 0 612 612;" xml:space="preserve">
		<g>
			<g id="Fax2_1_">
				<path d="M459,130.526l-74.314-0.005l0,0c-3.448,0-6.245,2.795-6.245,6.245c0,3.45,2.795,6.245,6.245,6.245L459,143.016l0,0 c3.448,0,6.245-2.795,6.245-6.245C465.245,133.322,462.45,130.526,459,130.526z"/>
				<path d="M533.314,93.063l-148.629-0.011l0,0c-3.448,0-6.245,2.795-6.245,6.245s2.795,6.245,6.245,6.245l148.629,0.011l0,0 c3.448,0,6.245-2.795,6.245-6.245C539.559,95.859,536.764,93.063,533.314,93.063z"/>
				<path d="M533.314,55.593l-148.629-0.011l0,0c-3.448,0-6.245,2.795-6.245,6.245c0,3.45,2.795,6.245,6.245,6.245l148.629,0.011l0,0 c3.448,0,6.245-2.795,6.245-6.245C539.559,58.39,536.764,55.593,533.314,55.593z"/>
				<path d="M563.29,114.298c0-31.229,0-62.459,0-93.69c-69.527,0-139.053,0-208.58,0c0,31.224,0,62.449,0,93.673 c-27.626,0-55.254,0-82.88,0c7.524,17.045,11.063,33.826,11.063,52.457c0,106.579,0,213.16,0,319.739 c0,18.631-3.54,35.412-11.063,52.457c96.737,0,193.473,0,290.21,0c27.591,0,49.959-22.368,49.959-49.959 c0-108.245,0-216.489,0-324.735C612,137.135,590.386,114.975,563.29,114.298z M367.2,33.098h183.6v128.645H367.2V33.098z M380.314,430.273c-23.395,0-26.229-2.834-26.229-26.229s2.834-26.229,26.229-26.229s26.229,2.834,26.229,26.229 S403.709,430.273,380.314,430.273z M380.314,352.837c-23.395,0-26.229-2.834-26.229-26.229s2.834-26.229,26.229-26.229 s26.229,2.834,26.229,26.229C406.543,350.003,403.709,352.837,380.314,352.837z M380.314,275.4 c-23.395,0-26.229-2.834-26.229-26.229s2.834-26.229,26.229-26.229s26.229,2.834,26.229,26.229S403.709,275.4,380.314,275.4z M459,430.273c-23.395,0-26.229-2.834-26.229-26.229s2.834-26.229,26.229-26.229s26.229,2.834,26.229,26.229 S482.395,430.273,459,430.273z M459,352.837c-23.395,0-26.229-2.834-26.229-26.229S435.605,300.38,459,300.38 s26.229,2.834,26.229,26.229C485.229,350.003,482.395,352.837,459,352.837z M459,275.4c-23.395,0-26.229-2.834-26.229-26.229 s2.834-26.229,26.229-26.229s26.229,2.834,26.229,26.229S482.395,275.4,459,275.4z M537.686,430.273 c-23.395,0-26.229-2.834-26.229-26.229s2.834-26.229,26.229-26.229c23.395,0,26.229,2.834,26.229,26.229 S561.08,430.273,537.686,430.273z M537.686,352.837c-23.395,0-26.229-2.834-26.229-26.229s2.834-26.229,26.229-26.229 c23.395,0,26.229,2.834,26.229,26.229C563.914,350.003,561.08,352.837,537.686,352.837z M537.686,275.4 c-23.395,0-26.229-2.834-26.229-26.229s2.834-26.229,26.229-26.229c23.395,0,26.229,2.834,26.229,26.229 S561.08,275.4,537.686,275.4z"/>
				<path d="M153,61.824c-57.943,0-104.914,46.972-104.914,104.914c0,106.579,0,213.16,0,319.739 c0,57.943,46.972,104.914,104.914,104.914s104.914-46.972,104.914-104.914c0-106.579,0-213.16,0-319.739 C257.914,108.796,210.943,61.824,153,61.824z"/>
				<path d="M32.887,117.289C13.143,124.468,0,143.233,0,164.241C0,272.486,0,380.73,0,488.975c0,21.008,13.143,39.772,32.887,46.952 c-6.637-16.122-9.781-32.015-9.781-49.45c0-106.579,0-213.16,0-319.739C23.106,149.303,26.25,133.411,32.887,117.289z"/>
			</g>
		</g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	</svg>