import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./auth.guard";
import {LayoutComponent} from "./layout/layout.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {ServerComponent} from "./pages/server/server.component";
import {AreaCodeComponent} from "./pages/areaCode/areaCode.component";
import {UnionExternalNumberComponent} from "./pages/unionExternalNumber/unionExternalNumber.component";
import {SipPeerComponent} from "./pages/sipPeer/sipPeer.component";
import {CallRecordComponent} from "./pages/callRecord/callRecord.component";
import {RecordComponent} from "./pages/record/record.component";
import {UserListComponent} from "./pages/user/userList/user-list.component";

// @formatter:off
const routes: Routes = [
    {
        path: 'login',
        loadChildren: './pages/auth/auth.module#AuthModule'
    },
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'ats',
                loadChildren: './pages/pbx/pbx.module#PbxModule'
            },
            {
                path: 'user-list',
                component: UserListComponent,
                canActivate: [AuthGuard],
                data: { roles: ['ADMINISTRATOR'] }
            },
            {
                path: 'server',
                component: ServerComponent,
                canActivate: [AuthGuard],
                data: { roles: ['ADMINISTRATOR'] }
            },
            {
                path: 'area-code',
                component: AreaCodeComponent,
                canActivate: [AuthGuard],
                data: { roles: ['ADMINISTRATOR'] }
            },
            {
                path: 'union-external-number',
                component: UnionExternalNumberComponent,
                canActivate: [AuthGuard],
                data: { roles: ['ADMINISTRATOR', 'MANAGER'] }
            },
            {
                path: 'sip-peer',
                component: SipPeerComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'call-record',
                component: CallRecordComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'records',
                component: RecordComponent,
                canActivate: [AuthGuard],
                data: { roles: ['ADMINISTRATOR'] }
            },
            {
                path: '',
                redirectTo: '/dashboard',
                pathMatch: 'full',
                canActivate: [AuthGuard]
            }
        ]
    }
];
// @formatter:on
@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})], exports: [RouterModule]
})
export class AppRoutingModule {
}
