<div class="title">
	Sip Peers
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<app-mks-paging-short class="left-margin" *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                      (countChange)="countChange($event)"></app-mks-paging-short>
		<div *filter-marker class="inputs-row">
			<div class="input-block">
				<text-input id="input_name" name="input_name" [(ngModel)]="listSearchData.searchPattern.peerName" placeholder="Имя"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt>
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.status | peerStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.status = undefined">Все</a>
							<a data-value="ONLINE" (click)="listSearchData.searchPattern.status = 'ONLINE'">{{ 'ONLINE' | peerStatus }}</a>
							<a data-value="OFFLINE" (click)="listSearchData.searchPattern.status = 'OFFLINE'">{{ 'OFFLINE' | peerStatus }}</a>
							<a data-value="DISCONNECTED" (click)="listSearchData.searchPattern.status = 'DISCONNECTED'">{{ 'DISCONNECTED' | peerStatus }}</a>
							<a data-value="NOT_CONFIGURED" (click)="listSearchData.searchPattern.status = 'NOT_CONFIGURED'">{{ 'NOT_CONFIGURED' | peerStatus }}</a>
							<a data-value="UNMONITORED" (click)="listSearchData.searchPattern.status = 'UNMONITORED'">{{ 'UNMONITORED' | peerStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block">
				<text-input id="input_ip" name="input_ip" [(ngModel)]="listSearchData.searchPattern.address" placeholder="Адрес"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_useragent" name="input_useragent" [(ngModel)]="listSearchData.searchPattern.userAgent" placeholder="User Agent"></text-input>
			</div>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата начала'" [(ngModel)]="listSearchData.searchPattern.fromDate"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата окончания'" [(ngModel)]="listSearchData.searchPattern.tillDate"></app-datetime-picker>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td>
					<span>Имя</span>
					<app-sort [component]="this" [property]="'peerName'"></app-sort>
				</td>
				<td>
					<span>Статус</span>
					<app-sort [component]="this" [property]="'status'"></app-sort>
				</td>
				<td>Адрес</td>
				<td>Порт</td>
				<td>User Agent</td>
				<td>
					<span>Время</span>
					<app-sort [component]="this" [property]="'regDate'"></app-sort>
				</td>
				<td>Сервер (ID)</td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let sipPeer of page.items">
				<td style="width: 10%">{{sipPeer.peerName}}</td>
				<td class="status">
					<div class="sipPeer-status" [ngClass]="sipPeer.status">
						<span class="circle"></span><span>{{sipPeer.status | peerStatus}}</span></div>
				</td>
				<td>{{sipPeer.address}}</td>
				<td>{{sipPeer.port}}</td>
				<td style="width: 30%">{{sipPeer.userAgent}}</td>
				<td class="datetime">{{sipPeer.regDate | date:"dd.MM.yyyy HH.mm.ss"}}</td>
				<td>{{sipPeer.server}} {{sipPeer.serverId ? '(' + sipPeer.serverId + ')' : ''}}</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<app-mks-paging-short *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                      (countChange)="countChange($event)"></app-mks-paging-short>
		<div *filter-marker class="inputs-row">
			<div class="input-block">
				<text-input id="input_name_mob" name="input_name" [(ngModel)]="listSearchData.searchPattern.peerName" placeholder="Name"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt>
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.status | peerStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.status = undefined">Все</a>
							<a data-value="ONLINE" (click)="listSearchData.searchPattern.status = 'ONLINE'">{{ 'ONLINE' | peerStatus }}</a>
							<a data-value="OFFLINE" (click)="listSearchData.searchPattern.status = 'OFFLINE'">{{ 'OFFLINE' | peerStatus }}</a>
							<a data-value="DISCONNECTED" (click)="listSearchData.searchPattern.status = 'DISCONNECTED'">{{ 'DISCONNECTED' | peerStatus }}</a>
							<a data-value="NOT_CONFIGURED" (click)="listSearchData.searchPattern.status = 'NOT_CONFIGURED'">{{ 'NOT_CONFIGURED' | peerStatus }}</a>
							<a data-value="UNMONITORED" (click)="listSearchData.searchPattern.status = 'UNMONITORED'">{{ 'UNMONITORED' | peerStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block">
				<text-input id="input_ip_mob" name="input_ip" [(ngModel)]="listSearchData.searchPattern.address" placeholder="Адрес"></text-input>
			</div>
			<div class="input-block">
				<text-input id="input_useragent_mob" name="input_useragent" [(ngModel)]="listSearchData.searchPattern.userAgent" placeholder="User Agent"></text-input>
			</div>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата начала'" [(ngModel)]="listSearchData.searchPattern.fromDate"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата окончания'" [(ngModel)]="listSearchData.searchPattern.tillDate"></app-datetime-picker>
			</div>
		</div>
	</app-table-header>
	<div class="table-body">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let sipPeer of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Имя</div>
								<div class="data">{{sipPeer.peerName}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Статус</div>
								<div class="data">
									<div class="sipPeer-status" [ngClass]="sipPeer.status">
										<span class="circle"></span><span>{{sipPeer.status | peerStatus}}</span></div>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Адрес</div>
								<div class="data">{{sipPeer.address}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Порт</div>
								<div class="data">{{sipPeer.port}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">User Agent</div>
								<div class="data">{{sipPeer.userAgent}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Время</div>
								<div class="data">{{sipPeer.regDate | date:"dd.MM.yyyy HH.mm.ss"}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Сервер(ID)</div>
								<div class="data">{{sipPeer.server}} {{sipPeer.serverId ? '(' + sipPeer.serverId + ')' : ''}}</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>
