import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ListSearchData} from "../model/common/list/listSearchData";
import {Integration} from "../model/integration";
import {IntegrationKeys} from "../model/integrationKeys";

@Injectable({
    providedIn: 'root'
})
export class IntegrationsService {

    constructor(private http: HttpClient) {
    }

    getById(id: number) {
        return this.http.get<Integration>(`api/integrations/${id}`)
    }

    save(pbxid: number, integration: Integration) {
        return this.http.post(`api/pbx/${pbxid}/integrations`, integration);
    }

    delete(id: number) {
        return this.http.delete(`api/integrations/${id}`)
    }

    getListByPbx(pbxId: number, searchData: ListSearchData<Integration>) {
        return this.http.post<Integration[]>(`api/pbx/${pbxId}/integrations/list`, searchData);
    }

    generateUniqueApiKey() {
        return this.http.get<string>(`api/integrations/uniqueApiKey`)
    }

    getKeys(pbxId: number) {
        return this.http.get<IntegrationKeys>(`api/pbx/${pbxId}/integrations/keys`);
    }
}
