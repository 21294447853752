import {Component, ViewChild} from '@angular/core';
import {BaseListPageComponent} from "../baseListPage.component";
import {ListSearchData} from "../../model/common/list/listSearchData";
import {ModalService} from "../../service/modal.service";
import {StorageFilesService} from "../../service/storage-files.service";
import {Order} from "../../model/common/list/order";
import {PlayerComponent} from "../../shared/player/player.component";
import {saveAs} from 'file-saver';
import {CallRecordService} from "../../service/call-record.service";
import {CallRecordSearch} from "../../model/common/list/callRecordSearch";
import {StorageFileData} from "../../model/common/callRecord/storageFileData";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-record', templateUrl: './record.component.html', styleUrls: ['./record.component.css']
})
export class RecordComponent extends BaseListPageComponent<StorageFileData, CallRecordSearch> {
    isDataAvailable: Promise<boolean>;

    @ViewChild(PlayerComponent) player:PlayerComponent;
    public currentStorageFile: StorageFileData;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private storageFilesService: StorageFilesService, protected callRecordService: CallRecordService) {
        super(deviceService, new ListSearchData(new CallRecordSearch()));
        this.order.length = 0;
        this.order.push(new Order("_id", false));
    }

    loadPage() {
        this.callRecordService.getAllStorageFiles(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isDataAvailable = Promise.resolve(true);
        })
    }

    play(storageFileData: StorageFileData) {
        this.currentStorageFile = storageFileData;
        this.player.play("api/storageFiles/" + storageFileData.fileId + "/play");
    }

    download(record: StorageFileData) {
        this.storageFilesService.download(record.fileId).toPromise().then(value => {
            saveAs(value, record.fileName);
        })
    }

    clearSearch() {
        this.listSearchData.searchPattern = new CallRecordSearch();
    }
}
