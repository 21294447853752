<div class="title">
	<span class="over-title">АТС</span>
	{{currentPbx.name}}
</div>
<div class="base-tab ats-view__tab vertical">
	<div class="base-tab__header">
		<div class="scrollbar-inner">
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === '' || tab === 'pbx' || tab === 'ats'}" [routerLink]="[ '/ats', currentPbx.id, 'show']">АТС</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'company'}" [routerLink]="['/ats', currentPbx.id, 'show', 'company']">Компания</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'tariff'}" [routerLink]="['tariff']">Тариф и услуги</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'persons'}" [routerLink]="['persons']">Пользователи</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'acl'}" [routerLink]="['acl']">Список разрешенных IP адресов</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'external-numbers'}" [routerLink]="['external-numbers']">Внешние номера</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'internal-numbers'}" [routerLink]="['internal-numbers']">Внутренние номера</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'call-schemas'}" [routerLink]="['call-schemas']">Схемы звонков</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'integrations'}" [routerLink]="['integrations']">Интеграции</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'voice-mails'}" [routerLink]="['voice-mails']">Голосовая почта</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'hunt-groups'}" [routerLink]="['hunt-groups']">Группы</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'conferences'}" [routerLink]="['conferences']">Конференции</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'faxes'}" [routerLink]="['faxes']">Факсы</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'queues'}" [routerLink]="['queues']">Очереди</button>
			<button class="btn base-tab__btn" [ngClass]="{'active': tab === 'storage-files'}" [routerLink]="['storage-files']">Звуковые файлы</button>
		</div>
	</div>
	<div class="base-tab__body">
		<router-outlet></router-outlet>
	</div>
</div>
