import {Component, ViewEncapsulation} from '@angular/core';
import {BasePbxTabComponent} from "../basePbxTab.component";
import {Page} from "../../../model/common/list/page";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {User} from "../../../model/user";
import {ModalService} from "../../../service/modal.service";
import {ActivatedRoute} from "@angular/router";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {UserService} from "../../../service/user.service";
import {ModalState} from "../../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {ChangePassword} from "../../../model/changePassword";
import {Role} from "../../../model/role";
import {UserSearch} from "../../../model/common/list/userSearch";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-person', templateUrl: './person.component.html', styleUrls: ['./person.component.css']
})
export class PersonComponent extends BasePbxTabComponent {
    readonly personModal = "person-modal";
    readonly personDeleteModal = "person-delete-modal";

    pageLoaded: Promise<boolean>;
    page: Page<User>;
    listSearchData: ListSearchData<UserSearch>;
    currentPerson: User;
    changePassword: ChangePassword;
    readonly roles = [{key: 'SECRETARY', label: Role.SECRETARY}, {key: 'USER', label: Role.USER},
        {key: 'CLIENT', label: Role.CLIENT}]

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService,
                protected userService: UserService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit(): void {
        this.tab = 'persons';
        let userSearch = new UserSearch();
        userSearch.companyId = this.currentPbx.companyId;
        this.listSearchData = new ListSearchData(userSearch);
        this.loadPage();
    }

    public loadPage() {
        this.userService.getPage(this.listSearchData, true).subscribe(value => {
            this.page = value;
            this.page.items.forEach(user => user.name = user.surName + " " + user.firstName);
            this.pageLoaded = Promise.resolve(true);
        })
    }

    pageChanged(page: number) {
        this.listSearchData.page = page - 1;
        this.loadPage();
    }

    countChange(count: number) {
        this.listSearchData.limit = count;
        this.loadPage();
    }

    createModal() {
        this.currentPerson = new User();
        this.currentPerson.companyId = this.currentPbx.companyId;
        this.modalService.open(this.personModal, ModalState.ADD);
    }

    createEditModal(person: User) {
        this.userService.getById(person.id).subscribe(user => {
            this.currentPerson = user;
            this.modalService.open(this.personModal, ModalState.EDIT);
        })
    }

    createDeleteModal(person: User) {
        this.currentPerson = person;
        this.modalService.open(this.personDeleteModal, ModalState.DELETE);
    }

    delete(id: number) {
        if(this.currentPerson && this.currentPerson.id === id) {
            this.userService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.personDeleteModal);
            })
        }
    }

    save($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentPerson) {
            this.userService.save(this.currentPerson).subscribe(() => {
                this.loadPage();
                this.closeModal(this.personModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    selectRole($event: any) {
        this.currentPerson.role = $event.key;
    }
}
