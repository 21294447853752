export abstract class ValidationStorage {
    static codeDefinitions: Map<number, string> = new Map();

    static initialize() {
        ValidationStorage.codeDefinitions.set(500, "Ошибка сервера");

        ValidationStorage.codeDefinitions.set(3000, "Сервер используется");
        ValidationStorage.codeDefinitions.set(3001, "Сервер с таким адресом уже существует");
        ValidationStorage.codeDefinitions.set(3002, "Сервер не найден");

        ValidationStorage.codeDefinitions.set(3100, "Администратор с таким логином уже существует");
        ValidationStorage.codeDefinitions.set(3101, "АТС с таким номером уже существует");
        ValidationStorage.codeDefinitions.set(3102, "АТС не найдена");
        ValidationStorage.codeDefinitions.set(3103, "У активной АТС должен быть указан сервер");
        ValidationStorage.codeDefinitions.set(3104, "Компании не найдено");
        ValidationStorage.codeDefinitions.set(3105, "Нужно указать тип компании");

        ValidationStorage.codeDefinitions.set(3201, "Сочетание кода страны и региона уже существует");
        ValidationStorage.codeDefinitions.set(3202, "Код региона не найден");
        ValidationStorage.codeDefinitions.set(3203, "Недостаточно средств для подключения нового номера.");

        ValidationStorage.codeDefinitions.set(3122, "Внешний номер используется");
        ValidationStorage.codeDefinitions.set(3126, "Неверное значение для схемы звонка");

        ValidationStorage.codeDefinitions.set(4016, "Номер не найден");

        ValidationStorage.codeDefinitions.set(4100, "Номер принадлежит другой компании");
        ValidationStorage.codeDefinitions.set(4101, "Пир с таким номером уже существует");
        ValidationStorage.codeDefinitions.set(4103, "Номер должен состоять из двух, трех или четырех цифр");
        ValidationStorage.codeDefinitions.set(4106, "Номер может включать в себя только цифры");

        ValidationStorage.codeDefinitions.set(4114, "Email не подтвержден");
        ValidationStorage.codeDefinitions.set(4115, "Email не подтвержден");
        ValidationStorage.codeDefinitions.set(4116, "Email не заполнен");
        ValidationStorage.codeDefinitions.set(4119, "Неверный номер контракта (компания) или АТС");

        ValidationStorage.codeDefinitions.set(4200, "Номер принадлежит другой компании");
        ValidationStorage.codeDefinitions.set(4201, "Невозможно отключить последний номер");

        ValidationStorage.codeDefinitions.set(4901, "Логин уже используется в системе");
        ValidationStorage.codeDefinitions.set(4903, "Email пользователя не подтвержден");
        ValidationStorage.codeDefinitions.set(4904, "Невозможно удалить администратора");

        ValidationStorage.codeDefinitions.set(6001, "Номер не задан или некорректен");
        ValidationStorage.codeDefinitions.set(6002, "Выбранный номер уже используется");
        ValidationStorage.codeDefinitions.set(6003, "Интеграция с таким именем и ключом уже существует");

        ValidationStorage.codeDefinitions.set(8001, "Неверный API-ключ");
        ValidationStorage.codeDefinitions.set(8003, "Указанный API-ключ не обладает необходимыми правами");
    }
}
ValidationStorage.initialize();
