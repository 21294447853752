export class PersonData {
    fullName: string;
    passportNumber: string;
    fileName1: string;
    fileName2: string;
    legalFileName1: string;
    legalFileName2: string;
    companyType: string;

    scan1: any;
    scan2: any;
    legalScan1: any;
    legalScan2: any;
}
