import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {VoiceMail} from "../model/voiceMail";
import {Page} from "../model/common/list/page";
import {ListSearchData} from "../model/common/list/listSearchData";
import {VoiceMailSearch} from "../model/common/list/voiceMailSearch";

@Injectable({
  providedIn: 'root'
})
export class VoiceMailsService {

    constructor(private http: HttpClient) {
    }

    search(listSearchData: ListSearchData<VoiceMailSearch>) {
        return this.http.post<Page<VoiceMail>>(`api/voiceMails/search`, listSearchData);
    }

    getById(id: number) {
        return this.http.get<VoiceMail>(`api/voiceMails/${id}`);
    }

    delete(id: number) {
        return this.http.delete(`api/voiceMails/${id}`);
    }

    create(pbxId: number, voiceMail: VoiceMail) {
        return this.http.post<number>(`api/voiceMails/pbx/${pbxId}`, voiceMail);
    }

    update(id: number, voiceMail: VoiceMail) {
        return this.http.put<number>(`api/voiceMails/${id}`, voiceMail);
    }
}
