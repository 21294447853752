export class Server {
    id: number;
    address: string;
    name: string;
    login: string;
    password: string;
    status: string;
    callManagerId: number;
    createDatetime: string;
    loaded: boolean;
    timeZone: string;
}
