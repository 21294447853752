import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Pbx} from "../model/pbx";
import {Page} from "../model/common/list/page";
import {ListSearchData} from "../model/common/list/listSearchData";
import {PbxSearch} from "../model/common/list/pbxSearch";
import {PbxForm} from "../model/pbxForm";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AtsService {
    private currentPbxSource = new BehaviorSubject<PbxForm>(new PbxForm());
    private currentTab = new BehaviorSubject<string>("");

    currentPbx$ = this.currentPbxSource.asObservable();
    currentTab$ = this.currentTab.asObservable();

    constructor(private http: HttpClient) {
    }

    setCurrentPbx(pbx: PbxForm) {
        this.currentPbxSource.next(pbx);
    }

    setCurrentTab(tab: string) {
        this.currentTab.next(tab);
    }

    getAll(searchData: ListSearchData<PbxSearch>) {
        return this.http.post<Page<Pbx>>('api/pbx/search', searchData);
    }

    getCredentialsById(id: number) {
        return this.http.get<string>(`api/pbx/${id}/credentials`);
    }

    getBalanceById(id: number) {
        return this.http.get<number>(`api/pbx/${id}/balance`);
    }

    delete(id: number) {
        return this.http.delete('api/pbx/' + id);
    }

    getById(id: number) {
        return this.http.get<PbxForm>(`api/pbx/${id}`);
    }

    save(pbx: PbxForm, id: number) {
        return this.http.put<PbxForm>(`api/pbx/${id}`, pbx);
    }

    createMaster(pbx: Pbx) {
        return this.http.post('api/pbx/master', pbx);
    }

    create(pbx: PbxForm) {
        return this.http.post<PbxForm>('api/pbx', pbx);
    }

    block(id: number) {
        return this.http.put(`api/pbx/${id}/block`, null);
    }

    unblock(id: number) {
        return this.http.put(`api/pbx/${id}/unblock`, null);
    }
}
