import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-actions', templateUrl: './actions.component.html', styleUrls: ['./actions.component.css']
})
export class ActionsComponent implements OnInit {
    @Input() id: string = "actions";

    // By default thees params will recalculate in ngOnInit() and depend on EventEmitter existence.
    // But you can show/hide actions depending on the external conditions.
    @Input() isShowUsed = undefined;
    @Input() isEditUsed = undefined;
    @Input() isDeleteUsed = undefined;
    @Input() isNextUsed = undefined;
    @Input() isClientLoginUsed = undefined;
    @Input() isActivateUsed = undefined;
    @Input() isDeactivateUsed = undefined;
    @Input() isAcceptUsed = undefined;
    @Input() isUnselectUsed = undefined;
    @Input() isSetupUsed = undefined;
    @Input() isUpUsed = undefined;
    @Input() isDownUsed = undefined;
    @Input() isMembersUsed = undefined;
    @Input() isPlayUsed = undefined;
    @Input() isDownloadUsed = undefined;
    @Input() isPasswordUsed = undefined;

    @Output() show = new EventEmitter<string>();
    @Output() edit = new EventEmitter<string>();
    @Output() delete = new EventEmitter<string>();
    @Output() next = new EventEmitter<string>();
    @Output() clientLogin = new EventEmitter<string>();
    @Output() activate = new EventEmitter<string>();
    @Output() deactivate = new EventEmitter<string>();
    @Output() accept = new EventEmitter<string>();
    @Output() unselect = new EventEmitter<string>();
    @Output() setup = new EventEmitter<string>();
    @Output() up = new EventEmitter<string>();
    @Output() down = new EventEmitter<string>();
    @Output() members = new EventEmitter<string>();
    @Output() play = new EventEmitter<string>();
    @Output() download = new EventEmitter<string>();
    @Output() password = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit() {
        if (ActionsComponent.isUndefined(this.isShowUsed)) {
            this.isShowUsed = this.show.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isEditUsed)) {
            this.isEditUsed = this.edit.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isDeleteUsed)) {
            this.isDeleteUsed = this.delete.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isNextUsed)) {
            this.isNextUsed = this.next.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isClientLoginUsed)) {
            this.isClientLoginUsed = this.clientLogin.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isActivateUsed)) {
            this.isActivateUsed = this.activate.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isDeactivateUsed)) {
            this.isDeactivateUsed = this.deactivate.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isAcceptUsed)) {
            this.isAcceptUsed = this.accept.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isUnselectUsed)) {
            this.isUnselectUsed = this.unselect.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isSetupUsed)) {
            this.isSetupUsed = this.setup.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isUpUsed)) {
            this.isUpUsed = this.up.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isDownUsed)) {
            this.isDownUsed = this.down.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isMembersUsed)) {
            this.isMembersUsed = this.members.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isPlayUsed)) {
            this.isPlayUsed = this.play.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isDownloadUsed)) {
            this.isDownloadUsed = this.download.observers.length > 0;
        }
        if (ActionsComponent.isUndefined(this.isPasswordUsed)) {
            this.isPasswordUsed = this.password.observers.length > 0;
        }
    }

    private static isUndefined(any) {
        return typeof any === 'undefined';
    }
}
