import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {NgForm} from "@angular/forms";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ExternalNumber} from "../../../model/externalNumber";
import {NumberConfiguration} from "../../../model/numberConfiguration";
import {ModalService} from "../../../service/modal.service";
import {ExternalNumbersService} from "../../../service/external-numbers.service";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {ModalState} from "../../../shared/modal/modal.state";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-external-numbers',
    templateUrl: './external-numbers.component.html',
    styleUrls: ['./external-numbers.component.css']
})
export class ExternalNumbersComponent extends BasePbxTabComponent {
    public readonly deactivateModal = "ext-num-deactivate-modal";
    public readonly deleteModal = "ext-num-delete-modal";

    externalNumbers: ExternalNumber[];
    config: NumberConfiguration;

    regularPrice: string;
    bronzePrice: string;
    silverPrice: string;
    goldPrice: string;

    currentExternalNumber: ExternalNumber = new ExternalNumber();

    area: number = 495;
    numberType: string = null;

    freeNumbers: ExternalNumber[];
    filter: string = null;
    selectedNumber: ExternalNumber;
    page: number = 0;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private externalNumbersService: ExternalNumbersService,
                protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.getExternalNumbers();
        this.getConfig();
        this.tab = 'external-numbers';
    }

    private getConfig() {
        this.externalNumbersService.getConfig(this.currentPbx.id).subscribe(value => {
            this.config = value;
            this.regularPrice = this.config.prices.find(price => price.type === 'REGULAR').price;
            this.bronzePrice = this.config.prices.find(price => price.type === 'BRONZE').price;
            this.silverPrice = this.config.prices.find(price => price.type === 'SILVER').price;
            this.goldPrice = this.config.prices.find(price => price.type === 'GOLD').price;
        })
    }

    createDeactivateExternalNumberModal(externalNumber: ExternalNumber) {
        this.currentExternalNumber = externalNumber;
        this.modalService.open(this.deactivateModal, ModalState.DELETE);
    }

    createDeleteExternalNumberModal(externalNumber: ExternalNumber) {
        this.currentExternalNumber = externalNumber;
        this.modalService.open(this.deleteModal, ModalState.DELETE);
    }

    createExternalNumberModal() {
        this.currentExternalNumber = new ExternalNumber();
        this.externalNumbersService.freeNumbers(this.currentPbx.id, this.numberType, this.area, this.filter, this.page).subscribe(value => {
            this.freeNumbers = value;
            this.modalService.open("ext-num-modal", ModalState.ADD);
        })
    }

    showExternalNumberModal(id: number, state: string) {
        this.externalNumbersService.getExternalNumber(id).subscribe(value => {
            this.currentExternalNumber = value;
            this.modalService.open("ext-num-modal", ModalState[state]);
        })
    }

    getExternalNumbers() {
        this.externalNumbersService.getByPbxId(this.currentPbx.id).subscribe(value => {
            this.externalNumbers = value;
        })
    }

    deactivateExternalNumber(id: number) {
        if (this.currentExternalNumber && this.currentExternalNumber.id === id) {
            this.externalNumbersService.deactivate(this.currentPbx.id, id).subscribe(() => {
                this.getExternalNumbers();
                this.closeModal(this.deactivateModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        }
    }

    deleteExternalNumber(id: number) {
        if (this.currentExternalNumber && this.currentExternalNumber.id === id) {
            this.externalNumbersService.delete(id).subscribe(() => {
                this.getExternalNumbers();
                this.closeModal(this.deleteModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        }
    }

    activateExternalNumber() {
        if (this.currentExternalNumber) {
            this.externalNumbersService.activate(this.currentPbx.id, this.selectedNumber.id).subscribe(id => {
                this.getExternalNumbers();
                this.closeModal("ext-num-prompt-modal");
                this.closeModal("ext-num-modal");
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
            })
        }
    }

    selectExternalNumber($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentExternalNumber) {
            this.externalNumbersService.checkBalance(this.currentPbx.id).subscribe(value => {
                if (value) {
                    this.select(form, this.currentPbx.id);
                } else {
                    this.validationCodes = [3203];
                }
            }, (e) => {
                this.validationCodes = e.error.codes;
            })
        }
    }

    select(form: NgForm, pbxId: number) {
        let number = this.cleanNumber();
        this.externalNumbersService.select(number, pbxId).subscribe(id => {
            this.modalService.open('ext-num-prompt-modal', ModalState.CONFIRM);
        }, (e) => {
            this.validationCodes = e.error.codes;
        }, () => {
            this.validationCodes = [];
            form.onReset();
        })
    }

    unselect() {
        let number = this.cleanNumber();
        this.externalNumbersService.unselect(number).subscribe(id => {
            this.modalService.close('ext-num-prompt-modal');
        }, (e) => {
            this.validationCodes = e.error.codes;
        }, () => {
            this.validationCodes = [];
        })
    }

    private cleanNumber() {
        return this.selectedNumber.number
            .replace('+', '')
            .replace(new RegExp('\-', 'g'), '')
            .replace(' (', '')
            .replace(') ', '');
    }

    updateExternalNumber($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentExternalNumber) {
            this.externalNumbersService
                .updateExternalNumber(this.currentPbx.id, this.currentExternalNumber.id, this.currentExternalNumber)
                .subscribe(() => {
                this.getExternalNumbers();
                this.closeModal("ext-num-modal");
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    selectArea(code: number) {
        this.area = code;
        this.loadFreeNumbers();
    }

    selectType(type: string) {
        this.numberType = type;
        this.loadFreeNumbers();
    }

    private loadFreeNumbers() {
        this.externalNumbersService.freeNumbers(this.currentPbx.id, this.numberType, this.area, this.filter, this.page).subscribe(value => {
            this.freeNumbers = value;
        }, (e) => {
            this.validationCodes = e.error.codes;
        }, () => {
            this.validationCodes = [];
        });
    }

    onEnter(value: string) {
        this.filter = value;
        this.loadFreeNumbers();
    }

    selectNumber(number: ExternalNumber) {
        this.selectedNumber = number;
    }

    loadMore() {
        this.page++;
        this.loadFreeNumbers();
    }
}
