export class StorageFile {
    id: number;
    name: string = '';
    fileName: string;
    used: string;
    shared: boolean;
    fileExist: boolean;
    file: any;
    fileFake: string;
    pbxId: number;
}
