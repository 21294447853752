<div class="table-header">
	<button *ngIf="filterTemplates.length > 0" class="btn base-btn line page-filters__btn" (click)="isFilterShow = !isFilterShow">
		<svg>
			<use *ngIf="isFilterShow" xlink:href="#filter-fill"></use>
			<use *ngIf="!isFilterShow" xlink:href="#filter"></use>
		</svg>
		<span>Фильтр</span>
	</button>

	<ng-container *ngFor="let tmpl of headerTemplates" [ngTemplateOutlet]="tmpl"></ng-container>

	<div *ngFor="let tmpl of filterTemplates; last as isLast" style="width: 100%">
		<div *ngIf="isFilterShow" class="page-filters">
			<ng-container [ngTemplateOutlet]="tmpl"></ng-container>
			<button *ngIf="isSubmitFilterUsed && isLast" class="btn base-btn ok-btn" (click)="submitFilter.emit(); isFilterShow = false;">OK</button>
			<button *ngIf="isClearFilterUsed && isLast" class="btn base-btn grey" (click)="clearFilter.emit()">Очистить</button>
		</div>
	</div>
</div>