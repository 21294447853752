import {Component} from '@angular/core';
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {ModalState} from "../../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {BaseListPageComponent} from "../../baseListPage.component";
import {ModalService} from "../../../service/modal.service";
import {User} from "../../../model/user";
import {UserService} from "../../../service/user.service";
import {UserSearch} from "../../../model/common/list/userSearch";
import {Role} from "../../../model/role";
import {ChangePassword} from "../../../model/changePassword";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-user-list', //
    templateUrl: './user-list.component.html', //
    styleUrls: ['./user-list.component.css']
})
export class UserListComponent extends BaseListPageComponent<User, UserSearch> {
    readonly userModal = "user-modal";
    readonly userDeleteModal = "user-delete-modal";

    currentUser: User = new User();
    isDataAvailable = false;
    changePassword: ChangePassword;

    validationCodes: number[];

    readonly roles = [{key: 'ADMINISTRATOR', label: Role.ADMINISTRATOR}, {key: 'MANAGER', label: Role.MANAGER}, {key: 'SUPPORT', label: Role.SUPPORT}]

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private userService: UserService) {
        super(deviceService, new ListSearchData(new UserSearch()));
    }

    loadPage() {
        this.userService.getPage(this.listSearchData, false).subscribe(value => {
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    createAddUserModal() {
        this.currentUser = new User();
        this.modalService.open(this.userModal, ModalState.ADD)
    }

    createEditUserModal(id: number) {
        this.userService.getById(id).subscribe((value) => {
            this.currentUser = value;
            this.modalService.open(this.userModal, ModalState.EDIT)
        });
    }

    createShowUserModal(id: number) {
        this.userService.getById(id).subscribe((value) => {
            this.currentUser = value;
            this.modalService.open(this.userModal, ModalState.SHOW)
        });
    }

    createDeleteUserModal(server: User) {
        this.currentUser = server;
        this.modalService.open(this.userDeleteModal, ModalState.DELETE)
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.validationCodes = [];
    }

    save(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentUser) {
            this.userService.save(this.currentUser).subscribe(() => {
                this.loadPage();
                this.closeModal(this.userModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            });
        }
    }

    delete(id: number) {
        if (this.currentUser && this.currentUser.id === id) {
            this.userService.delete(this.currentUser.id).subscribe(() => {
                this.closeModal(this.userDeleteModal);
                this.loadPage();
            }, (e) => this.validationCodes = e.error.codes, () => this.validationCodes = []);
        }
    }

    selectRole($event: any) {
        this.currentUser.role = $event.key;
    }

    clearSearch() {
        this.listSearchData.searchPattern = new UserSearch();
    }
}
