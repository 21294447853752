<div class="base-tab__block active" data-tabname="company">
	<form [ngClass]="{'form-to-show': state === 'show'}" #formDataCompany="ngForm">
		<div class="col">
			<div class="input-block disabled">
				<label for="input_comp_id">ID</label>
				<div class="value" *ngIf="state === 'show'">{{company.id}}</div>
				<text-input id="input_comp_id" name="input_comp_id" [(ngModel)]="company.id" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block required">
				<label for="input_comp_name">Название</label>
				<div class="value" *ngIf="state === 'show'">{{company.name}}</div>
				<text-input id="input_comp_name" name="input_comp_name" [(ngModel)]="company.name" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_deal">Номер договора</label>
				<div class="value" *ngIf="state === 'show'">{{company.contractNumber}}</div>
				<text-input id="input_comp_deal" name="input_comp_deal" [(ngModel)]="company.contractNumber" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_contact">Контактное лицо</label>
				<div class="value" *ngIf="state === 'show'">{{company.contactPerson}}</div>
				<text-input id="input_comp_contact" name="input_comp_contact" [(ngModel)]="company.contactPerson" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_phone">Телефон для связи</label>
				<div class="value" *ngIf="state === 'show'">{{company.contactPhone}}</div>
				<text-input min="0" id="input_comp_phone" name="input_comp_phone" [(ngModel)]="company.contactPhone" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_email">E-mail</label>
				<div class="value" *ngIf="state === 'show'">{{company.email}}</div>
				<text-input min="0" id="input_email" name="input_email" [(ngModel)]="company.email" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="select-block required">
				<label for="input_comp_type">Тип клиента</label>
				<div class="value" *ngIf="state === 'show'">{{company.type | companyType}}</div>
				<dl class="select select-filter select-flag scrollable" *ngIf="state === 'edit'">
					<dt class="">
						<input type="text" id="input_comp_type" name="input_comp_type" value="{{company.type | companyType}}">
					</dt>
					<dd>
						<div class="select-options">
							<div class="scroll-cont scrollbar-inner">
								<a *ngFor="let type of ['LEGAL_PERSON', 'INDIVIDUAL']" (click)="company.type = type">{{type | companyType}}</a>
							</div>
						</div>
					</dd>
				</dl>
			</div>

			<div class="input-block">
				<label for="input_comp_k_address">Адрес для корреспонденции</label>
				<div class="value" *ngIf="state === 'show'">{{company.mailAddress}}</div>
				<text-input min="0" id="input_comp_k_address" name="input_comp_k_address" [(ngModel)]="company.mailAddress" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_tariff">Тип тарифа</label>
				<div class="value" *ngIf="state === 'show'">{{company.companyTariffType}}</div>
				<input type="number" min="0" id="input_comp_tariff" name="input_comp_tariff" [(ngModel)]="company.companyTariffType" *ngIf="state === 'edit'">
			</div>

		</div>

		<div class="col" *ngIf="company.type === 'INDIVIDUAL'">
			<div class="input-block">
				<label>Скан-копия страниц паспорта:</label>
				<div class="passport-scan-block__item">
					<div class="header">
						<div class="ttl">Страница с фотографией</div>
						<input [disabled]="state !== 'edit'" type="file" id="file1" name="file1" (change)="handleFileInput($event, '1')">
					</div>
					<div class="file" *ngIf="personData.fileName1">
						<div class="file-name">
							<span>{{personData.fileName1}}</span>
							<button *ngIf="authenticationService.isAdministrator()" class="btn action-btn" (click)="download(1, personData.fileName1)" data-tooltip="Скачать документ">
								<svg>
									<use xlink:href="#download"></use>
								</svg>
							</button>
							<button *ngIf="state === 'edit'" class="btn action-btn delete" (click)="createScanDeleteModal(1, personData.fileName1)" data-tooltip="Удалить документ">
								<svg>
									<use xlink:href="#remove"></use>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div class="passport-scan-block__item">
					<div class="header">
						<div class="ttl">Страница с пропиской</div>
						<input [disabled]="state !== 'edit'" type="file" id="file2" name="file2" (change)="handleFileInput($event, '2')">
					</div>
					<div class="file" *ngIf="personData.fileName2">
						<div class="file-name">
							<span>{{personData.fileName2}}</span>
							<button *ngIf="authenticationService.isAdministrator()" class="btn action-btn" (click)="download(2, personData.fileName2)" data-tooltip="Скачать документ">
								<svg>
									<use xlink:href="#download"></use>
								</svg>
							</button>
							<button *ngIf="state === 'edit'" class="btn action-btn delete" (click)="createScanDeleteModal(2, personData.fileName2)" data-tooltip="Удалить документ">
								<svg>
									<use xlink:href="#remove"></use>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col" *ngIf="company.type === 'LEGAL_PERSON'">
			<div class="select-block">
				<label for="input_comp_form">Правовая форма</label>
				<div class="value" *ngIf="state === 'show'">{{company.legalForm | companyForm}}</div>
				<dl class="select select-filter select-flag scrollable" *ngIf="state === 'edit'">
					<dt class="">
						<input type="text" id="input_comp_form" name="input_comp_form" value="{{company.legalForm | companyForm}}">
					</dt>
					<dd>
						<div class="select-options">
							<div class="scroll-cont scrollbar-inner">
								<a *ngFor="let form of ['OOO', 'OAO', 'PAO']" (click)="company.legalForm = form">{{form | companyForm}}</a>
							</div>
						</div>
					</dd>
				</dl>
			</div>

			<div class="input-block">
				<label for="input_comp_inn">ИНН</label>
				<div class="value" *ngIf="state === 'show'">{{company.inn}}</div>
				<text-input min="0" id="input_comp_inn" name="input_comp_inn" [(ngModel)]="company.inn" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_kpp">КПП</label>
				<div class="value" *ngIf="state === 'show'">{{company.kpp}}</div>
				<text-input min="0" id="input_comp_kpp" name="input_comp_kpp" [(ngModel)]="company.kpp" *ngIf="state === 'edit'"></text-input>
			</div>

			<div class="input-block">
				<label for="input_comp_ogrn">ОГРН</label>
				<div class="value" *ngIf="state === 'show'">{{company.ogrn}}</div>
				<text-input min="0" id="input_comp_ogrn" name="input_comp_ogrn" [(ngModel)]="company.ogrn" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_okpo">ОКПО</label>
				<div class="value" *ngIf="state === 'show'">{{company.okpo}}</div>
				<text-input min="0" id="input_comp_okpo" name="input_comp_okpo" [(ngModel)]="company.okpo" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_address">Юридический адрес</label>
				<div class="value" *ngIf="state === 'show'">{{company.legalAddress}}</div>
				<text-input min="0" id="input_comp_address" name="input_comp_address" [(ngModel)]="company.legalAddress" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_f_address">Фактический адрес</label>
				<div class="value" *ngIf="state === 'show'">{{company.actualAddress}}</div>
				<text-input min="0" id="input_comp_f_address" name="input_comp_f_address" [(ngModel)]="company.actualAddress" *ngIf="state === 'edit'"></text-input>
			</div>

			<div class="input-block">
				<label for="input_comp_account">Номер счета</label>
				<div class="value" *ngIf="state === 'show'">{{company.account}}</div>
				<text-input min="0" id="input_comp_account" name="input_comp_account" [(ngModel)]="company.account" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_bank">Название банка</label>
				<div class="value" *ngIf="state === 'show'">{{company.bankName}}</div>
				<text-input min="0" id="input_comp_bank" name="input_comp_bank" [(ngModel)]="company.bankName" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_corr">Корр. счет</label>
				<div class="value" *ngIf="state === 'show'">{{company.accountCorr}}</div>
				<text-input min="0" id="input_comp_corr" name="input_comp_corr" [(ngModel)]="company.accountCorr" *ngIf="state === 'edit'"></text-input>
			</div>
			<div class="input-block">
				<label for="input_comp_bik">БИК</label>
				<div class="value" *ngIf="state === 'show'">{{company.bik}}</div>
				<text-input min="0" id="input_comp_bik" name="input_comp_bik" [(ngModel)]="company.bik" *ngIf="state === 'edit'"></text-input>
			</div>

			<div class="input-block">
				<span>Скан-копия документов:</span>
				<div class="passport-scan-block__item">
					<div class="header">
						<div class="ttl">Свидетельство о государственной регистрации</div>
						<input [disabled]="state !== 'edit'" type="file" id="file3" name="file3" (change)="handleFileInput($event, '3')">
					</div>
					<div class="file" *ngIf="personData.legalFileName1">
						<div class="file-name">
							<span>{{personData.legalFileName1}}</span>
							<button *ngIf="authenticationService.isAdministrator()" class="btn action-btn" (click)="download(3, personData.legalFileName1)" data-tooltip="Скачать документ">
								<svg>
									<use xlink:href="#download"></use>
								</svg>
							</button>
							<button *ngIf="state === 'edit'" class="btn action-btn delete" (click)="createScanDeleteModal(3, personData.legalFileName1)" data-tooltip="Удалить документ">
								<svg>
									<use xlink:href="#remove"></use>
								</svg>
							</button>
						</div>
					</div>
				</div>
				<div class="passport-scan-block__item">
					<div class="header">
						<div class="ttl">Свидетельство о постановке на учет в налоговом органе</div>
						<input [disabled]="state !== 'edit'" type="file" id="file4" name="file4" (change)="handleFileInput($event, '4')">
					</div>
					<div class="file" *ngIf="personData.legalFileName2">
						<div class="file-name">
							<span>{{personData.legalFileName2}}</span>
							<button *ngIf="authenticationService.isAdministrator()" class="btn action-btn" (click)="download(4, personData.legalFileName2)" data-tooltip="Скачать документ">
								<svg>
									<use xlink:href="#download"></use>
								</svg>
							</button>
							<button *ngIf="state === 'edit'" class="btn action-btn delete" (click)="createScanDeleteModal(4, personData.legalFileName2)" data-tooltip="Удалить документ">
								<svg>
									<use xlink:href="#remove"></use>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
		<button class="btn base-btn btn__general" *ngIf="state === 'edit'" (click)="saveCompany($event, formDataCompany)">Сохранить</button>
		<button class="btn base-btn btn__general" *ngIf="state === 'show'" [routerLink]="[ '/ats',currentPbx.id,'edit','company']" (click)="state = 'edit'">Редактирование</button>
		<button class="btn base-btn grey" *ngIf="state === 'show'" [routerLink]="['/ats']">Отмена</button>
		<button class="btn base-btn grey" *ngIf="state === 'edit'" [routerLink]="[ '/ats',currentPbx.id,'show','company']"
		        (click)="state = 'show'; tab = 'company'; getCompany()">Отмена
		</button>
</div>

<app-modal [id]="scanDeleteModal" [title]="'Удалить документ'">
	<div body>
		<div>
			Удалить документ: <span class="b">{{scanNameToDelete}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(scanDeleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="delete(scanNoToDelete)">Удалить</button>
	</div>
</app-modal>
