export class SipPeerSearch {

    constructor() {
    }

    public peerName: string;
    public status: string;
    public address: string;
    public userAgent: string;
    public fromDate: string;
    public tillDate: string;
}
