import {ChangeDetectionStrategy, Component, DoCheck, Input, IterableDiffer, IterableDiffers} from '@angular/core';
import {ValidationStorage} from "./validation.storage";

@Component({
    selector: 'app-validation',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.css']
})
export class ValidationComponent implements DoCheck {
    @Input('codes') codes: number[] = [];

    messages: string[];

    iterableDiffer: IterableDiffer<number>;

    constructor(private iterableDiffers: IterableDiffers) {
        this.iterableDiffer = iterableDiffers.find(this.codes).create(null)
    }

    ngDoCheck(): void {
        let changes = this.iterableDiffer.diff(this.codes);
        if (changes) {
            let codeDefinitions = ValidationStorage.codeDefinitions;
            if (this.codes) {
                this.messages = this.codes.map(item => codeDefinitions.get(item));
            }
        }
    }
}
