<div class="base-tab__block active" *ngIf="isDataAvailable && isTabDataAvailable | async">
	<div class="table-container --desktop">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createAddModal()">
				Добавить голосовую почту
			</button>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table">
				<thead>
				<tr>
					<td>Внутренний номер</td>
					<td>E-mail</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let voiceMail of page.items">
					<td>{{voiceMail.number}}</td>
					<td>{{voiceMail.email}}</td>
					<td class="actions">
						<app-actions (delete)="createDeleteModal(voiceMail)"
						             (edit)="createEditModal(voiceMail.id)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createAddModal()">
				Добавить голосовую почту
			</button>
		</app-table-header>
		<div class="table-body">
			<table class="base-table">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let voiceMail of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Внутренний номер</div>
									<div class="data">{{voiceMail.number}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">E-mail</div>
									<div class="data">{{voiceMail.email}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (delete)="createDeleteModal(voiceMail)"
											             (edit)="createEditModal(voiceMail.id)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="voiceMailModal" [title]="'Создать голосовую почту'" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataVoiceMail="ngForm">
			<div class="col">
				<div class="input-block required" [ngClass]="{'invalid': formDataVoiceMail.submitted && number.invalid}">
					<label for="input_voice_mail_number">Внутренний номер</label>
					<text-input id="input_voice_mail_number" name="input_voice_mail_number" required [(ngModel)]="currentVoiceMail.number" #number="ngModel"
					            pattern="[0-9]*"></text-input>
					<div *ngIf="formDataVoiceMail.submitted && number.invalid" class="invalid">
						<div *ngIf="number.errors.required">Введите номер</div>
						<div *ngIf="pin.errors.pattern">Номер может включать в себя только цифры</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataVoiceMail.submitted && email.invalid}">
					<label for="input_voice_mail">E-mail</label>
					<input type="email" id="input_voice_mail" name="input_voice_mail" required [(ngModel)]="currentVoiceMail.email" #email="ngModel" email>
					<div *ngIf="formDataVoiceMail.submitted && email.invalid" class="invalid">
						<div *ngIf="email.errors.required">Введите e-mail</div>
						<div *ngIf="email.errors.email">Не валидный e-mail</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataVoiceMail.submitted && pin.invalid}">
					<label for="input_voice_mail_pin">Пин</label>
					<text-input id="input_voice_mail_pin" name="input_voice_mail_pin" required [(ngModel)]="currentVoiceMail.pin" #pin="ngModel" maxlength="5"
					            pattern="[0-9]*"></text-input>
					<div *ngIf="formDataVoiceMail.submitted && pin.invalid" class="invalid">
						<div *ngIf="pin.errors.required">Введите пин</div>
						<div *ngIf="pin.errors.pattern">Пин может включать в себя только цифры</div>
						<div *ngIf="pin.errors.maxlength">Длина пина не больше 5 символов</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(voiceMailModal); formDataVoiceMail.onReset()">Отмена</button>
		<button *ngIf="modalService.getState(voiceMailModal) === 'ADD'" class="btn base-btn" (click)="create($event,formDataVoiceMail)">Создать</button>
		<button *ngIf="modalService.getState(voiceMailModal) === 'EDIT'" class="btn base-btn" (click)="update($event,formDataVoiceMail)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="voiceMailDeleteModal" [title]="'Удалить голосовую почту'">
	<div body>
		Удалить голосовую почту <span class="b">{{currentVoiceMail.email}}</span>?
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(voiceMailDeleteModal)">Отмена</button>
		<button class="btn base-btn save-btn" (click)="delete(currentVoiceMail.id)">Удалить</button>
	</div>
</app-modal>
