export class CallSchema {
    id: number;
    name: string;
    type: string;
    active: boolean;
    schema: string;
    createDatetime: string;
    modifiedDateTime: string;
    login: string;
    externalNumberId: number;
    externalNumberNumber: string;
}
