import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ExternalNumber} from "../model/externalNumber";
import {ListSearchData} from "../model/common/list/listSearchData";
import {UnionExternalNumberSearch} from "../model/common/list/unionExternalNumberSearch";
import {Page} from "../model/common/list/page";
import {UnionExternalNumber} from "../model/unionExternalNumber";
import {ResolveOption} from "../model/resolveOption";
import {NumberConfiguration} from "../model/numberConfiguration";

@Injectable({
    providedIn: 'root'
})
export class ExternalNumbersService {

    constructor(private http: HttpClient) {
    }

    getByPbxId(id: number) {
        return this.http.get<ExternalNumber[]>(`api/pbx/${id}/external-numbers`);
    }

    getConfig(id: number) {
        return this.http.get<NumberConfiguration>(`api/pbx/${id}/external-numbers/configuration`);
    }

    getUnionExternalNumberList(searchData: ListSearchData<UnionExternalNumberSearch>) {
        return this.http.post<Page<UnionExternalNumber>>('api/union/externalNumber', searchData);
    }

    getExternalNumber(id: number) {
        return this.http.get<ExternalNumber>(`api/external-numbers/${id}`)
    }

    deactivate(pbxId: number, id: number) {
        return this.http.delete(`api/pbx/${pbxId}/external-numbers/${id}`)
    }

    makeFree(billingId: number) {
        return this.http.put(`api/external-numbers/${billingId}/makeFree`, null)
    }

    delete(id: number) {
        return this.http.delete(`api/external-numbers/${id}`)
    }

    activate(pbxId: number, id: number) {
        return this.http.put(`api/pbx/${pbxId}/external-numbers/${id}/activate`, null);
    }

    checkBalance(id: number) {
        return this.http.get<boolean>(`api/pbx/${id}/checkBalance`);
    }

    select(number: string, pbxId: number) {
        return this.http.get(`api/external-numbers/select/${number}/${pbxId}`);
    }

    unselect(number: string) {
        return this.http.get(`api/external-numbers/unselect/${number}`);
    }

    resolve(resolveOption: ResolveOption) {
        return this.http.put(`api/external-numbers/resolve`, resolveOption);
    }

    save(externalNumber: ExternalNumber) {
        return this.http.post(`api/external-numbers`, externalNumber);
    }

    saveToPublic(billingExternalNumberId: number) {
        return this.http.put(`api/external-numbers/toPublic/${billingExternalNumberId}`, null);
    }

    saveToPublicByPbx(pbxId: number, billingExternalNumberId: number) {
        return this.http.put(`api/external-numbers/toPublic/${pbxId}/${billingExternalNumberId}`, null);
    }

    updateExternalNumber(pbxid: number, id: number, externalNumber: ExternalNumber) {
        return this.http.put(`api/pbx/${pbxid}/external-numbers/${id}`, externalNumber);
    }

    freeNumbers(pbxid: number, type: string, area: number, filter: string, page: number) {
        let params = new HttpParams().set("area", String(area)).set("type", type).set("filter", filter).set("page", String(page));
        return this.http.get<ExternalNumber[]>(`api/pbx/${pbxid}/external-numbers/free`, {params});
    }

    withoutIntegration(pbxid: number) {
        return this.http.get<ExternalNumber[]>(`api/pbx/${pbxid}/external-numbers/withoutIntegration`);
    }
}
