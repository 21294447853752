import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "ng-pick-datetime";
import { InputTrimModule } from 'ng2-trim-directive';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthGuard} from "./auth.guard";
import {LayoutComponent} from "./layout/layout.component";
import {HeaderComponent, SidebarComponent} from "./shared";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {NgxPaginationModule} from "ngx-pagination";
import {ServerComponent} from "./pages/server/server.component";
import {AreaCodeComponent} from "./pages/areaCode/areaCode.component";
import {UnionExternalNumberComponent} from "./pages/unionExternalNumber/unionExternalNumber.component";
import {SipPeerComponent} from "./pages/sipPeer/sipPeer.component";
import {ErrorInterceptor} from "./interceptor/error.interceptor";
import {CallRecordComponent} from "./pages/callRecord/callRecord.component";
import {MatTabsModule} from "@angular/material/tabs";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NgHttpLoaderModule} from 'ng-http-loader'
import {LogoLoaderComponent} from "./shared/logo-loader/logo-loader.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {AuthModule} from "./pages/auth/auth.module";
import {PbxModule} from "./pages/pbx/pbx.module";
import {SharedModule} from "./shared/shared.module";
import {RecordComponent} from './pages/record/record.component';
import {UserListComponent} from "./pages/user/userList/user-list.component";

// @formatter:off
@NgModule({
    declarations: [//
        AppComponent, //
        LayoutComponent,//
        HeaderComponent,//
        SidebarComponent,//
        DashboardComponent,//
        ServerComponent,//
        UserListComponent,//
        AreaCodeComponent,//
        UnionExternalNumberComponent,//
        SipPeerComponent,//
        CallRecordComponent,//
        LogoLoaderComponent, //
        RecordComponent
    ], imports: [//
        AuthModule,
        PbxModule,
        SharedModule,
        BrowserModule,//
        BrowserAnimationsModule,//
        AppRoutingModule,//
        ReactiveFormsModule,//
        FormsModule,//
        HttpClientModule,//
        NgxPaginationModule,//
        OwlDateTimeModule,//
        OwlNativeDateTimeModule,//
        InputTrimModule,//
        MatTabsModule,//
        MatFormFieldModule,//
        MatAutocompleteModule,//
        MatProgressSpinnerModule,//
        NgHttpLoaderModule.forRoot(),
        NgSelectModule
//
    ], providers: [AuthGuard,//
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        }
    ], bootstrap: [AppComponent]
})
// @formatter:on
export class AppModule {
}
