<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height="30px" width="30px" viewBox="0 0 452.331 452.331"
     style="enable-background:new 0 0 452.331 452.331;" xml:space="preserve">
		<g>
			<g>
				<path d="M362.86,136.686c-49.332,0-89.487,40.139-89.487,89.479c0,26.263,11.413,49.869,29.507,66.215 H149.444c18.102-16.355,29.515-39.952,29.515-66.215c0-49.34-40.147-89.479-89.487-89.479S0,176.825,0,226.165 c0,49.048,39.692,88.975,88.642,89.431v0.049h0.829H362.86c49.34,0,89.471-40.139,89.471-89.479 C452.331,176.825,412.208,136.686,362.86,136.686z"/>
			</g>
		</g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	<g></g>
	</svg>