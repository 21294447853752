export class User {

    constructor() {
    }

    id: number;
    login: string;
    email: string;
    name: string;
    role: string;
    firstName: string = '';
    surName: string = '';
    password: string;
    companyId: number;
    companyName: string;
    createDatetime: string;

}
