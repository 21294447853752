export class Integration {
    id: number;
    name: string;
    key: string;
    getManagers: boolean;
    callNotificationUrl: string;
    getManagersUrl: string;
    authUrl: string;
    domainName: string;
    login: string;
    externalNumberId: number;
    externalNumber: string;
    getContactsUrl: string;
    autoAddLead: boolean;
    uniqueApiKey: string;
    amoClientId: string;

    pbxId: number;
}
