import {ChangeDetectionStrategy, Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'text-input',
    template: '<input type="text" trim="blur" [id]="id" [name]="name" [(ngModel)]="value" [readOnly]="readOnly" [placeholder]="placeholder" [min]="min">',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TextInputComponent), multi: true}
    ],
})

export class TextInputComponent implements ControlValueAccessor {
    @Input() id: string
    @Input() name: string
    @Input() placeholder: string = "";
    @Input() readOnly: boolean = false;
    @Input() min: string;

    private _value: string;
    get value() {
        return this._value;
    }
    set value(v) {
        if (v !== this._value) {
            this._value = v;
            this.onChange(v);
        }
    }

    constructor() {
    }

    onChange = (_) => {};
    onTouched = () => {};

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // throw new Error("Method not implemented.");
    }
}
