import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AudioService} from "../../service/audio.service";
import {StreamState} from "../../interfaces/stream-state";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-player', templateUrl: './player.component.html', styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {
    streamState: StreamState;
    url: string;
    @Output() download = new EventEmitter<string>();

    constructor(public audioService: AudioService) {
        this.audioService.getState()
            .subscribe(state => {
                this.streamState = state;
            });
    }

    ngOnInit(): void {
    }

    play(url: string) {
        this.url = url;
        this.audioService.stop();
        this.playStream();
    }


    playStream() {
        this.audioService.playStream(this.url).subscribe(value => {
            // listening for fun here
        })
    }

    playStop() {
        if (this.streamState.playing) {
            this.audioService.pause();
        } else {
            this.audioService.play();
        }
    }

    onSliderChangeEnd(change) {
        this.audioService.seekTo(change.value);
    }

    onVolumeChange(change) {
        this.audioService.volume(change.value / 100);
    }

}
