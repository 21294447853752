<div class="title">
	Список АТС
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn popup-btn__open" (click)="createAddPbxModal()">
			Создать
		</button>
		<button *table-header-marker class="btn base-btn right-margin popup-btn__open" (click)="createMasterAddPbxModal()">
			Создание АТС
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id" name="input_id" [(ngModel)]="listSearchData.searchPattern.idPbx" placeholder="ID"></text-input>
			</div>
			<div class="input-block contract">
				<text-input id="input_contract" name="input_contract" [(ngModel)]="listSearchData.searchPattern.contractNumber"
				            placeholder="Номер договора"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_phone" name="input_phone" [(ngModel)]="listSearchData.searchPattern.notificationPhone"
				            placeholder="Номер для уведомлений"></text-input>
			</div>
			<div class="input-block ext-number">
				<text-input id="input_ext-number" name="input_ext-number" [(ngModel)]="listSearchData.searchPattern.externalNumber"
				            placeholder="Внешний номер"></text-input>
			</div>
			<div class="input-block ext-number">
				<text-input id="input_reg-number" name="input_reg-number" [(ngModel)]="listSearchData.searchPattern.registrationNumber"
				            placeholder="Номер регистрации"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_name" name="input_name" [(ngModel)]="listSearchData.searchPattern.namePbx" placeholder="Название"></text-input>
			</div>
			<div class="input-block company">
				<text-input id="input_company" name="input_company" [(ngModel)]="listSearchData.searchPattern.companyName"
				            placeholder="Название компании"></text-input>
			</div>
			<dl class="select select-filter scrollable balance">
				<dt class="">
					<input type="text" name="scope" class="required" placeholder="Баланс" value="{{listSearchData.searchPattern.operation | mathComparance}}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value=">" (click)="listSearchData.searchPattern.operation = 'GT'">{{ 'GT' | mathComparance }}</a>
							<a data-value=">=" (click)="listSearchData.searchPattern.operation = 'GT_EQ'">{{ 'GT_EQ' | mathComparance }}</a>
							<a data-value="=" (click)="listSearchData.searchPattern.operation = 'EQ'">{{ 'EQ' | mathComparance }}</a>
							<a data-value="<" (click)="listSearchData.searchPattern.operation = 'LT'">{{ 'LT' | mathComparance }}</a>
							<a data-value="<=" (click)="listSearchData.searchPattern.operation = 'LT_EQ'">{{ 'LT_EQ' | mathComparance }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block balance-num">
				<text-input id="input_balance" name="input_balance" placeholder="" [(ngModel)]="listSearchData.searchPattern.balance"></text-input>
			</div>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата создания с'" [(ngModel)]="listSearchData.searchPattern.startCreateDate"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата создания по'" [(ngModel)]="listSearchData.searchPattern.endCreateDate"></app-datetime-picker>
			</div>
			<div class="input-block select-block">
				<dl class="select select-filter select-flag scrollable">
					<dt class="">
						<input type="text" placeholder="Статус" required value="{{listSearchData.searchPattern.status  | status }}">
					</dt>
					<dd>
						<div class="select-options">
							<div class="scroll-cont scrollbar-inner">
								<a data-value="" (click)="listSearchData.searchPattern.status = ''"></a>
								<a data-value="DISABLED" (click)="listSearchData.searchPattern.status = 'DISABLED'">{{ 'DISABLED' | status }}</a>
								<a data-value="ACTIVE" (click)="listSearchData.searchPattern.status = 'ACTIVE'">{{ "ACTIVE" | status }}</a>
							</div>
						</div>
					</dd>
				</dl>
			</div>
			<div class="input-block login">
				<text-input id="input_login" name="input_login" [(ngModel)]="listSearchData.searchPattern.login" placeholder="Логин"></text-input>
			</div>
			<div class="input-block email">
				<text-input id="input_email" name="input_email" [(ngModel)]="listSearchData.searchPattern.email" placeholder="E-mail пользователя"></text-input>
			</div>
			<div class="input-block user">
				<text-input id="input_user" name="input_user" [(ngModel)]="listSearchData.searchPattern.personName" placeholder="Имя пользователя"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td>
					<span>ID</span>
					<app-sort [component]="this" [property]="'id'"></app-sort>
				</td>
				<td>
					<span>Дата и время создания</span>
					<app-sort [component]="this" [property]="'createDatetime'"></app-sort>
				</td>
				<td>
					<span>Название</span>
					<app-sort [component]="this" [property]="'name'"></app-sort>
				</td>
				<td>
					<span>Статус</span>
					<app-sort [component]="this" [property]="'status'"></app-sort>
				</td>
				<td>Баланс</td>
				<td>Номер для уведомлений</td>
				<td>Компания</td>
				<td></td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let pbx of page.items">
				<td class="id">{{pbx.id}}</td>
				<td class="datetime">{{pbx.createDatetime}}</td>
				<td>{{pbx.name}}</td>
				<td class="status">
					<div class="ats-status" [ngClass]="pbx.status">
						<span class="circle"></span><span>{{ pbx.status | status }}</span></div>
				</td>
				<td>{{pbx.companyBalance}}</td>
				<td class="phone">{{pbx.notificationPhone}}</td>
				<td>{{pbx.companyName}}</td>
				<td class="actions">
					<app-actions (show)="show(pbx.id)" (edit)="edit(pbx.id)" (delete)="createDeletePbxModal(pbx)" (clientLogin)="client(pbx.id)"></app-actions>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn popup-btn__open" (click)="createAddPbxModal()">
			Создать
		</button>
		<button *table-header-marker class="btn base-btn right-margin popup-btn__open" (click)="createMasterAddPbxModal()">
			Создание АТС
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id_mob" name="input_id" [(ngModel)]="listSearchData.searchPattern.idPbx" placeholder="ID"></text-input>
			</div>
			<div class="input-block contract">
				<text-input id="input_contract_mob" name="input_contract" [(ngModel)]="listSearchData.searchPattern.contractNumber"
				            placeholder="Номер договора"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_phone_mob" name="input_phone" [(ngModel)]="listSearchData.searchPattern.notificationPhone"
				            placeholder="Номер для уведомлений"></text-input>
			</div>
			<div class="input-block ext-number">
				<text-input id="input_ext-number_mob" name="input_ext-number" [(ngModel)]="listSearchData.searchPattern.externalNumber"
				            placeholder="Внешний номер"></text-input>
			</div>
			<div class="input-block ext-number">
				<text-input id="input_reg-number_mob" name="input_reg-number" [(ngModel)]="listSearchData.searchPattern.registrationNumber"
				            placeholder="Номер регистрации"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_name_mob" name="input_name" [(ngModel)]="listSearchData.searchPattern.namePbx" placeholder="Название"></text-input>
			</div>
			<div class="input-block company">
				<text-input id="input_company_mob" name="input_company" [(ngModel)]="listSearchData.searchPattern.companyName"
				            placeholder="Название компании"></text-input>
			</div>
			<dl class="select select-filter scrollable balance">
				<dt class="">
					<input type="text" name="scope" class="required" placeholder="Баланс" value="{{listSearchData.searchPattern.operation | mathComparance}}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value=">" (click)="listSearchData.searchPattern.operation = 'GT'">{{ 'GT' | mathComparance }}</a>
							<a data-value=">=" (click)="listSearchData.searchPattern.operation = 'GT_EQ'">{{ 'GT_EQ' | mathComparance }}</a>
							<a data-value="=" (click)="listSearchData.searchPattern.operation = 'EQ'">{{ 'EQ' | mathComparance }}</a>
							<a data-value="<" (click)="listSearchData.searchPattern.operation = 'LT'">{{ 'LT' | mathComparance }}</a>
							<a data-value="<=" (click)="listSearchData.searchPattern.operation = 'LT_EQ'">{{ 'LT_EQ' | mathComparance }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block balance-num">
				<text-input id="input_balance_mob" name="input_balance" placeholder="" [(ngModel)]="listSearchData.searchPattern.balance"></text-input>
			</div>
			<div class="input-block fromDate">
				<app-datetime-picker [placeholder]="'Дата создания с'" [(ngModel)]="listSearchData.searchPattern.startCreateDate"></app-datetime-picker>
			</div>
			<div class="input-block toDate">
				<app-datetime-picker [placeholder]="'Дата создания по'" [(ngModel)]="listSearchData.searchPattern.endCreateDate"></app-datetime-picker>
			</div>
			<div class="input-block select-block">
				<dl class="select select-filter select-flag scrollable">
					<dt class="">
						<input type="text" placeholder="Статус" required value="{{listSearchData.searchPattern.status  | status }}">
					</dt>
					<dd>
						<div class="select-options">
							<div class="scroll-cont scrollbar-inner">
								<a data-value="" (click)="listSearchData.searchPattern.status = ''"></a>
								<a data-value="DISABLED" (click)="listSearchData.searchPattern.status = 'DISABLED'">{{ 'DISABLED' | status }}</a>
								<a data-value="ACTIVE" (click)="listSearchData.searchPattern.status = 'ACTIVE'">{{ "ACTIVE" | status }}</a>
							</div>
						</div>
					</dd>
				</dl>
			</div>
			<div class="input-block login">
				<text-input id="input_login_mob" name="input_login" [(ngModel)]="listSearchData.searchPattern.login" placeholder="Логин"></text-input>
			</div>
			<div class="input-block email">
				<text-input id="input_email_mob" name="input_email" [(ngModel)]="listSearchData.searchPattern.email" placeholder="E-mail пользователя"></text-input>
			</div>
			<div class="input-block user">
				<text-input id="input_user_mob" name="input_user" [(ngModel)]="listSearchData.searchPattern.personName" placeholder="Имя пользователя"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let pbx of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">ID</div>
								<div class="data">{{pbx.id}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Название</div>
								<div class="data">{{pbx.name}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Статус</div>
								<div class="data">
									<div class="ats-status" [ngClass]="pbx.status">
										<span class="circle"></span><span>{{ pbx.status | status }}</span>
									</div>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Дата и время создания</div>
								<div class="data">{{pbx.createDatetime}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Номер для уведомлений</div>
								<div class="data">{{pbx.notificationPhone}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Компания</div>
								<div class="data">{{pbx.companyName}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="data">
									<div class="action">
										<app-actions (show)="show(pbx.id)" (edit)="edit(pbx.id)" (delete)="createDeletePbxModal(pbx)"
										             (clientLogin)="client(pbx.id)"></app-actions>
									</div>
								</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<app-modal [id]="'pbx-delete-modal'">
	<div body>Вы действительно хотите удалить АТС с именем "{{currentPbx.name}}", <br/> статусом "{{ currentPbx.status | status }}" <br/> и сервером
		"{{ofForm().serverName}}"?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('pbx-delete-modal')">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentPbx.id)">Удалить</button>
	</div>
</app-modal>

<app-modal [id]="'pbx-master-modal'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataMaster="ngForm">
			<div class="col">
				<div class="input-block required" [ngClass]="{'invalid': formDataMaster.submitted && name.invalid}">
					<label for="input_master_pbx_name">Номер АТС</label>
					<text-input required id="input_master_pbx_name" name="input_master_pbx_name" [(ngModel)]="currentPbx.name" #name="ngModel"></text-input>
					<div *ngIf="formDataMaster.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataMaster.submitted && clientCredential.invalid}">
					<label for="input_master_pbx_login">Логин администратора АТС</label>
					<text-input required id="input_master_pbx_login" name="input_master_pbx_login" [(ngModel)]="ofMaster().clientCredential"
					            #clientCredential="ngModel"></text-input>
					<div *ngIf="formDataMaster.submitted && clientCredential.invalid" class="invalid">
						<div *ngIf="clientCredential.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataMaster.submitted && ccode.invalid}">
					<label for="input_master_pbx_country">Код страны</label>
					<text-input required id="input_master_pbx_country" name="input_master_pbx_country" [(ngModel)]="ofMaster().countryCode"
					            #ccode="ngModel"></text-input>
					<div *ngIf="formDataMaster.submitted && ccode.invalid" class="invalid">
						<div *ngIf="ccode.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataMaster.submitted && acode.invalid}">
					<label for="input_master_pbx_area">Код города</label>
					<text-input required id="input_master_pbx_area" name="input_master_pbx_area" [(ngModel)]="ofMaster().areaCode" #acode="ngModel"></text-input>
					<div *ngIf="formDataMaster.submitted && acode.invalid" class="invalid">
						<div *ngIf="acode.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="select-block required">
					<app-select [selectName]="'Временная зона'"
					            [id]="'input_zone'"
					            [labelKey]="'showVal'"
					            [value]="ofMaster().timeZone"
					            [required]="true"
					            [autocomplete]="true"
					            [options]="timezones"
					            (selectChange)="selectTimezone($event)"></app-select>
				</div>

			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('pbx-master-modal'); formDataMaster.onReset()">Отмена</button>
		<button *ngIf="modalService.getState('pbx-master-modal') === 'ADD'" class="btn base-btn" (click)="createMaster($event, formDataMaster)">Создать</button>
	</div>
</app-modal>

<!-- -->

<app-modal [id]="'pbx-modal'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataPbx="ngForm">
			<div *ngIf="popupDataReady || modalService.readonly('pbx-modal')" class="col">
				<div class="input-block required" [ngClass]="{'invalid': formDataPbx.submitted && name.invalid}">
					<label for="input_pbx_name">Название</label>
					<text-input id="input_pbx_name" name="input_pbx_name" required [(ngModel)]="ofForm().name" #name="ngModel"></text-input>
					<div *ngIf="formDataPbx.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataPbx.submitted && ccode.invalid}">
					<label for="input_code">Код страны</label>
					<text-input required id="input_code" name="input_code" [(ngModel)]="ofForm().countryCode" #ccode="ngModel"></text-input>
					<div *ngIf="formDataPbx.submitted && ccode.invalid" class="invalid">
						<div *ngIf="ccode.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataPbx.submitted && acode.invalid}">
					<label for="input_acode">Код города</label>
					<text-input required id="input_acode" name="input_acode" [(ngModel)]="ofForm().areaCode" #acode="ngModel"></text-input>
					<div *ngIf="formDataPbx.submitted && acode.invalid" class="invalid">
						<div *ngIf="acode.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="select-block required">
					<app-select *ngIf="timezones"
					            [selectName]="'Временная зона'"
					            [id]="'input_zone2'"
					            [labelKey]="'showVal'"
					            [value]="ofForm().timeZone"
					            [required]="true"
					            [autocomplete]="true"
					            [readOnly]="false"
					            [options]="timezones"
					            (selectChange)="selectTimezone($event)"></app-select>
				</div>

				<div class="input-block">
					<label for="input_pbx_num">Номер для уведомлений</label>
					<text-input id="input_pbx_num" name="input_pbx_num" [(ngModel)]="ofForm().notificationPhone" #zone="ngModel"></text-input>
				</div>
			</div>
			<div *ngIf="popupDataReady" class="col">

				<div class="input-block required" [ngClass]="{'invalid': formDataPbx.submitted && ncalls.invalid}">
					<label for="input_calls">Кол-во одновременных звонков</label>
					<input type="number" id="input_calls" name="input_calls" required [(ngModel)]="ofForm().callLimit" #ncalls="ngModel">
					<div *ngIf="formDataPbx.submitted && ncalls.invalid" class="invalid">
						<div *ngIf="ncalls.errors.required">Поле не может быть пустым</div>
					</div>
				</div>

				<div class="select-block required">
					<label for="pbx_status">Статус</label>
					<dl class="select select-filter select-flag scrollable">
						<dt class="">
							<input type="text" name="pbx_status" id="pbx_status" class="required" required value="{{ofForm().status  | status }}">
						</dt>
						<dd>
							<div class="select-options">
								<div class="scroll-cont scrollbar-inner">
									<a data-value="DISABLED" (click)="setStatus('DISABLED')">{{ 'DISABLED' | status }}</a>
									<a data-value="ACTIVE" (click)="setStatus('ACTIVE')">{{ "ACTIVE" | status }}</a>
								</div>
							</div>
						</dd>
					</dl>
				</div>
				<div class="select-block required">
					<label for="input_comp_type">Тип клиента</label>
					<dl class="select select-filter select-flag scrollable">
						<dt class="">
							<input type="text" id="input_comp_type" name="input_comp_type" value="{{ofForm().companyType | companyType}}">
						</dt>
						<dd>
							<div class="select-options">
								<div class="scroll-cont scrollbar-inner">
									<a *ngFor="let type of ['LEGAL_PERSON', 'INDIVIDUAL']" (click)="ofForm().companyType = type">{{type | companyType}}</a>
								</div>
							</div>
						</dd>
					</dl>
				</div>
				<div class="select-block required">
					<app-select [selectName]="'Сервер'"
					            [id]="'input_pbx_serv'"
					            [labelKey]="'name'"
					            [value]="server()"
					            [required]="true"
					            [autocomplete]="true"
					            [readOnly]="false"
					            [options]="servers"
					            (selectChange)="setServer($event)"></app-select>
				</div>

			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('pbx-modal'); formDataPbx.onReset()">Отмена</button>
		<button *ngIf="modalService.getState('pbx-modal') === 'ADD'" class="btn base-btn" (click)="create($event,formDataPbx)">Создать</button>
	</div>
</app-modal>