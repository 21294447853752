<div class="base-tab__block active" *ngIf="isDataAvailable && pageLoaded">
	<div class="table-container --desktop">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createQueueModal()">
				Добавить очередь
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по имени"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)"
			                (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table" *ngIf="page">
				<thead>
				<tr>
					<td>Название очереди</td>
					<td>Внутренний номер</td>
					<td>Стратегия обзвона</td>
					<td>Номера участников</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let queue of page.items">
					<td>{{queue.name}}</td>
					<td>{{queue.number}}</td>
					<td>{{queue.strategy | queueStrategy}}</td>
					<td>{{queue.members}}</td>
					<td>
						<app-actions (edit)="createQueueEditModal(queue)" (delete)="createQueueDeleteModal(queue)"
						             (members)="createQueueConfigModal(queue)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createQueueModal()">
				Добавить очередь
			</button>
			<div *table-header-marker class="input-block search">
				<text-input id="input_1_mob" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по имени"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)"
			                (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body">
			<table class="base-table" *ngIf="page">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let queue of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Название очереди</div>
									<div class="data">{{queue.name}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Внутренний номер</div>
									<div class="data">{{queue.number}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Стратегия обзвона</div>
									<div class="data">{{queue.strategy | queueStrategy}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Номера участников</div>
									<div class="data">{{queue.members}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (edit)="createQueueEditModal(queue)" (delete)="createQueueDeleteModal(queue)"
											             (members)="createQueueConfigModal(queue)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="queueModal">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataQueue="ngForm">
			<div class="col" *ngIf="currentQueue">
				<div class="input-block required" [ngClass]="{'invalid': formDataQueue.submitted && name.invalid}">
					<label for="name">Название очереди</label>
					<text-input id="name" name="name" placeholder="Введите название очереди" [(ngModel)]="currentQueue.name" #name="ngModel" required></text-input>
					<div *ngIf="formDataQueue.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Введите название очереди</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formDataQueue.submitted && number.invalid}">
					<label for="number">Внутренний номер</label>
					<input type="number" id="number" name="number" placeholder="Введите номер" [(ngModel)]="currentQueue.number" #number="ngModel" pattern="^\d+$"
					       required>
					<div *ngIf="formDataQueue.submitted && number.invalid" class="invalid">
						<div *ngIf="number.errors.required">Введите номер</div>
						<div *ngIf="number.errors.pattern">Номер может включать в себя только цифры.</div>
					</div>
				</div>
				<div class="select-block">
					<label>Стратегия дозвона</label>
					<ng-select name="strategies"
					           [items]="strategies"
					           bindValue="key"
					           [clearable]="false"
					           [(ngModel)]="currentQueue.strategy">
					</ng-select>
				</div>
				<div class="select-block" *ngIf="extended">
					<label>Сообщение о постановке в очередь</label>
					<ng-select name="mohs"
					           [items]="mohs"
					           bindValue="id"
					           bindLabel="name"
					           [(ngModel)]="currentQueue.announceId">
					</ng-select>
				</div>
				<div class="select-block" *ngIf="extended">
					<label>Сообщение о положении в очереди</label>
					<ng-select name="announcePositions"
					           [items]="announcePositions"
					           bindValue="key"
					           [clearable]="false"
					           [(ngModel)]="currentQueue.announcePosition">
					</ng-select>
				</div>
				<div class="select-block" *ngIf="extended">
					<label>Сообщение о времени ожидания</label>
					<ng-select name="announcePositions"
					           [items]="announceHoldTimes"
					           bindValue="key"
					           [clearable]="false"
					           [(ngModel)]="currentQueue.announceHoldTime">
					</ng-select>
				</div>
			</div>
			<div class="col" *ngIf="currentQueue">
				<div class="select-block">
					<label>Музыка ожидания</label>
					<ng-select name="mohs"
					           [items]="mohs"
					           bindValue="id"
					           bindLabel="name"
					           [(ngModel)]="currentQueue.musicClassId">
					</ng-select>
				</div>
				<div class="input-block" [ngClass]="{'invalid': formDataQueue.submitted && timeout.invalid}">
					<label for="timeout">Время ожидания в очереди</label>
					<text-input id="timeout" name="timeout" placeholder="Введите время" [(ngModel)]="currentQueue.timeout" #timeout="ngModel"
					            pattern="^\d+$"></text-input>
					<div *ngIf="formDataQueue.submitted && timeout.invalid" class="invalid">
						<div *ngIf="timeout.errors.pattern">Время может включать в себя только цифры</div>
					</div>
				</div>
				<div class="input-block" [ngClass]="{'invalid': formDataQueue.submitted && retry.invalid}">
					<label for="retry">Повтор</label>
					<text-input id="retry" name="retry" placeholder="Повтор" [(ngModel)]="currentQueue.retry" #retry="ngModel" pattern="^\d+$"></text-input>
					<div *ngIf="formDataQueue.submitted && retry.invalid" class="invalid">
						<div *ngIf="retry.errors.pattern">Повтор может включать в себя только цифры</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-link queues-create_popup--additional-btn">
			<span (click)="toggleShowExtend()">{{extended ? 'Скрыть' : 'Дополнительно'}}</span>
		</button>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(queueModal)" style="margin-left: 20px">Отмена</button>
		<button *ngIf="modalService.getState(queueModal) === 'ADD'" class="btn base-btn" (click)="create($event, formDataQueue)">Создать</button>
		<button *ngIf="modalService.getState(queueModal) === 'EDIT'" class="btn base-btn" (click)="update($event, formDataQueue)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="queueDeleteModal" [title]="'Удалить очередь'">
	<div body *ngIf="currentQueue">
		<div class="settings-delete_popup--content">
			Удалить очередь <span class="b">{{currentQueue.name}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(queueDeleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentQueue.id)">Удалить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="queueConfigModal" [additionalClass]="'queue-members_popup'" [title]="'Изменить участников очереди ' + (currentQueue ? currentQueue.name : '')">
	<div body>
		<div class="internal-numbers__table --desktop">
			<div class="table-body scrollbar-inner">
				<table class="base-table">
					<thead>
					<tr>
						<td>Имя</td>
						<td>Внутренний номер</td>
						<td>Занятость</td>
						<td></td>
					</tr>
					</thead>
					<tbody>
					<tr *ngFor="let member of listMembers">
						<td>{{member.name}}</td>
						<td>{{member.account.number}} - {{member.account.name}}</td>
						<td>{{member.penalty}}</td>
						<td>
							<app-actions (edit)="editMember(member)" (delete)="deleteMember(member)"></app-actions>

						</td>
					</tr>
					<!--  -->
					</tbody>
				</table>
			</div>
		</div>
		<button class="btn base-link queue-members_popup--add-form-btn" (click)="addMemberAction = !addMemberAction">
			+ Добавить участника
		</button>
		<form class="queue-members_popup--add-form" *ngIf="addMemberAction" #formDataMember="ngForm">
			<p class="ttl">Добавление участника</p>
			<div class="input-block">
				<label for="input_19">Имя</label>
				<text-input id="input_19" name="input_19" [(ngModel)]="member.name" #name="ngModel"></text-input>
			</div>
			<div class="select-block">
				<label>Внутренний номер</label>
				<ng-select name="numbers"
				           [items]="accounts"
				           bindValue="id"
				           bindLabel="number"
				           [(ngModel)]="member.accountId"></ng-select>
			</div>
			<div class="input-block">
				<label for="input_81">Занятость</label>
				<text-input id="input_81" name="input_81" [(ngModel)]="member.penalty"></text-input>
			</div>
			<button class="btn base-btn" (click)="addMember()">Добавить</button>
			<button class="btn base-btn grey queue-members_popup--add-form-hide" (click)="addMemberAction = false">Отмена</button>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(queueConfigModal)">Отмена</button>
		<button class="btn base-btn save-btn" (click)="saveMembers()">Сохранить</button>
	</div>
</app-modal>
