import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {CallSchema} from "../../../model/callSchema/callSchema";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {ModalState} from "../../../shared/modal/modal.state";
import {CallSchemasService} from "../../../service/call-schemas.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-call-schemas',
    templateUrl: './call-schemas.component.html',
    styleUrls: ['./call-schemas.component.css']
})
export class CallSchemasComponent extends BasePbxTabComponent {
    callSchemas: CallSchema[];
    currentCallSchema: CallSchema = new CallSchema();

    page: number = 0;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private callSchemasService: CallSchemasService,
                protected route: ActivatedRoute, private router: Router, protected routeStateService: RouteStateService, protected atsService: AtsService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.getCallSchemas();
        this.tab = 'call-schemas';
    }

    createDeleteCallSchemaModal(callSchema: CallSchema) {
        this.currentCallSchema = callSchema;
        this.modalService.open("call-schema-delete-modal", ModalState.DELETE);
    }

    showCallSchemaPage(id: number, state: string) {
        let commands = ['/ats', this.currentPbx.id, state, 'call-schemas', 'call-schema'];
        if (id) {
            commands.push(id)
        }
        this.router.navigate(commands);
    }

    getCallSchemas() {
        this.callSchemasService.getByPbxId(this.currentPbx.id).subscribe(value => {
            this.callSchemas = value;
        })
    }

    deleteCallSchema(id: number) {
        if (this.currentCallSchema && this.currentCallSchema.id === id) {
            this.callSchemasService.deleteCallSchema(id).subscribe(() => {
                this.getCallSchemas();
                this.closeModal("call-schema-delete-modal");
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        }
    }
}
