import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BasePbxTabComponent} from "../../basePbxTab.component";
import {CallSchema} from "../../../../model/callSchema/callSchema";
import {ModalService} from "../../../../service/modal.service";
import {RouteStateService} from "../../../../service/route-state.service";
import {AtsService} from "../../../../service/ats.service";
import {NgForm} from "@angular/forms";
import {CallSchemasService} from "../../../../service/call-schemas.service";
import {ExternalNumber} from "../../../../model/externalNumber";
import {ExternalNumbersService} from "../../../../service/external-numbers.service";
import {CallSchemaType} from "../../../../model/callSchema/callSchemaType";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-call-schema',
    templateUrl: './call-schema.component.html',
    styleUrls: ['./call-schema.component.css']
})
export class CallSchemaComponent extends BasePbxTabComponent {
    readonly schemaTypes = [{key: 'INBOUND', label: CallSchemaType.INBOUND}, {key: 'OUTBOUND', label: CallSchemaType.OUTBOUND},
        {key: 'AUTOCALL', label: CallSchemaType.AUTOCALL}]

    schemaId: any;
    currentCallSchema: CallSchema = new CallSchema();
    externalNumbers: ExternalNumber[];

    grabHandler;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected callSchemasService: CallSchemasService,
                protected externalNumbersService: ExternalNumbersService, private router: Router, protected routeStateService: RouteStateService,
                protected atsService: AtsService) {
        super(deviceService, modalService, atsService, route, routeStateService);
        this.route.params.subscribe(params => {
            this.schemaId = params['schemaId'];
            if (this.schemaId) {
                this.callSchemasService.getById(this.schemaId).subscribe(value => {
                    this.currentCallSchema = value;
                })
            }
        });
    }

    tabOnInit() {
        this.getExternalNumbers();
        this.tab = 'call-schemas';
    }

    getExternalNumbers() {
        this.externalNumbersService.getByPbxId(this.currentPbx.id).subscribe(value => {
            this.externalNumbers = value;
        })
    }

    createCallSchema($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentCallSchema) {
            this.callSchemasService.createCallSchema(this.currentPbx.id, this.currentCallSchema).subscribe(id => {
                this.router.navigate(['/ats', this.currentPbx.id, 'show', 'call-schemas', 'call-schema', id]);
            }, (e) => {
                this.validationCodes = e.error.codes;
            })
        }
    }

    updateCallSchema($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentCallSchema) {
            this.callSchemasService.updateCallSchema(this.currentPbx.id, this.currentCallSchema.id, this.currentCallSchema).subscribe(() => {
                this.router.navigate(['/ats', this.currentPbx.id, 'show', 'call-schemas', 'call-schema', this.currentCallSchema.id]);
            }, (e) => {
                this.validationCodes = e.error.codes;
            })
        }
    }

    static grabSchemeMove(e, startX, startY){
        e.preventDefault();
        var container = document.querySelector('.callSchema__page--scheme-section');
        const x = e.pageX;
        const y = e.pageY;
        const walkX = (x - startX) * 0.045;
        const walkY = (y - startY) * 0.045;
        container.scrollLeft = container.scrollLeft - walkX;
        container.scrollTop = container.scrollTop - walkY;
    }

    grabScheme(event){
        document.querySelector('.callSchema__page--scheme-section').classList.add('grabbing');
        this.grabHandler = function (e) {CallSchemaComponent.grabSchemeMove(e, event.pageX, event.pageY)};
        document.querySelector('.callSchema__page--scheme-section').addEventListener("mousemove", this.grabHandler);
    }
    grabSchemeOut(event){
        document.querySelector('.callSchema__page--scheme-section').classList.remove('grabbing');
        document.querySelector('.callSchema__page--scheme-section').removeEventListener("mousemove", this.grabHandler);
    }

    selectSchemaType($event: any) {
        this.currentCallSchema.type = $event.key;
    }
}
