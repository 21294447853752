import {Component} from '@angular/core';
import {ListSearchData} from "../../model/common/list/listSearchData";
import {ModalState} from "../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {BaseListPageComponent} from "../baseListPage.component";
import {ModalService} from "../../service/modal.service";
import {AreaCodeSearch} from "../../model/common/list/areaCodeSearch";
import {AreaCode} from "../../model/areaCode";
import {AreaCodeService} from "../../service/areaCode.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-areaCode', //
    templateUrl: './areaCode.component.html', //
    styleUrls: ['./areaCode.component.css']
})
export class AreaCodeComponent extends BaseListPageComponent<AreaCode, AreaCodeSearch> {
    currentAreaCode: AreaCode = new AreaCode();
    isDataAvailable = false;

    validationCodes: number[];

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private areaCodeService: AreaCodeService) {
        super(deviceService, new ListSearchData(new AreaCodeSearch()));
    }

    loadPage() {
        this.areaCodeService.getAll(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    createAddAreaCodeModal() {
        this.currentAreaCode = new AreaCode();
        this.modalService.open("area-code-modal", ModalState.ADD)
    }

    createEditAreaCodeModal(id: number) {
        this.areaCodeService.getById(id).subscribe((value) => {
            this.currentAreaCode = value;
            this.modalService.open("area-code-modal", ModalState.EDIT)
        });
    }

    createShowAreaCodeModal(id: number) {
        this.areaCodeService.getById(id).subscribe((value) => {
            this.currentAreaCode = value;
            this.modalService.open("area-code-modal", ModalState.SHOW)
        });
    }

    createDeleteAreaCodeModal(areaCode: AreaCode) {
        this.currentAreaCode = areaCode;
        this.modalService.open("area-code-delete-modal", ModalState.DELETE)
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.validationCodes = [];
    }

    save(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentAreaCode) {
            this.areaCodeService.save(this.currentAreaCode).subscribe(() => {
                this.closeModal("area-code-modal");
                this.loadPage();
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            });
        }
    }

    delete(id: number) {
        if (this.currentAreaCode && this.currentAreaCode.id === id) {
            this.areaCodeService.delete(this.currentAreaCode.id).subscribe(() => {
                this.closeModal("area-code-delete-modal");
                this.loadPage();
            }, (e) => this.validationCodes = e.error.codes, () => this.validationCodes = []);
        }
    }

    clearSearch() {
        this.listSearchData.searchPattern = new AreaCodeSearch();
    }
}
