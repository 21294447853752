<div class="title">
	<span class="over-title">Настройка</span>
	Коды регионов
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn right-margin popup-btn__open" (click)="createAddAreaCodeModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id" name="input_id" [(ngModel)]="listSearchData.searchPattern.id" placeholder="ID"></text-input>
			</div>
			<div class="input-block small">
				<text-input id="input_country_code" name="input_country_code" [(ngModel)]="listSearchData.searchPattern.countryCode"
				            placeholder="Код страны"></text-input>
			</div>
			<div class="input-block small">
				<text-input id="input_area_code" name="input_area_code" [(ngModel)]="listSearchData.searchPattern.areaCode" placeholder="Код региона"></text-input>
			</div>
			<div class="input-block full">
				<text-input id="input_description" name="input_description" [(ngModel)]="listSearchData.searchPattern.description" placeholder="Описание"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td>
					<span>ID</span>
					<app-sort [component]="this" [property]="'id'"></app-sort>
				</td>
				<td>
					<span>Код страны</span>
					<app-sort [component]="this" [property]="'countryCode'"></app-sort>
				</td>
				<td>
					<span>Код региона</span>
					<app-sort [component]="this" [property]="'areaCode'"></app-sort>
				</td>
				<td>
					<span>Описание</span>
					<app-sort [component]="this" [property]="'description'"></app-sort>
				</td>
				<td></td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let code of page.items">
				<td class="id">{{code.id}}</td>
				<td>{{code.countryCode}}</td>
				<td>{{code.code}}</td>
				<td style="width: 30%">{{code.description}}</td>
				<td class="actions">
					<app-actions (show)="createShowAreaCodeModal(code.id)" (edit)="createEditAreaCodeModal(code.id)"
					             (delete)="createDeleteAreaCodeModal(code)"></app-actions>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn popup-btn__open" (click)="createAddAreaCodeModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id_mob" name="input_id" [(ngModel)]="listSearchData.searchPattern.id" placeholder="ID"></text-input>
			</div>
			<div class="input-block small">
				<text-input id="input_country_code_mob" name="input_country_code" [(ngModel)]="listSearchData.searchPattern.countryCode"
				            placeholder="Код страны"></text-input>
			</div>
			<div class="input-block small">
				<text-input id="input_area_code_mob" name="input_area_code" [(ngModel)]="listSearchData.searchPattern.areaCode" placeholder="Код региона"></text-input>
			</div>
			<div class="input-block full">
				<text-input id="input_description_mob" name="input_description" [(ngModel)]="listSearchData.searchPattern.description"
				            placeholder="Описание"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let code of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">ID</div>
								<div class="data">{{code.id}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Код страны</div>
								<div class="data">{{code.countryCode}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Код региона</div>
								<div class="data">{{code.code}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Описание</div>
								<div class="data">{{code.description}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="data">
									<div class="action">
										<app-actions (show)="createShowAreaCodeModal(code.id)" (edit)="createEditAreaCodeModal(code.id)"
										             (delete)="createDeleteAreaCodeModal(code)"></app-actions>
									</div>
								</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<app-modal [id]="'area-code-modal'" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formdata="ngForm">
			<div class="col">
				<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && countryCode.invalid}">
					<label for="input_country_code_modal">Код страны</label>
					<input type="number" required id="input_country_code_modal" name="input_country_code_modal"
					       [readOnly]="modalService.getState('area-code-modal') === 'SHOW'"
					       [(ngModel)]="currentAreaCode.countryCode" #countryCode="ngModel">
					<div *ngIf="formdata.submitted && countryCode.invalid" class="invalid">
						<div *ngIf="countryCode.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && areaCode.invalid}">
					<label for="input_area_code_modal">Код региона</label>
					<input type="number" required id="input_area_code_modal" name="input_area_code_modal" [readOnly]="modalService.getState('area-code-modal') === 'SHOW'"
					       [(ngModel)]="currentAreaCode.code" #areaCode="ngModel">
					<div *ngIf="formdata.submitted && areaCode.invalid" class="invalid">
						<div *ngIf="areaCode.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block">
					<label for="input_description_modal">Описание</label>
					<text-input id="input_description_modal" name="input_description_modal" [readOnly]="modalService.getState('area-code-modal') === 'SHOW'"
					            [(ngModel)]="currentAreaCode.description"></text-input>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('area-code-modal'); formdata.onReset()">Отмена</button>
		<button *ngIf="modalService.getState('area-code-modal') === 'ADD'" class="btn base-btn" (click)="save($event, formdata)">Создать</button>
		<button *ngIf="modalService.getState('area-code-modal') === 'EDIT'" class="btn base-btn" (click)="save($event, formdata)">Сохранить</button>
	</div>
</app-modal>

<app-modal [id]="'area-code-delete-modal'">
	<div body>Вы действительно хотите удалить код региона ({{currentAreaCode.countryCode}}) {{currentAreaCode.code}} с ID {{currentAreaCode.id}}?</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('area-code-delete-modal')">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentAreaCode.id)">Удалить</button>
	</div>
</app-modal>
