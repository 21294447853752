<pagination-template *ngIf="condition" #p="paginationApi"
                     (pageChange)="pageChange.emit($event)">
	<div class="base-pagination">
		<div class="page-count">
			<span>показать</span>
			<dl class="select select-filter select-flag scrollable fill">
				<dt class="">
					<input type="text" name="scope" class="required" value="10" [(ngModel)]="listSearchData.limit" (change)="countChange.emit(listSearchData.limit)"  >
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="10" (click)="countChange.emit(10)" >10</a>
							<a data-value="20" (click)="countChange.emit(20)">20</a>
							<a data-value="50" (click)="countChange.emit(50)">50</a>
							<a data-value="100" (click)="countChange.emit(100)">100</a>
							<a data-value="200" (click)="countChange.emit(200)">200</a>
						</div>
					</div>
				</dd>
			</dl>
		</div>

		<div class="page-btns">
			<button class="btn prev" *ngIf="!p.isFirstPage()" (click)="p.previous()">
				Пред.
			</button>

			<button *ngFor="let page of p.pages" [class.active]="page && p.getCurrent() === page.value" (click)="p.setCurrent(page.value)" class="btn num active">
				{{ page.label }}
			</button>

			<button *ngIf="!p.isLastPage()" (click)="p.next()" class="btn next">
				След.
			</button>
		</div>

		<ul>
			<li *ngFor="let item of pageData.items | paginate: { itemsPerPage: listSearchData.limit, currentPage: listSearchData.page + 1,  totalItems: pageData.count}">
			</li>
		</ul>
	</div>

</pagination-template>
