import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Tariff} from "../model/tariff";

@Injectable({
    providedIn: 'root'
})
export class TariffService {

    constructor(private http: HttpClient) {
    }

    getByCompanyId(id: number) {
        return this.http.get<Tariff>(`api/tariffs/byCompany/${id}`);
    }
}
