import {Component} from '@angular/core';
import {ListSearchData} from "../../model/common/list/listSearchData";
import {BaseListPageComponent} from "../baseListPage.component";
import {ModalService} from "../../service/modal.service";
import {SipPeer} from "../../model/sipPeer";
import {SipPeerSearch} from "../../model/common/list/sipPeerSearch";
import {SipPeerService} from "../../service/sip-peer.service";
import {Order} from "../../model/common/list/order";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-sipPeer', //
    templateUrl: './sipPeer.component.html', //
    styleUrls: ['./sipPeer.component.css']
})
export class SipPeerComponent extends BaseListPageComponent<SipPeer, SipPeerSearch> {
    isDataAvailable = false;

    validationCodes: number[];

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private sipPeerService: SipPeerService) {
        super(deviceService, new ListSearchData(new SipPeerSearch()));
        this.order.length = 0;
        this.order.push(new Order("regDate", false));
    }

    loadPage() {
        this.sipPeerService.getAll(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    clearSearch() {
        this.listSearchData.searchPattern = new SipPeerSearch();
    }
}
