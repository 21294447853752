import {Component} from '@angular/core';
import {forkJoin} from "rxjs";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {Page} from "../../../model/common/list/page";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {AccountSearch} from "../../../model/common/list/accountSearch";
import {Account} from "../../../model/account";
import {ExternalNumber} from "../../../model/externalNumber";
import {User} from "../../../model/user";
import {StorageFile} from "../../../model/storageFile";
import {ModalService} from "../../../service/modal.service";
import {ActivatedRoute} from "@angular/router";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {InternalNumbersService} from "../../../service/internal-numbers.service";
import {ExternalNumbersService} from "../../../service/external-numbers.service";
import {UserService} from "../../../service/user.service";
import {StorageFilesService} from "../../../service/storage-files.service";
import {ModalState} from "../../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-internal-numbers', templateUrl: './internal-numbers.component.html', styleUrls: ['./internal-numbers.component.css']
})
export class InternalNumbersComponent extends BasePbxTabComponent {
    public readonly accountModal = "account-modal";
    public readonly accountDeleteModal = "account-delete-modal";
    page: Page<Account>;
    listSearchData: ListSearchData<AccountSearch>;
    isTabDataAvailable = false;
    currentAccount: Account = new Account();
    externalNumbers: ExternalNumber[];

    users: User[];

    files: StorageFile[];

    statuses: string[] = ['ACTIVE', 'DISABLED'];

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService, protected internalNumbersService: InternalNumbersService, private externalNumbersService: ExternalNumbersService, private userService: UserService, private storageFilesService: StorageFilesService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'internal-numbers';
        this.listSearchData = new ListSearchData(new AccountSearch());
        this.loadPage();
    }

    loadPage() {
        this.internalNumbersService.getAll(this.id, this.listSearchData).subscribe(value => {
            this.page = value;
            this.isTabDataAvailable = true;
        })
    }

    pageChanged($event: number) {
        this.listSearchData.page = $event - 1;
        this.loadPage();
    }

    countChange($event: number) {
        this.listSearchData.limit = $event;
        this.loadPage();
    }

    createDeleteEAccountModal(account: Account) {
        this.currentAccount = account;
        this.modalService.open(this.accountDeleteModal, ModalState.DELETE);
    }

    createEditAccountModal(id: number) {
        this.currentAccount = new Account();

        let accountResp = this.internalNumbersService.getById(id, this.currentPbx.id);
        let formattedByPbxIdResp = this.externalNumbersService.getByPbxId(this.currentPbx.id);
        let usersResp = this.userService.getByCompanyId(this.currentPbx.companyId);
        let filesResp = this.storageFilesService.getByPbxId(this.currentPbx.id);

        forkJoin([accountResp, formattedByPbxIdResp, usersResp, filesResp]).subscribe(value => {
            this.currentAccount = value[0];

            this.externalNumbers = value[1];
            let externalNumber = new ExternalNumber();
            externalNumber.number = 'По умолчанию';
            this.externalNumbers.unshift(externalNumber);

            this.users = value[2];
            this.users.unshift(new User());
            this.users.forEach(user => user.name = user.firstName + " " + user.surName);

            this.files = value[3];
            this.files.unshift(new StorageFile());

            if(this.currentAccount.ringtoneId) {
                this.currentAccount.ringtoneName = this.files.find(file => file.id == this.currentAccount.ringtoneId).name;
            }
            if(this.currentAccount.mohId) {
                this.currentAccount.mohName = this.files.find(file => file.id == this.currentAccount.mohId).name;
            }
            if(this.currentAccount.personId) {
                this.currentAccount.personName = this.users.find(user => user.id == this.currentAccount.personId).name;
            }
            if(this.currentAccount.substituteNumberId) {
                this.currentAccount.substituteNumber = this.externalNumbers.find(num => num.id == this.currentAccount.substituteNumberId).number;
            }

            this.modalService.open(this.accountModal, ModalState.EDIT)
        });
    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    createAddAccountModal() {
        let accountForCreateResp = this.internalNumbersService.getForCreate(this.currentPbx.id);
        let formattedByPbxIdResp = this.externalNumbersService.getByPbxId(this.currentPbx.id);
        let usersResp = this.userService.getByCompanyId(this.currentPbx.companyId);
        let filesResp = this.storageFilesService.getByPbxId(this.currentPbx.id);

        forkJoin([accountForCreateResp, formattedByPbxIdResp, usersResp, filesResp]).subscribe(value => {
            this.currentAccount = value[0];

            this.externalNumbers = value[1];
            let externalNumber = new ExternalNumber();
            externalNumber.number = 'По умолчанию';
            this.externalNumbers.unshift(externalNumber);

            this.users = value[2];
            this.users.unshift(new User());
            this.users.forEach(user => user.name = user.firstName + " " + user.surName);

            this.files = value[3];
            this.files.unshift(new StorageFile());

            this.modalService.open(this.accountModal, ModalState.ADD);
        });
    }

    create($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentAccount) {
            this.internalNumbersService.create(this.currentAccount, this.currentPbx.id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.accountModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    update($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentAccount) {
            this.internalNumbersService.update(this.currentAccount, this.currentAccount.id, this.currentPbx.id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.accountModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    deleteAccount(id: number) {
        if (this.currentAccount && this.currentAccount.id === id) {
            this.internalNumbersService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.accountDeleteModal);
            })
        }
    }

    selectSubstituteNumber($event: any) {
        if ($event && $event.id) {
            this.currentAccount.substituteNumberId = $event.id;
            this.currentAccount.substituteNumber = $event.number;
        }
    }

    selectPerson($event: any) {
        if ($event && $event.id) {
            this.currentAccount.personId = $event.id;
        } else {
            this.currentAccount.personId = null;
        }
    }

    selectStatus($event: any) {
        this.currentAccount.status = $event;
    }

    selectMoh($event: any) {
        if ($event && $event.id) {
            this.currentAccount.mohId = $event.id;
        } else {
            this.currentAccount.mohId = null;
        }
    }

    selectRingtone($event: any) {
        if ($event && $event.id) {
            this.currentAccount.ringtoneId = $event.id;
        } else {
            this.currentAccount.ringtoneId = null;
        }
    }
}
