export class ResolveOption {
    publicId: number;
    billingId: number;
    option: string;


    constructor(publicId: number, billingId: number, option: string) {
        this.publicId = publicId;
        this.billingId = billingId;
        this.option = option;
    }
}
