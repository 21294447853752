import {Component} from '@angular/core';
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ModalService} from "../../../service/modal.service";
import {ActivatedRoute} from "@angular/router";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {Page} from "../../../model/common/list/page";
import {Conference} from "../../../model/conference";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {ConferencePattern} from "../../../model/conferencePattern";
import {ConferenceService} from "../../../service/conference.service";
import {NgForm} from "@angular/forms";
import {ModalState} from "../../../shared/modal/modal.state";
import {DeviceDetectorService} from "ngx-device-detector";


@Component({
    selector: 'app-conferences', templateUrl: './conferences.component.html', styleUrls: ['./conferences.component.css']
})
export class ConferencesComponent extends BasePbxTabComponent {
    readonly conferenceModal = "conference-group-modal";
    readonly conferenceDeleteModal = "conference-delete-modal";

    pageLoaded: Promise<boolean>;
    page: Page<Conference>;
    listSearchData: ListSearchData<ConferencePattern>;
    currentConference: Conference;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService, protected conferenceService: ConferenceService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'conferences';
        this.listSearchData = new ListSearchData(new ConferencePattern(this.currentPbx.id));
        this.loadPage();
    }

    public loadPage() {
        this.conferenceService.getListByPbx(this.currentPbx.id, this.listSearchData).subscribe(value => {
            this.page = value;
            this.pageLoaded = Promise.resolve(true);
        })
    }

    pageChanged(page: number) {
        this.listSearchData.page = page - 1;
        this.loadPage();
    }

    countChange(count: number) {
        this.listSearchData.limit = count;
        this.loadPage();
    }

    createModal() {
        this.currentConference = new Conference();
        this.modalService.open(this.conferenceModal, ModalState.ADD);
    }

    createEditModal(conference: Conference) {
        this.conferenceService.getById(conference.id).subscribe(value => {
            this.currentConference = value;
            this.modalService.open(this.conferenceModal, ModalState.EDIT);
        });
    }

    createDeleteModal(conference: Conference) {
        this.currentConference = conference;
        this.modalService.open(this.conferenceDeleteModal, ModalState.DELETE);
    }

    delete(id: number) {
        if(this.currentConference && this.currentConference.id === id) {
            this.conferenceService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.conferenceDeleteModal);
            })
        }
    }

    create($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentConference) {
            this.conferenceService.create(this.currentPbx.id, this.currentConference).subscribe(id => {
                this.loadPage();
                this.closeModal(this.conferenceModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    update($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentConference) {
            this.conferenceService.update(this.currentConference).subscribe(id => {
                this.loadPage();
                this.closeModal(this.conferenceModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }
}
