import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Page} from "../../model/common/list/page";
import {ListSearchData} from "../../model/common/list/listSearchData";

@Component({
  selector: 'app-mks-paging',
  templateUrl: './mks-paging.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MksPagingComponent {

    @Input('page') pageData: Page<any>;
    @Input('listSearchData') listSearchData: ListSearchData<any>;
    @Input('condition') condition: boolean = true;
    @Output() pageChange: EventEmitter<number> = new EventEmitter();
    @Output() countChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

}
