import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from "./login/login.component";
import {FormsModule} from "@angular/forms";
import {routing} from "./auth.routing";

// @formatter:off
@NgModule({
    declarations: [
        LoginComponent
    ],
    exports: [
        LoginComponent
    ],
    imports: [
        CommonModule ,
        FormsModule,
        routing
    ]
})
export class AuthModule {
}
