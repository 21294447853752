<div class="title">
	<span class="over-title">Настройка</span>
	Сервера
</div>
<div class="table-container --desktop" *ngIf="!isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn right-margin popup-btn__open" (click)="createAddServerModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id" name="input_id" [(ngModel)]="listSearchData.searchPattern.id" placeholder="ID"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_phone" name="input_phone" [(ngModel)]="listSearchData.searchPattern.name" placeholder="DNS"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.status | peerStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.status = undefined">Все</a>
							<a data-value="ONLINE" (click)="listSearchData.searchPattern.status = 'ONLINE'">{{ 'ONLINE' | peerStatus }}</a>
							<a data-value="OFFLINE" (click)="listSearchData.searchPattern.status = 'OFFLINE'">{{ 'OFFLINE' | peerStatus }}</a>
							<a data-value="DISCONNECTED" (click)="listSearchData.searchPattern.status = 'DISCONNECTED'">{{ 'DISCONNECTED' | peerStatus }}</a>
							<a data-value="NOT_CONFIGURED" (click)="listSearchData.searchPattern.status = 'NOT_CONFIGURED'">{{ 'NOT_CONFIGURED' | peerStatus }}</a>
							<a data-value="UNMONITORED" (click)="listSearchData.searchPattern.status = 'UNMONITORED'">{{ 'UNMONITORED' | peerStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block company">
				<text-input id="input_cmId" name="input_cmId" [(ngModel)]="listSearchData.searchPattern.callManagerId" placeholder="Call Manager ID"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<thead>
			<tr>
				<td>
					<span>ID</span>
					<app-sort [component]="this" [property]="'id'"></app-sort>
				</td>
				<td>
					<span>DNS</span>
					<app-sort [component]="this" [property]="'name'"></app-sort>
				</td>
				<td>
					<span>Адрес</span>
					<app-sort [component]="this" [property]="'address'"></app-sort>
				</td>
				<td>
					<span>Статус</span>
					<app-sort [component]="this" [property]="'status'"></app-sort>
				</td>
				<td>Call Manager ID</td>
				<td>Дата создания</td>
				<td>Загружен</td>
				<td>Временная зона</td>
				<td></td>
			</tr>
			</thead>
			<tbody>
			<tr *ngFor="let server of page.items">
				<td class="id">{{server.id}}</td>
				<td>{{server.address}}</td>
				<td>{{server.name}}</td>
				<td class="status">
					<div class="server-status" [ngClass]="server.status">
						<span class="circle"></span><span>{{server.status | peerStatus}}</span></div>
				</td>
				<td class="id">{{server.callManagerId}}</td>
				<td class="datetime">{{server.createDatetime}}</td>
				<td class="status">
					<div class="server-status" [ngClass]="server.loaded ? 'LOADED' : 'FREE'">
						<span class="circle"></span><span>{{server.loaded ? "Да" : "Нет"}}</span></div>
				</td>
				<td>{{server.timeZone}}</td>
				<td class="actions">
					<app-actions (show)="createShowServerModal(server.id)" (edit)="createEditServerModal(server.id)"
					             (delete)="createDeleteServerModal(server)"></app-actions>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<div class="table-container --mobile" *ngIf="isMobile && isDataAvailable">
	<app-table-header (onSubmitFilter)="loadPage()" (onClearFilter)="clearSearch()">
		<button *table-header-marker class="btn base-btn popup-btn__open" (click)="createAddServerModal()">
			Создать
		</button>
		<app-mks-paging *table-header-marker [page]="page" [listSearchData]="listSearchData" (pageChange)="pageChanged($event)"
		                (countChange)="countChange($event)"></app-mks-paging>
		<div *filter-marker class="inputs-row">
			<div class="input-block id">
				<text-input id="input_id_mob" name="input_id" [(ngModel)]="listSearchData.searchPattern.id" placeholder="ID"></text-input>
			</div>
			<div class="input-block name">
				<text-input id="input_phone_mob" name="input_phone" [(ngModel)]="listSearchData.searchPattern.name" placeholder="DNS"></text-input>
			</div>
			<dl class="select select-filter scrollable">
				<dt class="">
					<input type="text" name="scope" placeholder="Статус" value="{{ listSearchData.searchPattern.status | peerStatus }}">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a data-value="ALL" (click)="listSearchData.searchPattern.status = undefined">Все</a>
							<a data-value="ONLINE" (click)="listSearchData.searchPattern.status = 'ONLINE'">{{ 'ONLINE' | peerStatus }}</a>
							<a data-value="OFFLINE" (click)="listSearchData.searchPattern.status = 'OFFLINE'">{{ 'OFFLINE' | peerStatus }}</a>
							<a data-value="DISCONNECTED" (click)="listSearchData.searchPattern.status = 'DISCONNECTED'">{{ 'DISCONNECTED' | peerStatus }}</a>
							<a data-value="NOT_CONFIGURED" (click)="listSearchData.searchPattern.status = 'NOT_CONFIGURED'">{{ 'NOT_CONFIGURED' | peerStatus }}</a>
							<a data-value="UNMONITORED" (click)="listSearchData.searchPattern.status = 'UNMONITORED'">{{ 'UNMONITORED' | peerStatus }}</a>
						</div>
					</div>
				</dd>
			</dl>
			<div class="input-block company">
				<text-input id="input_cmId_mob" name="input_cmId" [(ngModel)]="listSearchData.searchPattern.callManagerId" placeholder="Call Manager ID"></text-input>
			</div>
		</div>
	</app-table-header>
	<div class="table-body scrollbar-inner">
		<table class="base-table">
			<tbody>
			<tr>
				<td>
					<div *ngFor="let server of page.items">
						<app-mobile-slider>
							<div *mobile-slider-marker class="td-block">
								<div class="label">ID</div>
								<div class="data">{{server.id}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">DNS</div>
								<div class="data">{{server.address}}</div>
							</div>
							<div *mobile-slider-marker class="td-block">
								<div class="label">Адрес</div>
								<div class="data">{{server.name}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Статус</div>
								<div class="data">
									<div class="server-status" [ngClass]="server.status">
										<span class="circle"></span><span>{{server.status | peerStatus}}</span></div>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Call Manager ID</div>
								<div class="data">{{server.callManagerId}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Дата создания</div>
								<div class="data">{{server.createDatetime}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Загружен</div>
								<div class="data">
									<div class="server-status" [ngClass]="server.loaded ? 'LOADED' : 'FREE'">
										<span class="circle"></span><span>{{server.loaded ? "Да" : "Нет"}}</span></div>
								</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="label">Временная зона</div>
								<div class="data">{{server.timeZone}}</div>
							</div>
							<div *mobile-slider-last-marker class="td-block">
								<div class="data">
									<div class="action">
										<app-actions (show)="createShowServerModal(server.id)" (edit)="createEditServerModal(server.id)"
										             (delete)="createDeleteServerModal(server)"></app-actions>
									</div>
								</div>
							</div>
						</app-mobile-slider>
					</div>
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</div>

<app-modal [id]="'server-modal'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formdata="ngForm">
			<div class="col">
				<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && dns.invalid}">
					<label for="input_1">DNS</label>
					<text-input required id="input_1" name="input_1" [readOnly]="modalService.getState('server-modal') === 'SHOW'"
					            [(ngModel)]="currentServer.name" #dns="ngModel"></text-input>
					<div *ngIf="formdata.submitted && dns.invalid" class="invalid">
						<div *ngIf="dns.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && address.invalid}">
					<label for="input_2">Адрес</label>
					<input required id="input_2" name="input_2" [readOnly]="modalService.getState('server-modal') === 'SHOW'"
					       [(ngModel)]="currentServer.address" #address="ngModel"
					       pattern="((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))">
					<div *ngIf="formdata.submitted && address.invalid" class="invalid">
						<div *ngIf="address.errors.required">Поле не может быть пустым</div>
						<div *ngIf="address.dirty && address.errors.pattern">Не соответствует формату IPv4/IPv6</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && callManagerId.invalid}">
					<label for="input_3">Call Manager ID</label>
					<text-input required id="input_3" name="input_3" [readOnly]="modalService.getState('server-modal') === 'SHOW'"
					            [(ngModel)]="currentServer.callManagerId" #callManagerId="ngModel"></text-input>
					<div *ngIf="formdata.submitted && callManagerId.invalid" class="invalid">
						<div *ngIf="callManagerId.errors.required">Поле не может быть пустым</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="group-block">
					<div class="title">AMI</div>
					<div class="inputs">
						<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && amiLogin.invalid}">
							<label for="input_4">Логин (AMI)</label>
							<text-input required id="input_4" name="input_4" [readOnly]="modalService.getState('server-modal') === 'SHOW'"
							            [(ngModel)]="currentServer.login" #amiLogin="ngModel"></text-input>
							<div *ngIf="formdata.submitted && amiLogin.invalid" class="invalid">
								<div *ngIf="amiLogin.errors.required">Поле не может быть пустым</div>
							</div>
						</div>
						<div class="input-block required" [ngClass]="{'invalid': formdata.submitted && amiPass.invalid}">
							<label for="input_5">Пароль (AMI)</label>
							<text-input required id="input_5" name="input_5" [readOnly]="modalService.getState('server-modal') === 'SHOW'"
							            [(ngModel)]="currentServer.password" #amiPass="ngModel"></text-input>
							<div *ngIf="formdata.submitted && amiPass.invalid" class="invalid">
								<div *ngIf="amiPass.errors.required">Поле не может быть пустым</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('server-modal'); formdata.onReset()">Отмена</button>
		<button *ngIf="modalService.getState('server-modal') === 'ADD'" class="btn base-btn" (click)="save($event, formdata)">Создать</button>
		<button *ngIf="modalService.getState('server-modal') === 'EDIT'" class="btn base-btn" (click)="save($event, formdata)">Сохранить</button>
	</div>
</app-modal>

<app-modal [id]="'server-delete-modal'">
	<div body>Вы действительно хотите удалить сервер с ID {{currentServer.id}}?</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('server-delete-modal')">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentServer.id)">Удалить</button>
	</div>
</app-modal>
