<div class="base-tab__block active" data-tabname="numbers_out">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createExternalNumberModal()">
				Добавить внешний номер
			</button>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table">
				<thead>
				<tr>
					<td>ID</td>
					<td>Номер</td>
					<td>Дата и время создания</td>
					<td>Тип</td>
					<td>Статус</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let externalNumber of externalNumbers">
					<td>{{externalNumber.id}}</td>
					<td>{{externalNumber.number}}</td>
					<td>{{externalNumber.createDatetime}}</td>
					<td>
						<div class="number-type" [ngClass]="externalNumber.type.toLowerCase()">
							<span class="circle"></span><span>{{externalNumber.type | numberType }}</span></div>
					</td>
					<td>
						<div class="ats-status" [ngClass]="externalNumber.action == null ? 'ACTIVE' : externalNumber.action">
							<span class="circle"></span><span>{{externalNumber.action == null ? 'Подключен' : externalNumber.action | numberAction}}</span></div>
					</td>
					<td class="actions">
						<app-actions [isDeactivateUsed]="externalNumber.pbxId != null" (deactivate)="createDeactivateExternalNumberModal(externalNumber)"
						             (delete)="createDeleteExternalNumberModal(externalNumber)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createExternalNumberModal()">
				Добавить внешний номер
			</button>
		</app-table-header>
		<div class="table-body">
			<table class="base-table">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let externalNumber of externalNumbers">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">ID</div>
									<div class="data">{{externalNumber.id}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Номер</div>
									<div class="data">{{externalNumber.number}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Дата и время создания</div>
									<div class="data">{{externalNumber.createDatetime}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Тип</div>
									<div class="data">
										<div class="number-type" [ngClass]="externalNumber.type.toLowerCase()">
											<span class="circle"></span><span>{{externalNumber.type | numberType }}</span></div>
									</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Статус</div>
									<div class="data">
										<div class="ats-status" [ngClass]="externalNumber.action == null ? 'ACTIVE' : externalNumber.action">
											<span class="circle"></span><span>{{externalNumber.action == null ? 'Подключен' : externalNumber.action | numberAction}}</span>
										</div>
									</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions [isDeactivateUsed]="externalNumber.pbxId != null"
											             (deactivate)="createDeactivateExternalNumberModal(externalNumber)"
											             (delete)="createDeleteExternalNumberModal(externalNumber)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="deactivateModal">
	<div body>Вы действительно хотите отключить номер    {{currentExternalNumber.number}}?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(deactivateModal)">Отмена</button>
		<button class="btn base-btn red" (click)="deactivateExternalNumber(currentExternalNumber.id)">Отключить</button>
	</div>
</app-modal>

<app-modal [id]="deleteModal">
	<div body>Вы действительно хотите удалить номер    {{currentExternalNumber.number}}?
	</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(deactivateModal)">Отмена</button>
		<button class="btn base-btn red" (click)="deleteExternalNumber(currentExternalNumber.id)">Удалить</button>
	</div>
</app-modal>

<app-modal [id]="'ext-num-modal'" [additionalClass]="'select-number_popup'" [title]="'hidden'" *ngIf="config">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div head>
		<span class="title">Выбор номера:</span>
		<div class="select-number_popup--codes">
			<button class="btn base-btn small" [ngClass]="{'active': area === 495}" (click)="selectArea(495); areaName.value='Другие города'">495</button>
			<button class="btn base-btn small" [ngClass]="{'active': area === 499}" (click)="selectArea(499); areaName.value='Другие города'">499</button>
			<button class="btn base-btn small" [ngClass]="{'active': area === 800}" (click)="selectArea(800); areaName.value='Другие города'">8-800</button>
			<dl class="select select-filter select-flag scrollable fill">
				<dt class="">
					<input type="text" name="scope" #areaName class="required" value="Другие города">
				</dt>
				<dd>
					<div class="select-options">
						<div class="scroll-cont scrollbar-inner">
							<a *ngFor="let area of config.areaCodes | slice:2" (click)="selectArea(area.code)">{{area.description}}</a>
						</div>
					</div>
				</dd>
			</dl>
		</div>
	</div>
	<div body>
		<div class="base-tab">
			<div class="base-tab__header">
				<div class="scrollbar-inner">
					<button class="btn base-tab__btn" [ngClass]="{'active': numberType === null}" (click)="selectType(null)">
						<span class="title">Все</span>
					</button>
					<button class="btn base-tab__btn" [ngClass]="{'active': numberType === 'REGULAR'}" (click)="selectType('REGULAR')">
						<span class="title">Простые</span>
						<span class="price free">{{regularPrice === '0' ? 'бесплатно' : (regularPrice + ' руб.')}}</span>
					</button>
					<button class="btn base-tab__btn" [ngClass]="{'active': numberType === 'BRONZE'}" (click)="selectType('BRONZE')">
						<span class="title">Бронзовые</span>
						<span class="price bronze">{{bronzePrice === '0' ? 'бесплатно' : (bronzePrice + ' руб.')}}</span>
					</button>
					<button class="btn base-tab__btn" [ngClass]="{'active': numberType === 'SILVER'}" (click)="selectType('SILVER')">
						<span class="title">Серебряные</span>
						<span class="price silver">{{silverPrice === '0' ? 'бесплатно' : (silverPrice + ' руб.')}}</span>
					</button>
					<button class="btn base-tab__btn" [ngClass]="{'active': numberType === 'GOLD'}" (click)="selectType('GOLD')">
						<span class="title">Золотые</span>
						<span class="price gold">{{goldPrice === '0' ? 'бесплатно' : (goldPrice + ' руб.')}}</span>
					</button>
				</div>
			</div>
		</div>
		<form action="" class="select-number_popup__form" #formdata="ngForm">
			<div class="select-number_popup__filter">
				<div class="input-block">
					<label for="pp-input_1">Фильтр номеров по цифрам</label>
					<text-input id="pp-input_1" name="pp-input_1" #search (keyup.enter)="onEnter(search.value)"></text-input>
				</div>
			</div>
			<div class="radiogroup" style="max-height: 300px">
				<fieldset id="rd-group1">
					<div class="col__3" style="-webkit-columns: 3;-moz-columns: 3;columns: 3;">
						<div class="base-radio" style="display: inline-flex" *ngFor="let number of freeNumbers; let i=index">
							<input type="radio" id="{{'rd' + i}}" value="{{'value' + i}}" name="{{'rd-group' + i}}"
							       [value]="number.number"
							       [attr.checked]="selectedNumber === number ? '' : null"
							       (click)="selectNumber(number)">
							<label for="{{'rd' + i}}">
								<div class="check" [ngClass]="selectedNumber === number ? 'checked' : null"></div>
								<span><i class="{{number.type.toLowerCase()}}">{{number.number}}</i></span>
							</label>
						</div>
					</div>
				</fieldset>
			</div>
			<div class="row">
				<button class="btn base-link grey" (click)="loadMore()">Показать еще</button>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('ext-num-modal')">Отмена</button>
		<button class="btn base-btn" (click)="selectExternalNumber($event, formdata)">Выбрать номер</button>
	</div>
</app-modal>

<app-modal [id]="'ext-num-prompt-modal'" [title]="'Подтверждение подключения номера'" *ngIf="selectedNumber">
	<div body>Вы действительно хотите подключить номер "{{selectedNumber.number}}"?</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="unselect()">Отмена</button>
		<button class="btn base-btn" (click)="activateExternalNumber()">Подключить</button>
	</div>
</app-modal>
