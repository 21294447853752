import {StorageFile} from "./storageFile";
import {User} from "./user";

export class Account {
    id: number;
    number: string;
    personId: number;
    personName: string = '';
    name: string;
    callSchemaName: string;
    callSchemaNumber: string;
    peerStatus: string;
    callWidgetId: number;
    status: string;


    mohId: number;
    mohName: string = '';
    ringtoneId: number;
    ringtoneName: string = '';
    recordConversations: boolean;
    phoneTransfer: boolean;
    phoneRedirectNumber: string;
    transferTimeout: number;
    callLimit: number;
    peerName: string;
    secret: string;
    pbxServerName: string;
    fmc: string;
    supportWebrtc: boolean;
    substituteNumberId: number;
    callSchemaExternalNumber: string;

    substituteNumber: string;

    selectedRingtone: StorageFile;
    selectedMoh: StorageFile = new StorageFile();
    selectedPerson: User = new User();
}
