import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CallSchema} from "../model/callSchema/callSchema";

@Injectable({
    providedIn: 'root'
})
export class CallSchemasService {

    constructor(private http: HttpClient) {
    }

    getById(id: number) {
        return this.http.get<CallSchema>(`api/call-schemas/${id}`);
    }

    getByPbxId(id: number) {
        return this.http.get<CallSchema[]>(`api/pbx/${id}/call-schemas`);
    }

    deleteCallSchema(id: number) {
        return this.http.delete(`api/call-schemas/${id}`)
    }

    createCallSchema(id: number, callSchema: CallSchema) {
        return this.http.post<number>(`api/pbx/${id}/call-schemas`, callSchema);
    }

    updateCallSchema(pbxid: number, id: number, callSchema: CallSchema) {
        return this.http.put(`api/pbx/${pbxid}/call-schemas/${id}`, callSchema);
    }
}
