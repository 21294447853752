import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Page} from "../../model/common/list/page";
import {ListSearchData} from "../../model/common/list/listSearchData";

@Component({
  selector: 'app-mks-paging-short',
  templateUrl: './mks-paging-short.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MksPagingShortComponent {
    pageNumber: number = 1;

    @Input('page') pageData: Page<any>;
    @Input('listSearchData') listSearchData: ListSearchData<any>;
    @Output() pageChange: EventEmitter<number> = new EventEmitter();
    @Output() countChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

    previous() {
        this.pageNumber--;
        this.pageChange.emit(this.pageNumber)
    }

    next() {
        this.pageNumber++;
        this.pageChange.emit(this.pageNumber)
    }

    first() {
        this.pageNumber = 1;
        this.pageChange.emit(this.pageNumber)
    }
}
