export class Queue {
    id: number;
    number: string;
    name: string;
    type: string;
    strategy: string;
    timeout: number;
    retry: number;
    announceId: number;
    musicClassId: number;
    members: string;
    announceHoldTime: string;
    announcePosition: string;
}
