import {Component} from '@angular/core';
import {ListSearchData} from "../../model/common/list/listSearchData";
import {BaseListPageComponent} from "../baseListPage.component";
import {ModalService} from "../../service/modal.service";
import {UnionExternalNumberSearch} from "../../model/common/list/unionExternalNumberSearch";
import {ExternalNumbersService} from "../../service/external-numbers.service";
import {UnionExternalNumber} from "../../model/unionExternalNumber";
import {AtsService} from "../../service/ats.service";
import {ModalState} from "../../shared/modal/modal.state";
import {AtsComponent} from "../pbx/pbxlist/ats.component";
import {ClientAuthService} from "../../service/client-auth.service";
import {HttpClient} from "@angular/common/http";
import {ServerService} from "../../service/server.service";
import {Router} from "@angular/router";
import {Pbx} from "../../model/pbx";
import {Order} from "../../model/common/list/order";
import {ConflictType} from "../../model/conflictType";
import {Observable, of} from "rxjs";
import {ResolveOption} from "../../model/resolveOption";
import {ExternalNumber} from "../../model/externalNumber";
import {ExternalNumberType} from "../../model/externalNumberType";
import {NgForm} from "@angular/forms";
import {CompanyService} from "../../service/company.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-unionExternalNumbers', //
    templateUrl: './unionExternalNumber.component.html', //
    styleUrls: ['./unionExternalNumber.component.css']
})
export class UnionExternalNumberComponent extends BaseListPageComponent<UnionExternalNumber, UnionExternalNumberSearch> {
    public readonly numberPbxModal = "number-pbx-modal";
    public readonly numberResolveModal = "number-resolve-modal";
    public readonly promptModal = "number-prompt-modal";
    public readonly deleteModal = "number-delete-modal";
    public readonly deactivateConfirmModal = "deactivate-confirm-modal";
    public readonly acceptConfirmModal = "number-accept-modal";
    public readonly unselectConfirmModal = "number-unselect-modal";
    public readonly numberModal = "number-modal";

    currentNumber: UnionExternalNumber = new UnionExternalNumber();
    currentPbx: Pbx = null;
    currentExternalNumber: ExternalNumber = new ExternalNumber();
    isDataAvailable = false;
    isAddExternalNumberOpened = false;
    isAcceptActivationOpened = false;

    atsComponent: AtsComponent;
    resolveOptions: Observable<any[]>;
    selectedOption: any;
    promptOption: Observable<any>;

    readonly deactivate = {
        key: 'DEACTIVATE', label: "Деактивировать", text: "Операция может занять некоторое время. После подтверждения попробуйте обновить страницу"
    }

    readonly activate = {
        key: 'ACTIVATE', label: "Активировать", text: "Операция может занять некоторое время. После подтверждения попробуйте обновить страницу"
    }
    readonly publicRemove = {
        key: 'PUBLIC_REMOVE', label: "Удалить из системы", text: "Удаление из системы не изменяет состояния биллинга!"
    }

    readonly copyFromPublic = {
        key: 'COPY_FROM_PUBLIC', label: "Скопировать из системы в биллинг"
    }
    readonly copyFromBilling = {
        key: 'COPY_FROM_BILLING', label: "Скопировать из биллинга в систему", text: "Операция скопирует данные и не изменит состояния биллинга!"
    }

    readonly clean = {
        key: 'CLEAN', label: "Убрать лишние данные биллинга"
    }

    validationCodes: number[];

    readonly types = [{key: 'REGULAR', label: ExternalNumberType.REGULAR}, {key: 'BRONZE', label: ExternalNumberType.BRONZE}, {key: 'SILVER', label: ExternalNumberType.SILVER}, {key: 'GOLD', label: ExternalNumberType.GOLD}]

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private companyService: CompanyService, private externalNumbersService: ExternalNumbersService, private atsService: AtsService,
                private clientAuthService: ClientAuthService, private http: HttpClient, private serverService: ServerService, private router: Router) {
        super(deviceService, new ListSearchData(new UnionExternalNumberSearch()));
        this.atsComponent = new AtsComponent(deviceService, modalService, atsService, clientAuthService, http, serverService, router);
        this.order = [new Order("billingId", true)];
        this.listSearchData.orders = this.order;
    }

    loadPage() {
        this.externalNumbersService.getUnionExternalNumberList(this.listSearchData).subscribe(value => {
            value.items.forEach(item => {
                item.isConflict = false;
                if (item.outer) {
                    item.status = "BUSY";
                } else {
                    if (item.status === "FREE" || item.status === "SELECTED") {
                        if (item.publicId) {
                            item.isConflict = true;
                        }
                    } else {
                        item.isConflict = !(item.publicPbxId === item.billingPbxId && item.publicType === item.billingType)
                    }
                }
            });
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    deactivateNumber(number: UnionExternalNumber) {
        if (number.publicId) {
            this.externalNumbersService.deactivate(number.billingPbxId, number.publicId).subscribe(() => {
                this.loadPage();
                this.closeModal(this.deactivateConfirmModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        } else if (number.billingId) {
            this.externalNumbersService.makeFree(number.billingId).subscribe(() => {
                this.loadPage();
                this.closeModal(this.deactivateConfirmModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        }
    }

    acceptNumber(number: UnionExternalNumber) {
        if (number.status === 'SELECTED') {
            this.externalNumbersService.saveToPublic(number.billingId).subscribe(() => {
                this.loadPage();
                this.closeModal(this.acceptConfirmModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        } else if (number.status === 'FREE') {
            this.isAcceptActivationOpened = true;
            this.createActivateNumberModal(number);
        }
    }

    deleteNumber(number: UnionExternalNumber) {
        this.externalNumbersService.delete(number.publicId ? number.publicId : number.billingId).subscribe(() => {
            this.loadPage();
            this.closeModal(this.deleteModal);
        }, (e) => {
            this.validationCodes = e.error.codes;
        });
    }

    unselect(number: UnionExternalNumber) {
        this.externalNumbersService.unselect(number.number).subscribe(value => {
            number.status = 'FREE';
            this.closeModal(this.unselectConfirmModal);
        }, (e) => {
            this.validationCodes = e.error.codes;
        });
    }

    activationProcess(number: UnionExternalNumber, pbxId: number) {
        if (this.isAcceptActivationOpened) {
            this.externalNumbersService.saveToPublicByPbx(pbxId, number.billingId).subscribe(() => {
                this.loadPage();
                this.closeModal(this.numberPbxModal)
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        } else {
            this.externalNumbersService.select(number.number, pbxId).subscribe(value => {
                number.status = 'SELECTED';
                this.externalNumbersService.activate(pbxId, number.billingId).subscribe(() => {
                    this.loadPage();
                    this.closeModal(this.numberPbxModal)
                }, (e) => {
                    this.validationCodes = e.error.codes;
                    this.unselect(number);
                });
            }, (e) => {
                this.validationCodes = e.error.codes;
            });
        }
    }

    createActivateNumberModal(unionExternalNumber: UnionExternalNumber) {
        this.currentNumber = unionExternalNumber;
        this.atsComponent.loadPageAvailableForBilling();
        this.modalService.open(this.numberPbxModal, ModalState.CONFIRM)
        if (this.isAcceptActivationOpened) {
            this.closeModal(this.acceptConfirmModal);
        }
    }

    createDeactivateNumberModal(unionExternalNumber: UnionExternalNumber) {
        this.currentNumber = unionExternalNumber;
        this.modalService.open(this.deactivateConfirmModal, ModalState.CONFIRM)
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.validationCodes = [];
        if (this.numberModal === id) {
            this.isAddExternalNumberOpened = false;
        }
        if (this.isAddExternalNumberOpened) {
            this.modalService.open(this.numberModal, ModalState.ADD)
        } else {
            this.currentPbx = null;
        }
    }

    get conflictType() {
        return ConflictType;
    }

    setup(number: UnionExternalNumber) {
        this.currentNumber = number;

        let resolveOptionsArray = [];
        if (this.currentNumber.billingId && !this.currentNumber.publicId) {
            this.currentNumber.conflictType = ConflictType.NUMBER_PUBLIC_MISS;
            resolveOptionsArray.push(this.activate);
            resolveOptionsArray.push(this.copyFromBilling);
            resolveOptionsArray.push(this.clean);
        } else if (!this.currentNumber.billingId && this.currentNumber.publicId) {
            this.currentNumber.conflictType = ConflictType.NUMBER_BILLING_MISS;
            resolveOptionsArray.push(this.deactivate);
            resolveOptionsArray.push(this.publicRemove);
            resolveOptionsArray.push(this.copyFromPublic);
        } else if (this.currentNumber.billingId && this.currentNumber.publicId) {
            if (this.currentNumber.billingType !== this.currentNumber.publicType) {
                this.currentNumber.conflictType = ConflictType.NUMBER_TYPE_MISSMATCH;
                resolveOptionsArray.push(this.copyFromPublic);
                resolveOptionsArray.push(this.copyFromBilling);
            } else if (this.currentNumber.billingPbxId && !this.currentNumber.publicPbxId) {
                this.currentNumber.conflictType = ConflictType.NUMBER_PBX_MISS_IN_PUBLIC;
                resolveOptionsArray.push(this.activate);
                resolveOptionsArray.push(this.copyFromBilling);
                resolveOptionsArray.push(this.clean);
            } else if (!this.currentNumber.billingPbxId && this.currentNumber.publicPbxId) {
                this.currentNumber.conflictType = ConflictType.NUMBER_PBX_MISS_IN_BILLING;
                resolveOptionsArray.push(this.deactivate);
                resolveOptionsArray.push(this.publicRemove);
                resolveOptionsArray.push(this.copyFromPublic);
            } else if (this.currentNumber.billingPbxId !== this.currentNumber.publicPbxId) {
                this.currentNumber.conflictType = ConflictType.NUMBER_PBX_MISSMATCH;
                resolveOptionsArray.push(this.deactivate);
            } else {
                resolveOptionsArray.push(this.copyFromPublic);
                resolveOptionsArray.push(this.copyFromBilling);
            }

        }
        this.resolveOptions = of(resolveOptionsArray);
        this.selectedOption = resolveOptionsArray[0];

        this.modalService.open(this.numberResolveModal, ModalState.CONFIRM)
    }

    tryResolve(currentNumber: UnionExternalNumber, selectedOption: any) {
        if (selectedOption.text) {
            this.promptOption = of(selectedOption.text);
            this.modalService.open(this.promptModal, ModalState.CONFIRM);
        } else {
            this.resolve(currentNumber, selectedOption, [this.numberResolveModal]);
        }
    }

    resolve(currentNumber: UnionExternalNumber, selectedOption: any, modalsToClose: string[]) {
        let resolve = new ResolveOption(currentNumber.publicId, currentNumber.billingId, selectedOption.key);
        this.externalNumbersService.resolve(resolve).subscribe(() => {
            this.loadPage();
            modalsToClose.forEach(value => this.closeModal(value));
        }, (e) => {
            this.validationCodes = e.error.codes;
        });
    }

    deleteNumberModal(number: UnionExternalNumber) {
        this.currentNumber = number;
        this.modalService.open(this.deleteModal, ModalState.DELETE)
    }

    acceptNumberModal(number: UnionExternalNumber) {
        this.currentNumber = number;
        this.modalService.open(this.acceptConfirmModal, ModalState.CONFIRM)
    }

    unselectNumberModal(number: UnionExternalNumber) {
        this.currentNumber = number;
        this.modalService.open(this.unselectConfirmModal, ModalState.CONFIRM)
    }

    selectPbxInModal(pbx: Pbx) {
        this.currentPbx = pbx;
        if (this.isAddExternalNumberOpened) {
            this.closeModal(this.numberPbxModal);
        }
    }

    createAddNumberModal() {
        this.currentExternalNumber = new ExternalNumber();
        this.modalService.open(this.numberModal, ModalState.ADD);
        this.isAddExternalNumberOpened = true;
    }

    selectType($event: any) {
        this.currentExternalNumber.type = $event.key;
    }

    save(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentExternalNumber && this.currentPbx) {
            this.currentExternalNumber.pbxId = this.currentPbx.id;
            this.externalNumbersService.save(this.currentExternalNumber).subscribe(() => {
                this.loadPage();
                this.closeModal(this.numberModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            });
        }
    }

    createSelectPbxForSaveExternalNumberModal() {
        this.atsComponent.loadPageAvailableForBilling();
        this.modalService.open(this.numberPbxModal, ModalState.CONFIRM)
        this.modalService.close(this.numberModal)
    }

    clearSearch() {
        this.listSearchData.searchPattern = new UnionExternalNumberSearch();
    }
}
