export class Page<T> {
    constructor(count: number, items: T[]) {
        this._count = count;
        this._items = items;
    }
    private _count: number;
    private _items: T[];


    get count(): number {
        return this._count;
    }

    set count(value: number) {
        this._count = value;
    }

    get items(): T[] {
        return this._items;
    }

    set items(value: T[]) {
        this._items = value;
    }
}
