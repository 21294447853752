import {Pipe, PipeTransform} from "@angular/core";
import {MathComparance} from "../../model/mathComparance";

@Pipe({name: 'mathComparance'})
export class MathComparancePipe implements PipeTransform {
    transform(value: any) {
        return MathComparance[value];
    }

}
