import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusPipe} from "./pipes/StatusPipe";
import {TableHeaderComponent, FilterMarker, TableHeaderMarker} from "./table-header/table-header.component";
import {ValidationComponent} from "./validation/validation.component";
import {MksPagingComponent} from "./paging/mks-paging.component";
import {MksPagingShortComponent} from "./paging-short/mks-paging-short.component";
import {DateTimePickerComponent} from "./datetimepicker/date-time-picker.component";
import {SortComponent} from "./sort/sort.component";
import {ModalComponent} from "./modal/modal.component";
import {SelectComponent} from "./select/select.component";
import {CompanyTypePipe} from "./pipes/companyTypePipe";
import {CompanyFormPipe} from "./pipes/companyFormPipe";
import {ActionsComponent} from "./actions/actions.component";
import {NumberType} from "./pipes/numberType";
import {NumberAction} from "./pipes/numberAction";
import {NumberStatus} from "./pipes/numberStatusPipe";
import {SchemaType} from "./pipes/schemaType";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxPaginationModule} from "ngx-pagination";
import {OwlDateTimeModule, OwlNativeDateTimeModule} from "ng-pick-datetime";
import {MatTabsModule} from "@angular/material/tabs";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {StrategyPipe} from "./pipes/strategyPipe";
import {NgSelectModule} from "@ng-select/ng-select";
import {QueueStrategyPipe} from "./pipes/queueStrategyPipe";
import {FileTypePipe} from "./pipes/fileTypePipe";
import {FileTypeDirective} from './validation/file-type.directive';
import {PlayerComponent} from './player/player.component';
import {MatSliderModule} from "@angular/material/slider";
import {RolePipe} from "./pipes/rolePipe";
import {TariffServiceStatusPipe} from "./pipes/tariffServiceStatusPipe";
import {TextInputComponent} from "./text-input/text-input.component";
import {InputTrimModule} from "ng2-trim-directive";
import {PeerStatusPipe} from "./pipes/peerStatusPipe";
import {InternalNumberStatusPipe} from "./pipes/internalNumberStatusPipe";
import {CallStatusPipe} from "./pipes/callStatusPipe";
import {CallTypePipe} from "./pipes/callTypePipe";
import {MobileSliderComponent, MobileSliderLastMarker, MobileSliderMarker} from "./mobile-slider/mobile-slider.component";
import {MathComparancePipe} from "./pipes/MathComparancePipe";


@NgModule({
    declarations: [//
        StatusPipe, //
        TableHeaderComponent,//
        MobileSliderComponent,//
        ValidationComponent,//
        MksPagingComponent,//
        MksPagingShortComponent,//
        DateTimePickerComponent,//
        SortComponent,//
        ModalComponent,//
        SelectComponent,//
        CompanyTypePipe,//
        CompanyFormPipe,//
        ActionsComponent,//
        NumberType,//
        NumberAction,//
        NumberStatus,//
        MathComparancePipe,//
        TariffServiceStatusPipe,//
        SchemaType,//
        FilterMarker,//
        TableHeaderMarker,//
        MobileSliderMarker,//
        MobileSliderLastMarker,//
        StrategyPipe,//
        CallTypePipe,//
        PeerStatusPipe,//
        CallStatusPipe,//
        InternalNumberStatusPipe,//
        QueueStrategyPipe,//
        FileTypePipe, //
        FileTypeDirective, //
        PlayerComponent, //
        RolePipe, //
        TextInputComponent //
    ],//
    imports: [//
        CommonModule, //
        ReactiveFormsModule,//
        FormsModule,//
        NgxPaginationModule,//
        OwlDateTimeModule,//
        OwlNativeDateTimeModule,//
        MatTabsModule,//
        MatFormFieldModule,//
        MatAutocompleteModule,//
        MatProgressSpinnerModule,//
        NgSelectModule, //
        MatSliderModule, //
        InputTrimModule],//
    exports: [//
        TableHeaderComponent,//
        MobileSliderComponent,//
        StatusPipe, //
        ValidationComponent,//
        MksPagingComponent,//
        MksPagingShortComponent,//
        DateTimePickerComponent,//
        SortComponent,//
        ModalComponent,//
        SelectComponent,//
        CompanyTypePipe,//
        CompanyFormPipe,//
        ActionsComponent,//
        NumberType,//
        NumberAction,//
        NumberStatus,//
        MathComparancePipe,//
        TariffServiceStatusPipe,//
        SchemaType,//
        FilterMarker,//
        TableHeaderMarker,//
        MobileSliderMarker,//
        MobileSliderLastMarker,//
        StrategyPipe,//
        CallTypePipe,//
        PeerStatusPipe,//
        CallStatusPipe,//
        InternalNumberStatusPipe,//
        QueueStrategyPipe,//
        FileTypePipe, //
        FileTypeDirective, //
        PlayerComponent, //
        RolePipe, //
        TextInputComponent //
    ]
})
export class SharedModule {
}
