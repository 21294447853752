export class UnionExternalNumberSearch {

    constructor() {
    }

    public numberId: number;
    public pbxId: number;
    public pbxName: string;
    public number: string;
    public type: string;
    public status: string;
    public conflict: boolean;
}
