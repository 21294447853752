import {Component} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {Server} from "../../../model/server";
import {ServerService} from "../../../service/server.service";
import {AtsService} from "../../../service/ats.service";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {ModalState} from "../../../shared/modal/modal.state";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-pbx-details', //
    templateUrl: './pbx-details.component.html', //
    styleUrls: ['./pbx-details.component.css'] //
})
export class PbxDetailsComponent extends BasePbxTabComponent {
    readonly pbxBlockModal = "pbx-block-modal";
    readonly pbxUnblockModal = "pbx-unblock-modal";

    servers: Server[];

    constructor(public deviceService: DeviceDetectorService, protected route: ActivatedRoute, protected serverService: ServerService, protected atsService: AtsService, private router: Router,
                public modalService: ModalService, protected routeStateService: RouteStateService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'pbx';
        this.serverService.getList().subscribe(value => {
            this.servers = value;
        });
    }

    save(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentPbx) {
            this.atsService.save(this.currentPbx, this.currentPbx.id).subscribe(() => {
                this.tab = 'pbx';
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                this.router.navigate(['/ats/' + this.currentPbx.id + '/show']);
                this.state = 'show';
            });
        }
    }

    server() {
        if (this.currentPbx !== undefined && this.currentPbx.serverId !== undefined) {
            let server = new Server();
            server.id = this.currentPbx.serverId;
            server.name = this.currentPbx.serverName;
            return server;
        } else {
            return new Server();
        }
    }

    setServer(server: any) {
        if (server) {
            this.currentPbx.serverId = server.id;
            this.currentPbx.serverName = server.name;
        } else {
            this.currentPbx.serverId = null;
            this.currentPbx.serverName = null;
        }
    }

    createPbxBlockModal() {
        this.modalService.open(this.pbxBlockModal, ModalState.CONFIRM);
    }

    createPbxUnblockModal() {
        this.modalService.open(this.pbxUnblockModal, ModalState.CONFIRM);
    }

    block() {
        this.atsService.block(this.currentPbx.id).subscribe(() => {
            this.loadPbx(true)
            this.closeModal(this.pbxBlockModal);
        });
    }

    unblock() {
        this.atsService.unblock(this.currentPbx.id).subscribe(() => {
            this.loadPbx(true)
            this.closeModal(this.pbxUnblockModal);
        });
    }
}
