<div>
	<div class="item cn-options">
		<i class="icon time-check">
			<voice-menu-ico></voice-menu-ico>
			<span class="counter"><span>{{ node._id_ + 1 }}</span></span>
		</i>
		<div class="name">Голосовое меню</div>
	</div>
	<div class="yes-no cf" data-name="voice-menu">
		<div class="tech-line"></div>
		<div class="yes tech-menu" *ngIf="node.digits['0'] != null || node.emptyMenu">
			<i class="icon yes tech-begin">0</i>
			<div schema-node [node]="node.digits['0'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['1'] != null">
			<i class="icon yes tech-begin">1</i>
			<div schema-node [node]="node.digits['1'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['2'] != null">
			<i class="icon yes tech-begin">2</i>
			<div schema-node [node]="node.digits['2'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['3'] != null">
			<i class="icon yes tech-begin">3</i>
			<div schema-node [node]="node.digits['3'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['4'] != null">
			<i class="icon yes tech-begin">4</i>
			<div schema-node [node]="node.digits['4'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['5'] != null">
			<i class="icon yes tech-begin">5</i>
			<div schema-node [node]="node.digits['5'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['6'] != null">
			<i class="icon yes tech-begin">6</i>
			<div schema-node [node]="node.digits['6'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['7'] != null">
			<i class="icon yes tech-begin">7</i>
			<div schema-node [node]="node.digits['7'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['8'] != null">
			<i class="icon yes tech-begin">8</i>
			<div schema-node [node]="node.digits['8'].node"></div>
		</div>
		<div class="yes tech-menu" *ngIf="node.digits['9'] != null">
			<i class="icon yes tech-begin">9</i>
			<div schema-node [node]="node.digits['9'].node"></div>
		</div>
		<div class="no tech-end">
			<i class="icon spec">Без ввода</i>
			<div *ngIf="node.digits['Z'] != null" schema-node [node]="node.digits['Z'].node"></div>
		</div>
	</div>
</div>