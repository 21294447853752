import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-logo-loader',
  templateUrl: './logo-loader.component.html',
  styleUrls: ['./logo-loader.component.css']
})
export class LogoLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
