export class Pbx {

    id: number;
    name: string;
    status: string;
    createDatetime: string;
    notificationPhone: string;
    registrationNumber: string;
    companyId: number;
    companyName: string;
    companyContractNumber: string;
    companyBalance: number;
}
