import {RouterModule, Routes} from "@angular/router";
import {PbxComponent} from "./pbx.component";
import {AuthGuard} from "../../auth.guard";
import {PbxDetailsComponent} from "./pbx-details/pbx-details.component";
import {CompanyDetailsComponent} from "./company-details/company-details.component";
import {AclComponent} from "./acl/acl.component";
import {ExternalNumbersComponent} from "./external-numbers/external-numbers.component";
import {InternalNumbersComponent} from "./internal-numbers/internal-numbers.component";
import {VoiceMailsComponent} from "./voice-mails/voice-mails.component";
import {HuntGroupsComponent} from "./hunt-groups/hunt-groups.component";
import {ConferencesComponent} from "./conferences/conferences.component";
import {FaxesComponent} from "./faxes/faxes.component";
import {QueuesComponent} from "./queues/queues.component";
import {AtsComponent} from "./pbxlist/ats.component";
import {ModuleWithProviders} from "@angular/core";
import {StorageFilesComponent} from "./storage-files/storage-files.component";
import {PersonComponent} from "./person/person.component";
import {TariffComponent} from "./tariff/tariff.component";
import {CallSchemaComponent} from "./call-schemas/call-schema/call-schema.component";
import {CallSchemasComponent} from "./call-schemas/call-schemas.component";
import {IntegrationsComponent} from "./integrations/integrations.component";

// @formatter:off
export const routes: Routes = [
    {
        path: '',
        component: AtsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':id/:state',
        component: PbxComponent,
        canActivate: [AuthGuard],
        children:[
            {
                path: '',
                component: PbxDetailsComponent,
                canActivate: [AuthGuard]
            },{
                path: 'company',
                component: CompanyDetailsComponent,
                canActivate: [AuthGuard]
            },{
                path: 'acl',
                component: AclComponent,
                canActivate: [AuthGuard]
            },{
                path: 'external-numbers',
                component: ExternalNumbersComponent,
                canActivate: [AuthGuard]
            },{
                path: 'internal-numbers',
                component: InternalNumbersComponent,
                canActivate: [AuthGuard]
            },{
                path: 'call-schemas',
                component: CallSchemasComponent,
                canActivate: [AuthGuard]
            },{
                path: 'call-schemas/call-schema',
                component: CallSchemaComponent,
                canActivate: [AuthGuard],
            },{
                path: 'call-schemas/call-schema/:schemaId',
                component: CallSchemaComponent,
                canActivate: [AuthGuard],
            },{
                path: 'voice-mails',
                component: VoiceMailsComponent,
                canActivate: [AuthGuard]
            },{
                path: 'hunt-groups',
                component: HuntGroupsComponent,
                canActivate: [AuthGuard]
            },{
                path: 'conferences',
                component: ConferencesComponent,
                canActivate: [AuthGuard]
            },{
                path: 'faxes',
                component: FaxesComponent,
                canActivate: [AuthGuard]
            },{
                path: 'queues',
                component: QueuesComponent,
                canActivate: [AuthGuard]
            },{
                path: 'storage-files',
                component: StorageFilesComponent,
                canActivate: [AuthGuard]
            },{
                path: 'persons',
                component: PersonComponent,
                canActivate: [AuthGuard]
            },{
                path: 'tariff',
                component: TariffComponent,
                canActivate: [AuthGuard]
            },{
                path: 'integrations',
                component: IntegrationsComponent,
                canActivate: [AuthGuard]
            },
        ]
    }
]
// @formatter:on
export const routing: ModuleWithProviders = RouterModule.forChild(routes)
