export class Company {
    id: number;
    name: string;
    contractNumber: string;
    contactPerson: string;
    contactPhone: string;
    email: string;
    type: string;
    legalForm: string;
    inn: string;
    kpp: string;
    companyTariffType: number;
    ogrn: string;
    okpo: string;
    legalAddress: string;
    actualAddress: string;
    mailAddress: string;
    account: string;
    bankName: string;
    accountCorr: string;
    bik: string;


    constructor(companyId?: number, companyName?: string) {
        this.id = companyId;
        this.name = companyName;
    }
}
