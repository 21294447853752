import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {QueuesService} from "../../../service/queues.service";
import {Page} from "../../../model/common/list/page";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {Queue} from "../../../model/queue";
import {QueuePattern} from "../../../model/queuePattern";
import {ModalState} from "../../../shared/modal/modal.state";
import {forkJoin} from "rxjs";
import {AccountService} from "../../../service/account.service";
import {QueueMember} from "../../../model/queueMember";
import {Account} from "../../../model/account";
import {NgForm} from "@angular/forms";
import {QueueStrategy} from "../../../model/queueStrategy";
import {StorageFilesService} from "../../../service/storage-files.service";
import {StorageFile} from "../../../model/storageFile";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-queues', templateUrl: './queues.component.html', styleUrls: ['./queues.component.css']
})
export class QueuesComponent extends BasePbxTabComponent {
    readonly queueModal = "queue-modal";
    readonly queueDeleteModal = "queue-delete-modal";
    readonly queueConfigModal = "queue-config-modal";
    readonly strategies = [ //
        {key: 'RING_ALL', label: QueueStrategy.RING_ALL}, //
        {key: 'LEAST_RECENT', label: QueueStrategy.LEAST_RECENT}, //
        {key: 'FEWEST_CALLS', label: QueueStrategy.FEWEST_CALLS}, //
        {key: 'RANDOM', label: QueueStrategy.RANDOM}, //
        {key: 'ROUND_ROBIN_ORDERED', label: QueueStrategy.ROUND_ROBIN_ORDERED}, //
        {key: 'WEIGHT_RANDOM', label: QueueStrategy.WEIGHT_RANDOM} //
    ] //

    readonly announcePositions = [{
        key: 'YES', label: "Да"
    }, {
        key: 'NO', label: "Нет"
    }];
    readonly announceHoldTimes = [{
        key: 'YES', label: "Да"
    }, {
        key: 'NO', label: "Нет"
    }];

    pageLoaded: Promise<boolean>;
    page: Page<Queue>;
    listSearchData: ListSearchData<QueuePattern>;
    currentQueue: Queue;

    listMembers: QueueMember[];
    accounts: Account[];
    member: QueueMember;
    mohs: StorageFile[];

    extended = false;
    addMemberAction = false;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService, protected atsService: AtsService, public queuesService: QueuesService, protected accountService: AccountService, protected storageFilesService: StorageFilesService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'queues';
        this.listSearchData = new ListSearchData(new QueuePattern(this.currentPbx.id));
        this.loadPage();
        this.loadMohs();
    }

    loadPage() {
        this.queuesService.getListByPbx(this.currentPbx.id, this.listSearchData).subscribe(value => {
            this.page = value;
            this.pageLoaded = Promise.resolve(true);
        })
    }

    pageChanged($event: number) {
        this.listSearchData.page = $event - 1;
        this.loadPage();
    }

    countChange($event: number) {
        this.listSearchData.limit = $event;
        this.loadPage();
    }

    createQueueModal() {
        this.currentQueue = new Queue();
        this.currentQueue.strategy = 'RING_ALL';
        this.currentQueue.type = 'QUEUE';
        this.currentQueue.announceHoldTime = 'YES';
        this.currentQueue.announcePosition = 'YES';
        this.modalService.open(this.queueModal, ModalState.ADD);
    }

    createQueueEditModal(queue: Queue) {
        this.queuesService.getById(queue.id).subscribe(value => {
            this.currentQueue = value;
            this.modalService.open(this.queueModal, ModalState.EDIT);
        });
    }

    createQueueConfigModal(queue: Queue) {
        this.addMemberAction = false;
        this.currentQueue = queue;
        let loadListMembers = this.queuesService.getListMembers(queue.id);
        let loadAccounts = this.accountService.getAccountsByPbx(this.currentPbx.id);

        forkJoin([loadListMembers, loadAccounts]).subscribe(value => {
            this.member = new QueueMember();
            this.listMembers = value[0];
            this.accounts = this.filterAccounts(value[1]);
            this.listMembers.forEach(value1 => value1.account = value[1].find(value2 => value2.id === value1.accountId));
            this.modalService.open(this.queueConfigModal, ModalState.EDIT);
        })
    }

    private filterAccounts(accounts: Account[]) {
        return accounts.filter(value1 => {
            let huntGroupMember = this.listMembers.find(value2 => value2.accountId === value1.id);
            return huntGroupMember === undefined;
        });
    }

    createQueueDeleteModal(queue: Queue) {
        this.currentQueue = queue;
        this.modalService.open(this.queueDeleteModal, ModalState.DELETE);
    }

    delete(id: number) {
        if (this.currentQueue && this.currentQueue.id === id) {
            this.queuesService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.queueDeleteModal);
            })
        }
    }

    create($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentQueue) {
            this.queuesService.create(this.currentPbx.id, this.currentQueue).subscribe(id => {
                this.loadPage();
                this.closeModal(this.queueModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    update($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentQueue && this.currentQueue.id) {
            this.queuesService.update(this.currentQueue).subscribe(id => {
                this.loadPage();
                this.closeModal(this.queueModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    deleteMember(member: QueueMember) {
        let index = this.listMembers.indexOf(member, 0);
        if (index > -1) {
            this.listMembers.splice(index, 1);
        }
    }

    addMember() {
        if (this.member) {
            this.member.account = this.accounts.find(value => value.id === this.member.accountId);
            this.accounts.splice(this.accounts.indexOf(this.member.account, 0));
            this.listMembers.push(this.member);
            this.member = new QueueMember();
        }
    }

    editMember(member: QueueMember) {
        this.member = member;
    }

    saveMembers() {
        if (this.listMembers) {
            this.listMembers.forEach(member => member.account = null);
            this.queuesService.saveMembers(this.currentQueue.id, this.listMembers).subscribe(() => {
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.closeModal(this.queueConfigModal);
                this.validationCodes = [];
                this.listMembers = [];
                this.loadPage();
            })
        }
    }


    toggleShowExtend() {
        this.extended = !this.extended;
    }

    private loadMohs() {
        this.storageFilesService.getByPbxId(this.currentPbx.id).subscribe(value => {
            this.mohs = value;
        })
    }
}
