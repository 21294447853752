import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ClientAuthService {

    constructor() {
    }

    tryAuth(username: string, password: string) {
        const request = new XMLHttpRequest();
        const clientURL = localStorage.getItem("clientURL");
        request.open('POST', clientURL + '/app/authentication', true);
        request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        request.withCredentials = true;
        request.onreadystatechange = function () {
            if (request.readyState === XMLHttpRequest.DONE && request.status === 200) {
                setTimeout(function () {
                    window.open(clientURL + '/#!/monitoring', '_blank');
                }, 1000);
            } else {
                console.log('ERROR!!!');
            }
        };
        request.send("j_username=" + username + "&j_password=" + password + "&_spring_security_remember_me=undefined&submit=Login");
    }
}
