import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ModalService} from "../../../service/modal.service";
import {AtsService} from "../../../service/ats.service";
import {RouteStateService} from "../../../service/route-state.service";
import {TariffService} from "../../../service/tariff.service";
import {Tariff} from "../../../model/tariff";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-tariff', templateUrl: './tariff.component.html', styleUrls: ['./tariff.component.css']
})
export class TariffComponent extends BasePbxTabComponent {
    tariff: Tariff = new Tariff();

    constructor(public deviceService: DeviceDetectorService, private tariffService: TariffService, public modalService: ModalService, protected atsService: AtsService, protected route: ActivatedRoute, protected routeStateService: RouteStateService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'tariff';
        if (this.currentPbx.status === 'ACTIVE') {
            this.getTariff();
            this.getBalance();
        }
    }

    getTariff() {
        this.tariffService.getByCompanyId(this.currentPbx.companyId).subscribe(value => {
            this.tariff = value;
        })
    }
}
