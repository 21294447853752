<label [attr.for]="id">{{selectName}}</label>
<dl class="select select-filter select-flag scrollable" [class.select-autocomplete]="autocomplete" [class.select-active]="dirty">
	<dt class="">
		<input type="text" [attr.name]="id" class="required" [attr.id]="id" [attr.required]="required" [readOnly]="readOnly" [formControl]="searchControl">
	</dt>
	<dd *ngIf="!readOnly">
		<div class="select-options">
			<div class="scroll-cont scrollbar-inner" >
				<a data-value="" *ngFor="let option of options" (click)="select(option)">{{ labelOf(option) }}</a>
			</div>
		</div>
	</dd>
</dl>
