export class AreaCodeSearch {

    constructor() {
    }

    public id: number;
    public countryCode: number;
    public areaCode: number;
    public description: string;
}
