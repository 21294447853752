import {ConflictType} from "./conflictType";

export class UnionExternalNumber {
    isConflict: boolean = false;

    number: string;
    billingId: number;
    publicId: number;
    status: string;
    billingType: string;
    publicType: string;
    outer: boolean;

    billingPbxName: string;
    billingPbxId: number;

    publicPbxName: string;
    publicPbxId: number;

    conflictType: ConflictType;
}
