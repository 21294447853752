import {Injectable} from '@angular/core';
import {Account} from "../model/account";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient) { }

    getAccountsByPbx(pbxId: number) {
        return this.http.get<Account[]>(`api/pbx/${pbxId}/accounts`);
    }

}
