import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {BasePbxTabComponent} from "../basePbxTab.component";
import {ModalService} from "../../../service/modal.service";
import {RouteStateService} from "../../../service/route-state.service";
import {AtsService} from "../../../service/ats.service";
import {ListSearchData} from "../../../model/common/list/listSearchData";
import {ModalState} from "../../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {DeviceDetectorService} from "ngx-device-detector";
import {Integration} from "../../../model/integration";
import {IntegrationsService} from "../../../service/integrations.service";
import {ExternalNumber} from "../../../model/externalNumber";
import {ExternalNumbersService} from "../../../service/external-numbers.service";
import {IntegrationKeys} from "../../../model/integrationKeys";

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.css']
})
export class IntegrationsComponent extends BasePbxTabComponent {
    readonly integrationModal = "integration-modal";
    readonly integrationDeleteModal = "integration-delete-modal";


    crmSystems = [{
        valueField: 'AmoCRM',
        showVal: "AmoCRM"
    }, {
        valueField: 'Bitrix24',
        showVal: "Bitrix24"
    }, {
        valueField: 'RetailCRM',
        showVal: "RetailCRM"
    }, {
        valueField: 'Planfix',
        showVal: "Planfix"
    }, {
        valueField: 'SynergyCRM',
        showVal: "SynergyCRM"
    }, {
        valueField: 'Gentleman',
        showVal: "GEN CRM"
    }, {
        valueField: '1C',
        showVal: "1C (Simplit)"
    }, {
        valueField: 'Roistat',
        showVal: "Roistat"
    }];
    selectedCRM = this.crmSystems[0];

    pageLoaded: Promise<boolean>;
    page: Integration[];
    apiKeys: IntegrationKeys;
    listSearchData: ListSearchData<Integration>;
    currentIntegration: Integration;
    externalNumbers: ExternalNumber[];
    isPrepared = true;

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, protected route: ActivatedRoute, protected routeStateService: RouteStateService,
                protected atsService: AtsService, public integrationsService: IntegrationsService, public externalNumberService: ExternalNumbersService) {
        super(deviceService, modalService, atsService, route, routeStateService);
    }

    tabOnInit() {
        this.tab = 'integrations';
        let integration = new Integration();
        integration.pbxId = this.currentPbx.id;
        this.listSearchData = new ListSearchData(integration);
        this.loadPage();
        this.integrationsService.getKeys(this.currentPbx.id).subscribe(value => {
            this.apiKeys = value;
        })
    }

    loadPage() {
        this.integrationsService.getListByPbx(this.currentPbx.id, this.listSearchData).subscribe(value => {
            this.page = value;
            this.pageLoaded = Promise.resolve(true);
        })
    }

    pageChanged(page: number) {
        this.listSearchData.page = page - 1;
        this.loadPage();
    }

    countChange(count: number) {
        this.listSearchData.limit = count;
        this.loadPage();
    }

    createModal() {
        this.currentIntegration = new Integration();
        this.externalNumberService.withoutIntegration(this.currentPbx.id).subscribe(value => {
            this.externalNumbers = value;
            this.modalService.open(this.integrationModal, ModalState.ADD);
        })
    }

    createEditModal(integration: Integration) {
        this.integrationsService.getById(integration.id).subscribe(value => {
            this.currentIntegration = value;
            this.checkIsCrmPrepared(this.currentIntegration.name);
            this.externalNumberService.withoutIntegration(this.currentPbx.id).subscribe(value => {
                this.externalNumbers = value;
                this.modalService.open(this.integrationModal, ModalState.EDIT);
            })
        });
    }

    createDeleteModal(integration: Integration) {
        this.currentIntegration = integration;
        this.modalService.open(this.integrationDeleteModal, ModalState.DELETE);
    }

    delete(id: number) {
        if(this.currentIntegration && this.currentIntegration.id === id) {
            this.integrationsService.delete(id).subscribe(() => {
                this.loadPage();
                this.closeModal(this.integrationDeleteModal);
            })
        }
    }

    save($event: Event, form: NgForm) {
        form.onSubmit($event);
        if (form.valid && this.currentIntegration) {
            if (this.isPrepared) {
                this.currentIntegration.name = this.selectedCRM.valueField;
            }
            this.integrationsService.save(this.currentPbx.id, this.currentIntegration).subscribe(id => {
                this.loadPage();
                this.closeModal(this.integrationModal);
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            })
        }
    }

    selectExternalNumber($event: any) {
        if ($event && $event.id) {
            this.currentIntegration.externalNumberId = $event.id;
            this.currentIntegration.externalNumber = $event.number;
        }
    }

    selectCRM($event: any) {
        if ($event) {
            this.selectedCRM = $event;
        }
    }

    generateUniqueApiKey(integration: Integration) {
        this.integrationsService.generateUniqueApiKey().subscribe(key => {
            if (key) {
                integration.uniqueApiKey = key;
            }
        })
    }

    checkDomainValid(integration: Integration) {
        if (integration.domainName) {
            if (integration.domainName.startsWith("http://")) {
                integration.domainName = integration.domainName.replace("http://", "");
            } else if (integration.domainName.startsWith("https://")) {
                integration.domainName = integration.domainName.replace("https://", "");
            }
        }
    }

    checkIsCrmPrepared(name: string) {
        for (let i = 0; i < this.crmSystems.length; i++) {
            if (this.crmSystems[i].valueField === name) {
                this.isPrepared = true;
                this.selectedCRM = this.crmSystems[i];
                break;
            }
        }
    }
}
