<div class="base-tab__block active" *ngIf="isDataAvailable && pageLoaded">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить факс
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="fax-search" name="fax-search" [(ngModel)]="listSearchData.searchPattern.email" (keyup.enter)="loadPage()"
				            placeholder="Поиск по e-mail"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table" *ngIf="page">
				<thead>
				<tr>
					<td>Номер</td>
					<td>E-mail</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let fax of page.items">
					<td>{{fax.number}}</td>
					<td>{{fax.email}}</td>
					<td>
						<app-actions (edit)="createEditModal(fax)" (delete)="createDeleteModal(fax)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createModal()">
				Добавить факс
			</button>
			<div *table-header-marker class="input-block search">
				<text-input id="fax-search_mob" name="fax-search" [(ngModel)]="listSearchData.searchPattern.email" (keyup.enter)="loadPage()"
				            placeholder="Поиск по e-mail"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)" (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body">
			<table class="base-table" *ngIf="page">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let fax of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Номер</div>
									<div class="data">{{fax.number}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">E-mail</div>
									<div class="data">{{fax.email}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (edit)="createEditModal(fax)" (delete)="createDeleteModal(fax)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="faxModal" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formData="ngForm">
			<div class="col" *ngIf="currentFax">
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && number.invalid}">
					<label for="fax_num">Внутренний номер</label>
					<input type="number" id="fax_num" min="10" max="9999" name="fax_num" required [(ngModel)]="currentFax.number" #number="ngModel">
					<div *ngIf="formData.submitted && number.invalid" class="invalid">
						<div *ngIf="number.errors.required">Введите номер</div>
						<div *ngIf="number.errors.min">Введите номер от 10 до 9999</div>
						<div *ngIf="number.errors.max">Введите номер от 10 до 9999</div>
					</div>
				</div>
				<div class="input-block required" [ngClass]="{'invalid': formData.submitted && email.invalid}">
					<label for="fax_email">E-mail</label>
					<text-input id="fax_email" name="fax_email" required email [(ngModel)]="currentFax.email" #email="ngModel"></text-input>
					<div *ngIf="formData.submitted && email.invalid" class="invalid">
						<div *ngIf="email.errors.required">Email не заполнен</div>
						<div *ngIf="email.errors.email">Невалидный e-mail</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(faxModal)">Отмена</button>
		<button *ngIf="modalService.getState(faxModal) === 'ADD'" class="btn base-btn" (click)="create($event, formData)">Создать</button>
		<button *ngIf="modalService.getState(faxModal) === 'EDIT'" class="btn base-btn" (click)="update($event, formData)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="faxDeleteModal" [title]="'Удалить факс'">
	<div body *ngIf="currentFax">
		<div class="settings-delete_popup--content">
			Удалить факс: <span class="b">{{currentFax.number}} - {{currentFax.email}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(faxDeleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentFax.id)">Удалить</button>
	</div>
</app-modal>
