import {Pbx} from "./pbx";
import {PbxAcl} from "./pbxAcl";

export class PbxForm extends Pbx{
    countryCode: string;
    areaCode: string;
    timeZone: string;
    callLimit: number;
    serverId: number;
    serverName: string;
    companyType: string;
    pbxAcls: PbxAcl[];
}
