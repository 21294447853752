<div class="base-tab__block active" *ngIf="isDataAvailable && pageLoaded">
	<div class="table-container --desktop">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createStorageFileModal()">
				Добавить файл
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по имени"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)"
			                (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table" *ngIf="page">
				<thead>
				<tr>
					<td>Название</td>
					<td>Имя файла</td>
					<td>Тип файла</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let file of page.items">
					<td>{{file.name}}</td>
					<td>{{file.fileName}}</td>
					<td>{{file.used | fileType}}</td>
					<td>
						<app-actions (play)="play(file)" (download)="download(file)" (delete)="createStorageFileDeleteModal(file)"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="createStorageFileModal()">
				Добавить файл
			</button>
			<div *table-header-marker class="input-block right-margin search">
				<text-input id="input_1_mob" name="input_1" [(ngModel)]="listSearchData.searchPattern.name" (keyup.enter)="loadPage()"
				            placeholder="Поиск по имени"></text-input>
			</div>
			<app-mks-paging *table-header-marker [condition]="page && page.count >= listSearchData.limit" [page]="page" [listSearchData]="listSearchData"
			                (pageChange)="pageChanged($event)"
			                (countChange)="countChange($event)"></app-mks-paging>
		</app-table-header>
		<div class="table-body">
			<table class="base-table" *ngIf="page">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let file of page.items">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Название</div>
									<div class="data">{{file.name}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Имя файла</div>
									<div class="data">{{file.fileName}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Тип файла</div>
									<div class="data">{{file.used | fileType}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (play)="play(file)" (download)="download(file)" (delete)="createStorageFileDeleteModal(file)"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="storageFileModal" [title]="'Создать звуковой файл'" [additionalClass]="'small_popup'">
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div body>
		<form #formDataFile="ngForm">
			<div class="col" *ngIf="currentStorageFile">
				<div class="input-block required" [ngClass]="{'invalid': formDataFile.submitted && name.invalid}">
					<label for="input_name">Название</label>
					<text-input id="input_name" name="input_name" placeholder="Введите название" [(ngModel)]="currentStorageFile.name" #name="ngModel"
					            required></text-input>
					<div *ngIf="formDataFile.submitted && name.invalid" class="invalid">
						<div *ngIf="name.errors.required">Введите название</div>
					</div>
				</div>
				<div class="select-block">
					<label>Тип файла</label>
					<ng-select name="types"
					           [placeholder]="'Звуковой файл'"
					           [items]="types"
					           bindValue="key"
					           [clearable]="false"
					           [(ngModel)]="currentStorageFile.used">
					</ng-select>
				</div>
				<div class="input-block input-file required" [ngClass]="{'invalid': formDataFile.submitted && fileField.invalid}">
					<label for="file">Файл <span>(только в формате mp3)</span></label>
					<input type="file" id="file" name="file" (change)="handleFileInput($event)" [accept]="'audio/mp3'" [(ngModel)]="currentStorageFile.fileFake"
					       #fileField="ngModel" required fileType="mp3">
					<div *ngIf="formDataFile.submitted && fileField.invalid" class="invalid">
						<div *ngIf="fileField.errors.required">Введите файл</div>
						<div *ngIf="fileField.errors.type">Поддерживаются только mp3-файлы</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(storageFileModal)">Отмена</button>
		<button class="btn base-btn" (click)="create($event, formDataFile)">Сохранить</button>
	</div>
</app-modal>
<!--  -->
<app-modal [id]="storageFileDeleteModal" [title]="'Удалить файл'">
	<div body *ngIf="currentStorageFile">
		<div class="settings-delete_popup--content">
			Удалить файл: <span class="b">{{currentStorageFile.fileName}}</span>?
		</div>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal(storageFileDeleteModal)">Отмена</button>
		<button class="btn base-btn red" (click)="delete(currentStorageFile)">Удалить</button>
	</div>
</app-modal>

<app-player (download)="download(currentStorageFile)"></app-player>
