import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Page} from "../model/common/list/page";
import {Server} from "../model/server";
import {ListSearchData} from "../model/common/list/listSearchData";
import {ServerSearch} from "../model/common/list/serverSearch";

@Injectable({
    providedIn: 'root'
})
export class ServerService {

    constructor(private http: HttpClient) {
    }

    getById(id: number) {
        return this.http.get<Server>('api/server/' + id);
    }

    getAll(searchData: ListSearchData<ServerSearch>) {
        return this.http.post<Page<Server>>('api/server', searchData);
    }

    save(server: Server) {
        return this.http.put('api/server', server);
    }

    delete(id: number) {
        return this.http.delete('api/server/' + id);
    }

    getList() {
        return this.http.get<Server[]>('api/server');
    }
}
