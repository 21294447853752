import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HuntGroup} from "../model/huntGroup";
import {HuntGroupAccounts} from "../model/huntGroupAccounts";
import {ListSearchData} from "../model/common/list/listSearchData";
import {Page} from "../model/common/list/page";
import {HuntGroupPattern} from "../model/huntGroupPattern";
import {HuntGroupMember} from "../model/huntGroupMember";

@Injectable({
    providedIn: 'root'
})
export class HuntGroupsService {

    constructor(private http: HttpClient) {
    }

    getByPbxId(id: number) {
        return this.http.get<HuntGroup[]>(`api/pbx/${id}/hunt-groups`);
    }

    getById(id: number) {
        return this.http.get<HuntGroup>(`api/hunt-groups/${id}`)
    }

    getListMembers(id: number) {
        return this.http.get<HuntGroupMember[]>(`api/hunt-groups/${id}/list-members`)
    }

    create(pbxid: number, huntGroup: HuntGroup) {
        return this.http.post(`api/pbx/${pbxid}/hunt-groups`, huntGroup);
    }

    update(pbxid: number, huntGroup: HuntGroup) {
        return this.http.put(`api/pbx/${pbxid}/hunt-groups`, huntGroup);
    }

    saveMembers(id: number, accountIds: number[]) {
        return this.http.put(`api/hunt-groups/${id}/members`, accountIds);
    }

    delete(id: number) {
        return this.http.delete(`api/hunt-groups/${id}`)
    }

    getListByPbx(pbxId: number, searchData: ListSearchData<HuntGroupPattern>) {
        return this.http.post<Page<HuntGroup>>(`api/pbx/${pbxId}/hunt-groups/list`, searchData);
    }
}
