<div class="mini-player" [ngClass]="{'show': streamState?.playing || streamState?.canplay}">
	<button class="btn pause-btn" (click)="playStop()">
		<svg *ngIf="!streamState?.playing">
			<use xlink:href="#play"></use>
		</svg>
		<svg *ngIf="streamState?.playing">
			<use xlink:href="#pause"></use>
		</svg>
	</button>
	<div class="track">
		<div class="start">{{ streamState?.readableCurrentTime }}</div>
		<div class="end">{{ streamState?.readableDuration }}</div>
		<mat-slider color="primary" class="line"  [min]="0" [max]="streamState?.duration" [step]="1" [value]="streamState?.currentTime" (input)="onSliderChangeEnd($event)" [disabled]="streamState?.error"></mat-slider>
	</div>
	<div class="volume">
		<div class="ico">
			<svg>
				<use xlink:href="#volume"></use>
			</svg>
		</div>
		<div class="level">
			<div class="bg">
				<mat-slider class="value" [min]="0" [step]="1" [max]="100" [vertical]="true" [value]="50" (input)="onVolumeChange($event)"></mat-slider>
			</div>
		</div>
	</div>
	<button class="btn pause-btn" style="margin-right: 0; margin-left: 5px;" (click)="download.emit('')" >
		<svg>
			<use xlink:href="#download"></use>
		</svg>
	</button>
</div>

