<div>
	<div class="item cn-options">
		<i class="icon time-check">
			<date-ico></date-ico>
			<span class="counter"><span>{{ node._id_ + 1 }}</span></span>
		</i>
		<div class="name">Проверка по времени</div>
	</div>
	<div class="yes-no cf spec-padding">
		<div class="tech-line"></div>
		<div class="yes" *ngIf="node.match != null">
			<i class="icon yes tech-begin">Да</i>
			<div schema-node [node]="node.match"></div>
		</div>
		<div class="no tech-end" *ngIf="node.match != null">
			<i class="icon no tech-end">Нет</i>
			<div schema-node [node]="node.notMatch"></div>
		</div>
	</div>
</div>