import {Component} from '@angular/core';
import {Server} from "../../model/server";
import {ServerService} from "../../service/server.service";
import {ListSearchData} from "../../model/common/list/listSearchData";
import {ServerSearch} from "../../model/common/list/serverSearch";
import {ModalState} from "../../shared/modal/modal.state";
import {NgForm} from "@angular/forms";
import {BaseListPageComponent} from "../baseListPage.component";
import {ModalService} from "../../service/modal.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
    selector: 'app-server', //
    templateUrl: './server.component.html', //
    styleUrls: ['./server.component.css']
})
export class ServerComponent extends BaseListPageComponent<Server, ServerSearch> {
    currentServer: Server = new Server();
    isDataAvailable = false;

    validationCodes: number[];

    constructor(public deviceService: DeviceDetectorService, public modalService: ModalService, private serverService: ServerService) {
        super(deviceService, new ListSearchData(new ServerSearch()));
    }

    loadPage() {
        this.serverService.getAll(this.listSearchData).subscribe(value => {
            this.page = value;
            this.isDataAvailable = true;
        });
    }

    createAddServerModal() {
        this.currentServer = new Server();
        this.modalService.open("server-modal", ModalState.ADD)
    }

    createEditServerModal(id: number) {
        this.serverService.getById(id).subscribe((value) => {
            this.currentServer = value;
            this.modalService.open("server-modal", ModalState.EDIT)
        });
    }

    createShowServerModal(id: number) {
        this.serverService.getById(id).subscribe((value) => {
            this.currentServer = value;
            this.modalService.open("server-modal", ModalState.SHOW)
        });
    }

    createDeleteServerModal(server: Server) {
        this.currentServer = server;
        this.modalService.open("server-delete-modal", ModalState.DELETE)
    }

    closeModal(id: string) {
        this.modalService.close(id);
        this.validationCodes = [];
    }

    save(event: Event, form: NgForm) {
        form.onSubmit(event);
        if (form.valid && this.currentServer) {
            this.serverService.save(this.currentServer).subscribe(() => {
                this.closeModal("server-modal");
                this.loadPage();
            }, (e) => {
                this.validationCodes = e.error.codes;
            }, () => {
                this.validationCodes = [];
                form.onReset();
            });
        }
    }

    delete(id: number) {
        if (this.currentServer && this.currentServer.id === id) {
            this.serverService.delete(this.currentServer.id).subscribe(() => {
                this.closeModal("server-delete-modal");
                this.loadPage();
            }, (e) => this.validationCodes = e.error.codes, () => this.validationCodes = []);
        }
    }

    clearSearch() {
        this.listSearchData.searchPattern = new ServerSearch();
    }
}
