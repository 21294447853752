import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../service/authentication.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-sidebar', templateUrl: './sidebar.component.html', styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

    constructor(public authenticationService: AuthenticationService, private router: Router) {
    }

    isShowMenuMobile = false;
    isShowUserDataMobile = false;

    ngOnInit() {
    }

    logout() {
        this.authenticationService.logout().subscribe(value => {
            this.router.navigate(['/login']);
        });
    }

    currentUserName() {
        return this.authenticationService.currentUserValue !== null ? this.authenticationService.currentUserValue.name : "";
    }

    currentUserData() {
        return this.authenticationService.currentUserValue !== null ? this.authenticationService.currentUserValue.firstName + " " + this.authenticationService.currentUserValue.surName : "";
    }

}
