<div class="base-tab__block active" data-tabname="numbers_out">
	<div class="table-container --desktop" *ngIf="!isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="showCallSchemaPage(null, 'add')">
				Создать схему звонков
			</button>
		</app-table-header>
		<div class="table-body scrollbar-inner">
			<table class="base-table">
				<thead>
				<tr>
					<td>ID</td>
					<td>Название</td>
					<td>Дата и время создания</td>
					<td>Тип схемы звонка</td>
					<td>Последнее изменение</td>
					<td>Кем изменено</td>
					<td>Активная</td>
					<td></td>
				</tr>
				</thead>
				<tbody>
				<tr *ngFor="let callSchema of callSchemas">
					<td>{{callSchema.id}}</td>
					<td>{{callSchema.name}}</td>
					<td>{{callSchema.createDatetime}}</td>
					<td>{{callSchema.type === "INBOUND" ? "Входящие" : callSchema.type === "OUTBOUND" ? "Исходящие" : "Автозвонки"}}</td>
					<td>{{callSchema.modifiedDateTime}}</td>
					<td>{{callSchema.login}}</td>
					<td>
						<div class="ats-status" [ngClass]="callSchema.active ? 'ACTIVE' : 'APPEND'">
							<span class="circle"></span><span>{{callSchema.active ? "Да" : "Нет"}}</span></div>
					</td>
					<td class="actions">
						<app-actions (delete)="createDeleteCallSchemaModal(callSchema)"
						             (edit)="showCallSchemaPage(callSchema.id, 'edit')"
						             (show)="showCallSchemaPage(callSchema.id, 'show')"></app-actions>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>

	<div class="table-container --mobile" *ngIf="isMobile">
		<app-table-header>
			<button *table-header-marker class="btn base-btn" (click)="showCallSchemaPage(null, 'add')">
				Создать схему звонков
			</button>
		</app-table-header>
		<div class="table-body">
			<table class="base-table">
				<tbody>
				<tr>
					<td>
						<div *ngFor="let callSchema of callSchemas">
							<app-mobile-slider>
								<div *mobile-slider-marker class="td-block">
									<div class="label">ID</div>
									<div class="data">{{callSchema.id}}</div>
								</div>
								<div *mobile-slider-marker class="td-block">
									<div class="label">Название</div>
									<div class="data">{{callSchema.name}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Дата и время создания</div>
									<div class="data">{{callSchema.createDatetime}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Тип схемы звонка</div>
									<div class="data">{{callSchema.type === "INBOUND" ? "Входящие" : callSchema.type === "OUTBOUND" ? "Исходящие" : "Автозвонки"}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Последнее изменение</div>
									<div class="data">{{callSchema.modifiedDateTime}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Кем изменено</div>
									<div class="data">{{callSchema.login}}</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="label">Активная</div>
									<div class="data">
										<div class="ats-status" [ngClass]="callSchema.active ? 'ACTIVE' : 'APPEND'">
											<span class="circle"></span><span>{{callSchema.active ? "Да" : "Нет"}}</span></div>
									</div>
								</div>
								<div *mobile-slider-last-marker class="td-block">
									<div class="data">
										<div class="action">
											<app-actions (delete)="createDeleteCallSchemaModal(callSchema)"
											             (edit)="showCallSchemaPage(callSchema.id, 'edit')"
											             (show)="showCallSchemaPage(callSchema.id, 'show')"></app-actions>
										</div>
									</div>
								</div>
							</app-mobile-slider>
						</div>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>

<app-modal [id]="'call-schema-delete-modal'">
	<div body>Вы действительно хотите удалить схему "{{currentCallSchema.name}}"?</div>
	<div validation>
		<app-validation [codes]="validationCodes"></app-validation>
	</div>
	<div footer>
		<button class="btn base-btn grey popup-btn__close" (click)="closeModal('call-schema-delete-modal')">Отмена</button>
		<button class="btn base-btn red" (click)="deleteCallSchema(currentCallSchema.id)">Удалить</button>
	</div>
</app-modal>
