import {Component, ContentChildren, Directive, EventEmitter, Output, TemplateRef} from '@angular/core';

@Directive({
    selector: '[filter-marker]'
})
export class FilterMarker {}

@Directive({
    selector: '[table-header-marker]'
})
export class TableHeaderMarker {}

@Component({
    selector: 'app-table-header',
    templateUrl: './table-header.component.html',
    styleUrls: ['./table-header.component.css']
})
export class TableHeaderComponent {
    @ContentChildren(FilterMarker, {read: TemplateRef}) filterTemplates: TemplateRef<any>[];
    @ContentChildren(TableHeaderMarker, {read: TemplateRef}) headerTemplates: TemplateRef<any>[];

    isFilterShow: boolean = false;
    isPenultimateHeaderTemplate = false;

    @Output('onSubmitFilter') submitFilter = new EventEmitter();
    @Output('onClearFilter') clearFilter = new EventEmitter();

    isSubmitFilterUsed = false;
    isClearFilterUsed = false;

    constructor() {
    }

    ngOnInit() {
        this.isSubmitFilterUsed = this.submitFilter.observers.length > 0;
        this.isClearFilterUsed = this.clearFilter.observers.length > 0;
    }
}
